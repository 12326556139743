import React, { useState } from 'react';
import { getIconUrl } from '../../../../constants/iconUrlMap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';

const BudgetCard = ({ latestBudget, storedUserId }) => {
  const [sortOption, setSortOption] = useState('Category Name');

  const sortBudgetCategories = () => {
    let sortedCategories;
    switch (sortOption) {
      case 'Category Name':
        sortedCategories = [...latestBudget.budget_categories].sort((a, b) =>
          a.category_name.localeCompare(b.category_name)
        );
        break;
      case 'Amount Budgeted':
        sortedCategories = [...latestBudget.budget_categories].sort(
          (a, b) =>
            a.total_allocated_for_property - b.total_allocated_for_property
        );
        break;
      case 'Amount Left':
        sortedCategories = [...latestBudget.budget_categories].sort(
          (a, b) => a.amount_left - b.amount_left
        );
        break;
      case 'Amount Spent':
        sortedCategories = [...latestBudget.budget_categories].sort(
          (a, b) =>
            a.total_allocated_for_property -
            a.amount_left -
            (b.total_allocated_for_property - b.amount_left)
        );
        break;
      default:
        sortedCategories = latestBudget.budget_categories;
    }
    return sortedCategories;
  };

  const sortedBudgetCategories = sortBudgetCategories();

  return (
    <div className='col-xl-3'>
      <div className='card card-height-100'>
        <div className='card-header align-items-center d-flex'>
          <h4 className='card-title mb-0 flex-grow-1'>
            {new Date(0, latestBudget.month - 1).toLocaleString('default', {
              month: 'long',
            })}{' '}
            Budget
          </h4>
          <div className='flex-shrink-0'>
            <NavDropdown title={`Sort by: ${sortOption}`} id='nav-dropdown'>
              <NavDropdown.Item onClick={() => setSortOption('Category Name')}>
                Category Name
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => setSortOption('Amount Budgeted')}
              >
                Amount Budgeted
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => setSortOption('Amount Left')}>
                Amount Left
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => setSortOption('Amount Spent')}>
                Amount Spent
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>
        {/* end card header */}
        <div className='card-body p-0'>
          <ul className='list list-group list-group-flush mb-0'>
            {sortedBudgetCategories.map((category, index) => {
              const amountBudgeted = category.total_allocated_for_property || 0;
              const amountLeft = category.amount_left || 0;
              const amountSpent = amountBudgeted - amountLeft;
              const progress =
                amountBudgeted !== 0 ? (amountSpent / amountBudgeted) * 100 : 0;

              let barColor = 'bg-secondary';
              if (progress >= 95) {
                barColor = 'bg-danger';
              } else if (progress >= 80) {
                barColor = 'bg-warning';
              } else {
                barColor = 'bg-success';
              }

              return (
                <li key={index} className='list-group-item' data-id='01'>
                  <div className='d-flex align-items-start'>
                    <div className='flex-shrink-0 me-3'>
                      <div>
                        <img
                          className='image avatar-xs rounded-circle'
                          alt=''
                          src={getIconUrl(category.category_icon).url}
                        />
                      </div>
                    </div>

                    <div className='flex-grow-1'>
                      <div className='d-flex justify-content-between align-items-start'>
                        <div className='overflow-hidden'>
                          <h5 className='contact-name fs-13 mb-1'>
                            <Link
                              to={`/budgetRelatedExpenses/${category.budget_category_id}`}
                              className='link text-body'
                            >
                              {category.category_name}
                            </Link>
                          </h5>
                        </div>

                        <div className='flex-shrink-0 ms-2'>
                          <div
                            className='fs-5 text'
                            style={{ fontWeight: 'bold' }}
                          >
                            ${Number(amountSpent)?.toFixed(2)}
                          </div>
                        </div>
                      </div>

                      <div className='overflow-hidden'>
                        <div className='no-padding card-body '>
                          <div className='d-flex justify-content-between '>
                            <div>
                              <h6 className='mb-0'>
                                <b className='text'>
                                  ${Number(amountSpent)?.toFixed(2)}
                                </b>{' '}
                                Spent
                              </h6>
                            </div>
                            <div>
                              <h6 className='mb-0'>
                                ${Number(amountLeft)?.toFixed(2)} Left
                              </h6>
                            </div>
                            <div>
                              <h6 className='mb-0'>
                                ${Number(amountBudgeted).toFixed(2)} Budgeted
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className='progress bg-secondary-subtle rounded-0'>
                          <div
                            className={`progress-bar ${barColor}`}
                            role='progressbar'
                            style={{ width: `${progress}%` }}
                            aria-valuenow={progress}
                            aria-valuemin='0'
                            aria-valuemax='100'
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        {/* end cardbody */}
      </div>
      {/* end card */}
    </div>
  );
};

export default BudgetCard;
