import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';

import {
  predefinedColors,
  dynamicallyGeneratedColor,
} from '../../../../../services/helpers/colorService';

Chart.register(CategoryScale, LinearScale, Title, Tooltip, Legend, BarElement);

const ExpenseTransactionGraph = ({ transactions, isDarkMode }) => {
  // Calculate total expenses
  const totalExpenses = transactions.reduce(
    (total, transaction) => total + transaction.amount,
    0
  );

  // Prepare the data
  const data = {
    labels: transactions.map((transaction) =>
      new Date(
        transaction.history_year,
        transaction.history_month - 1,
        transaction.history_day
      ).toLocaleDateString()
    ),
    datasets: [
      {
        label: `Total Expenses: $${totalExpenses}`,
        data: transactions.map((transaction) => transaction.amount),
        backgroundColor: transactions.map((transaction) =>
          dynamicallyGeneratedColor(
            transaction.budget_category_name || 'default',
            isDarkMode
          ).replace('0.2', '1')
        ),
        borderColor: transactions.map((transaction) =>
          dynamicallyGeneratedColor(
            transaction.budget_category_name || 'default',
            isDarkMode
          ).replace('0.2', '1')
        ),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: isDarkMode ? 'white' : '#6c757d',
        },
      },
      title: {
        display: true,
        text: 'Expense Breakdown',
        color: isDarkMode ? 'white' : '#6c757d',
      },
    },
    scales: {
      x: {
        ticks: {
          color: isDarkMode ? 'white' : '#6c757d',
        },
      },
      y: {
        type: 'linear',
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <div className='card card-height-100'>
        <div className='card-header align-items-center d-flex'>
          <h4 className='card-title mb-0 flex-grow-1'>Expense Breakdown</h4>
        </div>
        {/* end card header */}
        <div className='card-body'>
          <div style={{ width: '100%', height: '100%' }}>
            <Bar data={data} options={options} />
          </div>
        </div>
        {/* end card body */}
      </div>
      {/* end card */}
    </>
  );
};

export default ExpenseTransactionGraph;
