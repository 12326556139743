import React, { useEffect, useState } from 'react';
import loader from '../../../../styletheme/images/loading/loader.svg';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import {
  predefinedColors,
  dynamicallyGeneratedColor,
  parseColor,
  darkenColor,
  colorForCategory,
  stringToHash,
} from '../../../../services/helpers/colorService';
import {
  fetchLatestBudget,
  fetchSecondLatestBudget,
} from '../../../../services/budgets/budgetServiceManagement';
import BudgetBreakdownCard from '../../../reusable/cards/dashboard/BudgetBreakdownCard';
import BudgetCard from '../../../reusable/cards/budget/budgetCard';
import BudgetCardGrid from '../../../reusable/cards/budget/budgetCardGrid';
import SpendingCard from '../../../reusable/cards/budget/sub/spendingCard';
import SavingCard from '../../../reusable/cards/budget/sub/savingCard';
import BudgetLeftCard from '../../../reusable/cards/budget/sub/budgetLeftCard';
import TotalSpendCard from '../../../reusable/cards/budget/sub/totalSpentCard';
import LoaderView from '../../../reusable/loader/loaderView';
import { set } from 'lodash';
import PageTitleBox from '../../../reusable/portal/general/pageTitleBox';
import Lottie from 'react-lottie';
import no_budget_animation from '../../../reusable/animations/lottie/no_budget.json';

const CurrentBudget = ({ isDarkMode }) => {
  const [isLoading, setIsLoading] = useState();
  ChartJS.register(ArcElement, Tooltip, Legend);
  const pageTitle = 'Current Budget';
  const [mostSpentCategory, setMostSpentCategory] = useState({
    category: null,
    amountSpent: 0,
  });
  const [mostSavedCategory, setMostSavedCategory] = useState({
    category: null,
    amountSpent: 0,
  });

  const [leftToBudget, setLeftToBudget] = useState(0);
  const [totalBudget, setTotalBudget] = useState(0);
  const [totalSpent, setTotalSpent] = useState(0);

  const [percentageChange, setPercentageChange] = useState(0);
  const [percentageChangeInSavings, setPercentageChangeInSavings] = useState(0);
  const [secondLatestBudgetSummary, setSecondLatestBudgetSummary] = useState({
    totalAllocated: 0,
    totalLeft: 0,
    totalSpent: 0,
  });

  const emptyBudgetLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: no_budget_animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const [totalSpentPercentageChange, setTotalSpentPercentageChange] =
    useState(0);
  const [latestBudget, setLatestBudget] = useState({
    budget_categories: [
      {
        budget_id: '',
        budget_category_id: '',
        category_name: '',
        total_allocated_for_property: 0,
        amount_left: 0,
      },
    ],
    budget_id: '',
    creator_profile: {
      creator_profile_id: '',
      email_address: '',
      profile_img: '',
      last_name: '',
      user_id: '',
    },
    email_address: '',
    first_name: '',
    is_active_budget: false,
    last_name: '',
    month: 0,
    record_time_stamp: 0,
    total_left: 0,
    total_sum: 0,
    user_id: '',
    year: 0,
  });

  const [secondLatestBudget, setSecondLatestBudget] = useState({
    budget_categories: [
      {
        budget_id: '',
        budget_category_id: '',
        category_name: '',
        total_allocated_for_property: 0,
        amount_left: 0,
      },
    ],
    budget_id: '',
    creator_profile: {
      creator_profile_id: '',
      email_address: '',
      profile_img: '',
      last_name: '',
      user_id: '',
    },
    email_address: '',
    first_name: '',
    is_active_budget: false,
    last_name: '',
    month: 0,
    record_time_stamp: 0,
    total_left: 0,
    total_sum: 0,
    user_id: '',
    year: 0,
  });

  //budgetChartData

  const [budgetExpenseChartData, setBudgetExpenseChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Budget Breakdown',
        data: [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  });

  const getLatestBudget = async () => {
    //get the latest budget
    const latestBudget = await fetchLatestBudget();
    //console.log('current budget');
    //console.log(latestBudget);
    setLatestBudget(latestBudget);

    if (latestBudget) {
      setIsLoading(false);
      calculateMostSpentCategory(
        latestBudget.budget_categories,
        setMostSpentCategory
      );

      calculateMostSavedCategory(
        latestBudget.budget_categories,
        setMostSavedCategory
      );

      getLatestBudgetAndChartData(latestBudget);
    } else {
      setIsLoading(false);
    }
  };

  const getSecondLatestBudget = async (mostSpentCategoryValue) => {
    const secondLatestBudgetData = await fetchSecondLatestBudget();
    setSecondLatestBudget(secondLatestBudgetData);

    if (secondLatestBudgetData) {
      //console.log('Second latest budget');
      //console.log(secondLatestBudgetData);

      calculateAndSetSecondLatestBudgetSummary(secondLatestBudgetData);

      calculatePercentageChange(mostSpentCategoryValue, secondLatestBudgetData);
    } else {
      setIsLoading(false);
    }
  };

  const getSecondLatestBudgetForMostSaved = async (mostSavedCategoryValue) => {
    const secondLatestBudgetData = await fetchSecondLatestBudget();
    setSecondLatestBudget(secondLatestBudgetData);

    if (secondLatestBudgetData && mostSavedCategoryValue.category) {
      //console.log('Second latest budget for most saved');
      //console.log(secondLatestBudgetData);

      calculateAndSetSecondLatestBudgetSummary(secondLatestBudgetData);

      calculatePercentageChangeForMostSaved(
        mostSavedCategoryValue,
        secondLatestBudgetData
      );
    }
  };

  const calculateAndSetSecondLatestBudgetSummary = (secondLatestBudget) => {
    const totalAllocated = secondLatestBudget.budget_categories.reduce(
      (sum, category) => sum + category.total_allocated_for_property,
      0
    );
    const totalLeft = secondLatestBudget.budget_categories.reduce(
      (sum, category) => sum + category.amount_left,
      0
    );

    //total spent
    const totalSpent = secondLatestBudget.budget_categories.reduce(
      (sum, category) =>
        sum + (category.total_allocated_for_property - category.amount_left),
      0
    );

    setSecondLatestBudgetSummary({ totalAllocated, totalLeft, totalSpent });
  };

  const calculatePercentageChangeForMostSaved = (
    mostSavedCategoryValue,
    secondLatestBudgetData
  ) => {
    const mostSavedCategoryPrevious =
      secondLatestBudgetData.budget_categories.find(
        (category) =>
          category &&
          mostSavedCategoryValue.category &&
          category.category_name ===
            mostSavedCategoryValue.category.category_name
      );
    if (mostSavedCategoryPrevious) {
      const previousSaved =
        mostSavedCategoryPrevious.total_allocated_for_property -
        mostSavedCategoryPrevious.amount_left;
      const currentSaved =
        mostSavedCategoryValue.category.total_allocated_for_property -
        mostSavedCategoryValue.category.amount_left;

      let percentageChange = 0;

      if (previousSaved !== 0) {
        percentageChange =
          ((currentSaved - previousSaved) / previousSaved) * 100;
      } else if (currentSaved !== 0) {
        percentageChange = 100;
      }

      setPercentageChangeInSavings(percentageChange);
    }
  };

  const calculatePercentageChange = (
    mostSpentCategoryValue,
    secondLatestBudgetData
  ) => {
    if (!mostSpentCategoryValue || !mostSpentCategoryValue.category) {
      //console.log('No category with any amount spent in the current budget');
      return;
    }
    if (!secondLatestBudgetData || !secondLatestBudgetData.budget_categories) {
      //console.log('Second latest budget data or its categories are not available');
      return;
    }

    //work on this
    const mostSpentCategoryPrevious =
      secondLatestBudgetData.budget_categories.find(
        (category) =>
          category.category_name ===
          mostSpentCategoryValue.category.category_name
      );

    let change;

    if (mostSpentCategoryPrevious) {
      const previousSpent =
        mostSpentCategoryPrevious.total_allocated_for_property -
        mostSpentCategoryPrevious.amount_left;
      const currentSpent =
        mostSpentCategoryValue.category.total_allocated_for_property -
        mostSpentCategoryValue.category.amount_left;
      if (previousSpent !== 0) {
        change = ((currentSpent - previousSpent) / previousSpent) * 100 * -1;
      } else {
        change = 100 * -1; // If no money was spent in the previous period, but there was money spent in the current period, that's a 100% increase
      }
    } else {
      change = NaN; // or any other value that makes sense in your context
    }

    //console.log(change);
    setPercentageChange(change);
  };
  ///

  const getLatestBudgetAndChartData = async (latestBudget) => {
    const labels = latestBudget.budget_categories.map(
      (category) => category.category_name
    );
    const data = latestBudget.budget_categories.map(
      (category) => category.total_allocated_for_property
    );

    const backgroundColor = labels.map((category) =>
      dynamicallyGeneratedColor(category, isDarkMode)
    );
    const borderColor = backgroundColor.map(
      (color) => color.replace('0.2', '1') // Set alpha channel to 1 for solid color
    );

    const hoverColors = predefinedColors.map((color) =>
      darkenColor(color, 0.7)
    ); // darken by 30%

    setBudgetExpenseChartData((prevState) => ({
      ...prevState,
      labels: labels,
      datasets: prevState.datasets.map((dataset) => ({
        ...dataset,
        data: data,
        backgroundColor: predefinedColors, // Use the predefined colors
        borderColor: isDarkMode ? '#6c757d' : 'white',
        borderWidth: 3,
        hoverBackgroundColor: hoverColors, // Use the darker colors for hover
      })),
    }));
  };

  const calculateMostSpentCategory = (
    budgetCategories,
    setMostSpentCategory
  ) => {
    const mostSpentCategoryValue = budgetCategories.reduce(
      (max, category) => {
        const amountSpent =
          category.total_allocated_for_property - category.amount_left;
        return amountSpent > max.amountSpent ? { category, amountSpent } : max;
      },
      { category: null, amountSpent: 0 }
    );

    if (mostSpentCategoryValue.category) {
      setMostSpentCategory(mostSpentCategoryValue);

      getSecondLatestBudget(mostSpentCategoryValue);

      calculateAndSetLeftToBudget(budgetCategories, setLeftToBudget);
    }
  };

  //will do something similar to the most spent category
  const calculateMostSavedCategory = (
    budgetCategories,
    setMostSavedCategory
  ) => {
    const mostSavedCategoryValue = budgetCategories.reduce(
      (min, category) => {
        const amountSaved =
          category.total_allocated_for_property - category.amount_left;
        return amountSaved < min.amountSaved ? { category, amountSaved } : min;
      },
      { category: null, amountSaved: Infinity }
    );
    if (mostSavedCategoryValue.category) {
      //console.log('Most Saved Category Found!');
      //console.log(mostSavedCategoryValue);
      setMostSavedCategory(mostSavedCategoryValue);

      getSecondLatestBudgetForMostSaved(mostSavedCategoryValue);

      calculateAndSetLeftToBudget(budgetCategories, setLeftToBudget);
    }
  };

  const calculateAndSetLeftToBudget = (budgetCategories, setLeftToBudget) => {
    const totalAllocated = budgetCategories.reduce(
      (sum, category) => sum + Number(category.total_allocated_for_property),
      0
    );
    const totalSpent = budgetCategories.reduce(
      (sum, category) =>
        sum +
        (Number(category.total_allocated_for_property) -
          Number(category.amount_left)),
      0
    );
    const leftToBudget = Number(totalAllocated) - Number(totalSpent);

    setLeftToBudget(leftToBudget);
    setTotalBudget(totalAllocated);
    setTotalSpent(totalSpent);

    const budgetDifference = leftToBudget - secondLatestBudgetSummary.totalLeft;
    calculateTotalSpentPercentageChange(totalSpent, secondLatestBudgetSummary);
    //console.log('Total Budget');
    //console.log(totalAllocated);
    // console.log('Budget Difference');
    //console.log(budgetDifference);
  };

  const calculateTotalSpentPercentageChange = (
    totalSpent,
    secondLatestBudgetSummary
  ) => {
    let percentageChange =
      secondLatestBudgetSummary.totalSpent !== 0
        ? (
            ((totalSpent - secondLatestBudgetSummary.totalSpent) /
              secondLatestBudgetSummary.totalSpent) *
            100
          ).toFixed(2)
        : 0;

    setTotalSpentPercentageChange(percentageChange);
  };

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);

    //get the latest budget
    getLatestBudget();

    //will need to end the loader after x time
    setIsLoading(false);
  }, []);

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}
      {/*/Loader */}

      {/* ============================================================== */}
      {/* Start right Content here */}
      {/* ============================================================== */}

      <div data-bs-theme={isDarkMode ? 'dark' : 'light'}>
        <div className='container-fluid'>
          {/* start page title */}

          <PageTitleBox pageTitle={pageTitle} />

          {/* end page title */}
          <div className='row'>
            <div className='col-xl-3'>
              <BudgetBreakdownCard
                lottieAnimation={emptyBudgetLottieOptions}
                budgetExpenseChartData={budgetExpenseChartData}
                isDarkMode={isDarkMode}
              />
            </div>
            <div className='col-xl-6'>
              <div className='row'>
                <div className='col-md-6'>
                  <SpendingCard
                    mostSpentCategory={mostSpentCategory}
                    percentageChange={percentageChange}
                  />
                </div>
                <div className='col-md-6'>
                  <BudgetLeftCard
                    leftToBudget={leftToBudget}
                    totalBudget={totalBudget}
                  />
                </div>
                <div className='col-md-6'>
                  <TotalSpendCard
                    totalSpentPercentageChange={totalSpentPercentageChange}
                    totalSpent={totalSpent}
                    secondLatestBudgetSummary={secondLatestBudgetSummary}
                  />
                </div>
                <div className='col-md-6'>
                  <SavingCard
                    mostSavedCategory={mostSavedCategory}
                    percentageChange={percentageChangeInSavings}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* end col*/}
        </div>{' '}
        {/* end row*/}
        <div className='row'>
          {/* Budget Breakdown */}
          {latestBudget &&
            latestBudget?.budget_categories?.length > 0 &&
            latestBudget?.budget_categories[0]?.category_name && (
              <BudgetCardGrid latestBudget={latestBudget} />
            )}

          {/* end col */}
        </div>
        {/* end row */}
        {/* container-fluid */}
      </div>
      {/* End Page-content */}

      {/* End Page-content */}
    </React.Fragment>
  );
};

export default CurrentBudget;
