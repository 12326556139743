import React from 'react';
import { Link } from 'react-router-dom';
import { base64ImageString } from '../../../../constants/images/defaultImgs';

const ApprovedPalCard = ({ pal, storedUserId }) => {
  return (
   
      <div className='card card-animate' key={pal.pal_id}>
        <div className='card-body'>
          <div className='d-flex justify-content-between'>
            <div>
              <p className='fw-medium text-muted mb-0'>Approved Pal</p>

              <Link to={`/palz/${pal.pal_id}`}>
                <h4 className='mt-4 ff-secondary fw-semibold'>
                  <span className='counter-value' data-target='28.05'>
                    {pal.primary_pal_id === storedUserId
                      ? `${pal.pal_first_name} ${pal.pal_last_name}`
                      : `${pal.requestor_first_name} ${pal.requestor_last_name}`}
                  </span>
                </h4>
              </Link>
            </div>
            <div>
              <div className='avatar-sm flex-shrink-0'>
                <img
                  src={`data:image/png;base64,${
                    pal.primary_pal_id === storedUserId
                      ? pal.pal_profile_image || base64ImageString
                      : pal.requestor_profile_image || base64ImageString
                  }`}
                  alt='Profile'
                  className='avatar-title bg-info-subtle rounded-circle fs-2'
                  style={{ width: '50px', height: '50px' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

  );
};

export default ApprovedPalCard;
