import React, { createContext, useState, useContext } from 'react';

const SubscriptionContext = createContext();
//this is subscription invoice
export function SubscriptionProvider({ children }) {
  const [subscription, setSubscription] = useState({
    stripe_customer_id: '',
    customer: '',
    customer_address: {
      city: '',
      country: '',
      line1: '',
      line2: null,
      postal_code: '',
      state: '',
    },
    customer_email: '',
    customer_name: '',
    customer_phone: null,
    customer_tax_exempt: '',
    customer_tax_ids: [],
    paid: false,
    amount_due: 0,
    amount_paid: 0,
    amount_remaining: 0,
    status: '',
    attempt_count: 0,
    created: 0,
    description: null,
    discount: null,
    due_date: null,
    currency: '',
    hosted_invoice_url: '',
    invoice_pdf: '',
    id: '',
    lines: {
      object: '',
      data: [
        {
          id: '',
          object: '',
          amount: 0,
          amount_excluding_tax: 0,
          currency: '',
          description: '',
          discount_amounts: [],
          discountable: false,
          discounts: [],
          invoice: '',
          livemode: false,
          metadata: {},
          period: {
            end: 0,
            start: 0,
          },
          plan: {
            id: '',
            object: '',
            active: false,
            aggregate_usage: null,
            amount: 0,
            amount_decimal: '0',
            billing_scheme: '',
            created: 0,
            currency: '',
            interval: '',
            interval_count: 0,
            livemode: false,
            metadata: {},
            meter: null,
            nickname: '',
            product: '',
            tiers_mode: null,
            transform_usage: null,
            trial_period_days: null,
            usage_type: '',
          },
          price: {
            id: '',
            object: '',
            active: false,
            billing_scheme: '',
            created: 0,
            currency: '',
            custom_unit_amount: null,
            livemode: false,
            lookup_key: '',
            metadata: {},
            nickname: '',
            product: '',
            recurring: {
              aggregate_usage: null,
              interval: '',
              interval_count: 0,
              meter: null,
              trial_period_days: null,
              usage_type: '',
            },
            tax_behavior: '',
            tiers_mode: null,
            transform_quantity: null,
            type: '',
            unit_amount: 0,
            unit_amount_decimal: '0',
          },
          proration: false,
          proration_details: {
            credited_items: null,
          },
          quantity: 0,
          subscription: '',
          subscription_item: '',
          tax_amounts: [
            {
              amount: 0,
              inclusive: false,
              tax_rate: '',
              taxability_reason: '',
              taxable_amount: 0,
            },
          ],
          tax_rates: [],
          type: '',
          unit_amount_excluding_tax: '0',
        },
      ],
      has_more: false,
      total_count: 0,
      url: '',
    },
    next_payment_attempt: null,
    number: '',
    period_end: 0,
    period_start: 0,
    receipt_number: null,
    payment_intent: '',
    payment_settings: {
      default_mandate: null,
      payment_method_options: {
        acss_debit: null,
        bancontact: null,
        card: {
          request_three_d_secure: 'automatic',
        },
        customer_balance: null,
        konbini: null,
        sepa_debit: null,
        us_bank_account: null,
      },
      payment_method_types: null,
    },
  });

  return (
    <SubscriptionContext.Provider value={{ subscription, setSubscription }}>
      {children}
    </SubscriptionContext.Provider>
  );
}

export function useSubscription() {
  return useContext(SubscriptionContext);
}
