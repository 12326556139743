import React, { useState } from 'react';

import NavDropdown from 'react-bootstrap/NavDropdown';
import PendingPalCard from '../../../../reusable/cards/palz/pendingPalCard';

const PendingPalGrid = ({ allApprovedPalz, storedUserId }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('Name');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredPalz = allApprovedPalz.filter((pal) =>
    `${pal.pal_first_name} ${pal.pal_last_name} ${pal.requestor_first_name} ${pal.requestor_last_name}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const sortedPalz = [...filteredPalz].sort((a, b) => {
    switch (sortOption) {
      case 'Name':
        return `${a.pal_first_name} ${a.pal_last_name}`.localeCompare(
          `${b.pal_first_name} ${b.pal_last_name}`
        );
      case 'Date':
        const dateA = new Date(a.date_link_created);
        const dateB = new Date(b.date_link_created);
        return dateB - dateA;
      default:
        return 0;
    }
  });

  return (
    <div>
      <div className='row px-4'>
        <div className='col-12'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent'>
            <div className='col-md-3'>
              <h4 className='mb-sm-0'>Pending Palz</h4>
            </div>

            <div className='col-md-6'>
              <div id='contact-existing-list'>
                <div className='row mb-2'>
                  <div className='col'>
                    <input
                      className='search form-control'
                      placeholder='Search'
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-3 text-end'>
              <NavDropdown title={`Sort by: ${sortOption}`} id='nav-dropdown'>
                <NavDropdown.Item onClick={() => setSortOption('Name')}>
                  Name
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setSortOption('Date')}>
                  Date
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
        </div>
      </div>
      <div className='row pt-4 px-3'>
        {sortedPalz.map((pal) => (
          <div className='col-3' key={pal.pal_id}>
            <PendingPalCard pal={pal} storedUserId={storedUserId} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PendingPalGrid;
