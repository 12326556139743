//handles the login and registration
import http from "../general/httpService";
import config from "../../constants/config.json";
import jwtDecode from "jwt-decode";
import { firebase_auth } from "../../firebase";
import { signInWithEmailAndPassword,createUserWithEmailAndPassword,signOut } from "firebase/auth";
import { User } from "./model/user";
import axios from "axios";
//declaring the constants
const tokenKey = config.access_token;
const refreshToken = config.refresh_token;
const email = config.email;
const userKey = config.user_id;
const firstName = config.first_name;
const lastName = config.last_name;
const loginCode = config.login_code;



//Need to send the data to the server , prescreening model, 
//firebase account, the go to the portal

http.setJwt(getJwt());


//need to change out the methods
export async function sendLoginRequest(accountPayLoad) {


    try{
     // console.log("Sending login request");
      //console.log(`Email: ${accountPayLoad.email_address} `);

      const fullApiEndpoint = config.apiEndpoint + "/authentication/send_login_code";
      const {data: message} = await http.post(fullApiEndpoint, accountPayLoad);

      //console.log("API Response");
     // console.log(message);

      return message;
    }
    catch(ex){
      //console.log(ex);
    }
  }



//need a LOGIN
export async function loginUser(accountPayLoad) {
  try {
   // console.log("Logging in");
    //console.log(`Email: ${accountPayLoad.email_address} `);

    const fullApiEndpoint = config.apiEndpoint + "/authentication/pwdless_login";
    const { data } = await http.post(fullApiEndpoint, accountPayLoad);

   // console.log("API Response");
    //console.log(data);
    //set if no error
    // Store tokens and other information in the local storage
    localStorage.setItem(config.access_token, data.access_token);
    localStorage.setItem(config.refresh_token, data.refresh_token);
    localStorage.setItem(config.user_id, data.user_id);

    //localStorage.setItem(config.first_name, data.first_name);
    //localStorage.setItem(config.last_name, data.last_name);
    localStorage.setItem(config.email, accountPayLoad.email_address);
    localStorage.setItem(config.login_code, accountPayLoad.login_code);

    // Return the server message and the is_error property
    return { message: data.server_message, isError: data.is_error };
  } catch (ex) {
    console.error(ex);
    // If there's an error, return it to the user
    return { message: ex.message, isError: true };
  }
}


///authentication/pwdless_register
export async function registerUser(accountPayLoad) {
  try {
    //console.log("Registering user");
    //console.log(`Email: ${accountPayLoad.email_address} `);

    const fullApiEndpoint = config.apiEndpoint + "/authentication/pwdless_register";
    const { data } = await http.post(fullApiEndpoint, accountPayLoad);

    //console.log("API Response");
    //console.log(data);
    //set if no error
    

    // Return the server message and the is_error property
    return { message: data.server_message, isError: data.is_error };
  }
  catch (ex) {
    console.error(ex);
    // If there's an error, return it to the user
    return { message: ex.message, isError: true };
  }
}


//TODO: need to change these methods to the new ones


export async function getCurrentUserProfileByEmail() {
  const token = localStorage.getItem(tokenKey);
  const email = localStorage.getItem(config.email);

  const fullApiEndpoint =
    config.apiEndpoint +
    `/authentication/fetch_user_by_email`;
  
  //console.log(`Email: ${email}`);
  //console.log(`Token: ${token}`);
 
  const {data: response} = await http.post(fullApiEndpoint, {email_address: email});

  //console.log("API Response");
  //console.log(response);

  //remove expires_at, password, security_question, security_answer, login_code, _id
  delete response.expires_at;
  delete response.password;
  delete response.security_question;
  delete response.security_answer;
  delete response.login_code;
  delete response._id;

  const userData = {
    userId: response.user_pal_id,
    firstName: response.first_name,
    lastName: response.last_name,
    emailAddress: response.email_address,
    profileImg: response.profile_img,
    stripeCustomerId: response.stripe_customer_id,

  };
  return userData;
}


export function loginWithJwt(jwt) {
	localStorage.setItem(tokenKey, jwt);
}

export async function logout() {
  const fullApiEndpoint = config.apiEndpoint + "/authentication/logout";

  const { data: response } = await http.post(fullApiEndpoint, new User(
    localStorage.getItem(config.user_id),
    localStorage.getItem(config.first_name),
    localStorage.getItem(config.last_name),
    localStorage.getItem(config.email),
    null,
    null,
    null,
    null,
    localStorage.getItem(config.login_code),
    

  ));

  //console.log(response);

  if (response.is_error === false) {
    //console.log("User logged out successfully");
    // clear the local storage
    localStorage.removeItem(config.access_token);
    localStorage.removeItem(config.refresh_token);
    localStorage.removeItem(config.email);
    localStorage.removeItem(config.user_id);
    localStorage.removeItem(config.first_name);
    localStorage.removeItem(config.last_name);
    localStorage.removeItem(config.login_code);
    localStorage.removeItem(config.profile_img);


    // return true to indicate successful logout
    return true;
  } else {
    //still clear 
    //look into why there is  undefined token left over
        localStorage.removeItem(config.access_token);
        localStorage.removeItem(config.refresh_token);
        localStorage.removeItem(config.email);
        localStorage.removeItem(config.user_id);
        localStorage.removeItem(config.first_name);
        localStorage.removeItem(config.last_name);
        localStorage.removeItem(config.login_code);
         localStorage.removeItem(config.profile_img);
    // return false to indicate unsuccessful logout
    return true;
  }

}

export function getCurrentUser() {
	try {
		//get current user
		const jwt = localStorage.getItem(tokenKey);
		//alert(jwt);
		//if its an object
		//return jwtDecode(jwt);
		return jwt;
	} catch (ex) {
		return null;
	}
}

export function getJwt() {
	return localStorage.getItem(tokenKey);
}

///authentication/find_email_by_login_code
export async function findEmailByLoginCode(loginCode) {
  try {

    const fullApiEndpoint = config.apiEndpoint + "/authentication/find_email_by_login_code";
    const { data } = await http.post(fullApiEndpoint, {login_code: loginCode});

 
    return { message: data.server_message, isError: data.is_error };
  } catch (ex) {
    console.info(ex);
    // If there's an error, return it to the user
    return { message: ex.message, isError: true };
  }
}


