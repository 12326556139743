import React, { useState } from 'react';
import { getIconUrl } from '../../../../constants/iconUrlMap';
import NavDropdown from 'react-bootstrap/NavDropdown';

const ExpenseTransactionGrid = ({ transactions }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('Description');

  const filteredTransactions = transactions
    .filter((transaction) =>
      transaction.history_description
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      switch (sortOption) {
        case 'Description':
          return a.history_description.localeCompare(b.history_description);
        case 'Amount':
          return a.amount - b.amount;
        case 'Date':
          const aDate = new Date(a.time_stamp);
          const bDate = new Date(b.time_stamp);
          return aDate - bDate;
        default:
          return 0;
      }
    });
  function getOrdinalSuffix(day) {
    if (day % 10 === 1 && day !== 11) {
      return day + 'st';
    } else if (day % 10 === 2 && day !== 12) {
      return day + 'nd';
    } else if (day % 10 === 3 && day !== 13) {
      return day + 'rd';
    } else {
      return day + 'th';
    }
  }

  return (
    <div>
      <div className='row pt-4 px-5'>
        <div className='col-12'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent'>
            <div className='col-md-3'>
              <h4 className='mb-sm-0'>Recent Expenses</h4>
            </div>

            <div className='col-md-6'>
              <div id='contact-existing-list'>
                <div className='row mb-2'>
                  <div className='col'>
                    <input
                      className='search form-control'
                      placeholder='Search'
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-3 text-end'>
              <NavDropdown title={`Sort by: ${sortOption}`} id='nav-dropdown'>
                <NavDropdown.Item onClick={() => setSortOption('Description')}>
                  Description
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setSortOption('Amount')}>
                  Amount
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setSortOption('Date')}>
                  Date
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
        </div>
      </div>
      <div className='row px-4'>
        {filteredTransactions.map((transaction, index) => (
          <div key={index} className='col-xl-3 '>
            <div className='card card-height-100'>
              <div className='card-body'>
                <div className='d-flex align-items-start'>
                  <div className='flex-grow-1 overflow-hidden'>
                    <h5 className='contact-name fs-13 mb-1'>
                      <a href='#' className='link text-body'>
                        {`${getOrdinalSuffix(
                          transaction.history_day
                        )} ${new Intl.DateTimeFormat('en-US', {
                          month: 'long',
                          year: 'numeric',
                        }).format(
                          new Date(
                            transaction.history_year,
                            transaction.history_month - 1,
                            transaction.history_day
                          )
                        )}`}
                      </a>
                    </h5>
                    <p className='contact-born text-muted mb-0'>
                      {transaction.history_description}
                    </p>
                  </div>

                  <div className='flex-shrink-0 ms-2'>
                    <div
                      className='fs-5 text'
                      style={{ color: 'red', fontWeight: 'bold' }}
                    >
                      {'-$'} {Number(transaction.amount)?.toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExpenseTransactionGrid;
