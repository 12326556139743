import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const BudgetGoalDonutChart = ({ budgetGoalChartData, isDarkMode }) => {
  return (
    <>
      <div className='card card-height-100'>
        <div className='card-header align-items-center d-flex'>
          <h4 className='card-title mb-0 flex-grow-1'>Budget Goal</h4>
        </div>
        {/* end card header */}
        <div className='card-body'>
          <div style={{ width: '100%', height: '100%' }}>
            <Doughnut
              data={budgetGoalChartData}
              options={{
                maintainAspectRatio: true,
                responsive: true,
                plugins: {
                  legend: {
                    display: true,
                    labels: {
                      color: isDarkMode ? 'white' : '#6c757d',
                    },
                  },
                },
              }}
            />
          </div>
        </div>
        {/* end card body */}
      </div>
      {/* end card */}
    </>
  );
};

export default BudgetGoalDonutChart;
