export function formatDate(timestamp) {
    let dateObject = new Date(timestamp * 1000);
    let formattedDate = dateObject.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });

    return formattedDate;
  }
