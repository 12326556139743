import React, { useState } from 'react';
import { getIconUrl } from '../../../../constants/iconUrlMap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { base64ImageString } from '../../../../constants/images/defaultImgs';

const ExpenseGrid = ({ expenses, title = 'Recent Expenses' }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('Expense Name');

  const filteredExpenses = expenses
    .filter((expense) =>
      expense.expense_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      switch (sortOption) {
        case 'Expense Name':
          return a.expense_name.localeCompare(b.expense_name);
        case 'Amount':
          const aAmount =
            a.transactions.length > 0
              ? a.transactions[a.transactions.length - 1].amount
              : a.expense_amt;
          const bAmount =
            b.transactions.length > 0
              ? b.transactions[b.transactions.length - 1].amount
              : b.expense_amt;
          return aAmount - bAmount;
        case 'Date':
          const aDate =
            a.transactions.length > 0
              ? new Date(a.transactions[a.transactions.length - 1].date)
              : new Date();
          const bDate =
            b.transactions.length > 0
              ? new Date(b.transactions[b.transactions.length - 1].date)
              : new Date();
          return aDate - bDate;
        default:
          return 0;
      }
    });

  function getOrdinalSuffix(day) {
    if (day % 10 === 1 && day !== 11) {
      return day + 'st';
    } else if (day % 10 === 2 && day !== 12) {
      return day + 'nd';
    } else if (day % 10 === 3 && day !== 13) {
      return day + 'rd';
    } else {
      return day + 'th';
    }
  }

  return (
    <div>
      <div className='row pt-4 px-5'>
        <div className='col-12'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent'>
            <div className='col-md-3'>
              <h4 className='mb-sm-0'>{title}</h4>
            </div>

            <div className='col-md-6'>
              <div id='contact-existing-list'>
                <div className='row mb-2'>
                  <div className='col'>
                    <input
                      className='search form-control'
                      placeholder='Search'
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-3 text-end'>
              <NavDropdown title={`Sort by: ${sortOption}`} id='nav-dropdown'>
                <NavDropdown.Item onClick={() => setSortOption('Expense Name')}>
                  Expense Name
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setSortOption('Amount')}>
                  Amount
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setSortOption('Date')}>
                  Date
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
        </div>
      </div>
      <div className='row px-4'>
        {filteredExpenses.map((expense, index) => (
          <div key={index} className='col-xl-3 '>
            <div className='card card-height-100'>
              <div className='card-body'>
                <li key={index} className='list-group-item' data-id='01'>
                  <div className='d-flex align-items-start'>
                    <div className='flex-shrink-0 me-3'>
                      <div>
                        <img
                          className='image avatar-xs rounded-circle'
                          alt=''
                          src={getIconUrl(expense.expense_icon).url}
                        />
                      </div>
                    </div>

                    <div className='flex-grow-1 overflow-hidden'>
                      <h5 className='contact-name fs-13 mb-1'>
                        <Link
                          to={`/budgetRelatedExpenses/${
                            expense.transactions &&
                            expense.transactions.length > 0
                              ? expense.transactions[
                                  expense.transactions.length - 1
                                ].budget_category_selected_id
                              : ''
                          }`}
                          className='link text-body'
                        >
                          {expense.expense_name}
                        </Link>
                      </h5>
                      <p className='contact-born text-muted mb-0'>
                        {(
                          (expense.transactions &&
                          expense.transactions.length > 0
                            ? expense.transactions[
                                expense.transactions.length - 1
                              ].history_description
                            : expense.expense_description) || ''
                        ).length > 200
                          ? (
                              (expense.transactions &&
                              expense.transactions.length > 0
                                ? expense.transactions[
                                    expense.transactions.length - 1
                                  ].history_description
                                : expense.expense_description) || ''
                            ).substring(0, 200) + '...'
                          : (expense.transactions &&
                            expense.transactions.length > 0
                              ? expense.transactions[
                                  expense.transactions.length - 1
                                ].history_description
                              : expense.expense_description) || ''}
                      </p>
                      <p>
                        {/* clock icon*/}
                        <i className='bx bx-time'></i>

                        <a className='link text-body'>
                          {expense.transactions &&
                          expense.transactions.length > 0
                            ? `${getOrdinalSuffix(
                                expense.transactions[
                                  expense.transactions.length - 1
                                ].history_day
                              )} ${new Intl.DateTimeFormat('en-US', {
                                month: 'long',
                                year: 'numeric',
                              }).format(
                                new Date(
                                  expense.transactions[
                                    expense.transactions.length - 1
                                  ].history_year,
                                  expense.transactions[
                                    expense.transactions.length - 1
                                  ].history_month - 1,
                                  expense.transactions[
                                    expense.transactions.length - 1
                                  ].history_day
                                )
                              )}`
                            : 'No transactions'}
                        </a>
                      </p>
                    </div>

                    <div className='d-flex flex-column align-items-end'>
                      {(expense.pal_first_name ||
                        expense.pal_last_name ||
                        expense.pal_profile_image ||
                        expense.requestor_first_name ||
                        expense.requestor_last_name ||
                        expense.requestor_profile_image) && (
                        <div className='flex-shrink-0 ms-2'>
                          <img
                            src={`data:image/png;base64,${
                              expense.user_pal_id === expense.primary_pal_id
                                ? expense.pal_profile_image ||
                                  expense.requestor_profile_image ||
                                  base64ImageString
                                : expense.requestor_profile_image ||
                                  expense.pal_profile_image ||
                                  base64ImageString
                            }`}
                            alt='Profile'
                            className='avatar-title bg-info-subtle rounded-circle fs-2'
                            style={{ width: '25px', height: '25px' }} // Adjusted size to half
                          />
                          <div className='mt-0'>
                            {expense.user_pal_id === expense.primary_pal_id
                              ? `${
                                  expense.pal_first_name?.[0] ||
                                  expense.requestor_first_name?.[0] ||
                                  ''
                                } ${
                                  expense.pal_last_name?.[0] ||
                                  expense.requestor_last_name?.[0] ||
                                  ''
                                }`
                              : `${
                                  expense.requestor_first_name?.[0] ||
                                  expense.pal_first_name?.[0] ||
                                  ''
                                } ${
                                  expense.requestor_last_name?.[0] ||
                                  expense.pal_last_name?.[0] ||
                                  ''
                                }`}
                          </div>
                        </div>
                      )}

                      <div className='mt-2'>
                        <div
                          className='fs-5 text'
                          style={{ color: 'red', fontWeight: 'bold' }}
                        >
                          {'-$'}{' '}
                          {expense.transactions &&
                          expense.transactions.length > 0
                            ? Number(
                                expense.transactions[
                                  expense.transactions.length - 1
                                ].amount
                              )?.toFixed(2)
                            : Number(expense.expense_amt)?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExpenseGrid;
