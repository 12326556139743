import React, { useEffect, useState } from 'react';
import { getIconUrl, iconUrlMap } from '../../../../constants/iconUrlMap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { Modal, Badge, Button } from 'react-bootstrap';
import Select from 'react-select';

const CreateBudgetCategoryGrid = ({
  updateCategory,
  deleteCategory,
  budget_categories,
  iconSelectOptions,
}) => {
  const [sortOption, setSortOption] = useState('Category Name');
  const [showModal, setShowModal] = useState(false);
  const sortBudgetCategories = () => {
    let sortedCategories;
    switch (sortOption) {
      case 'Category Name':
        sortedCategories = [...budget_categories].sort((a, b) =>
          a.category_name.localeCompare(b.category_name)
        );
        break;
      case 'Amount Budgeted':
        sortedCategories = [...budget_categories].sort(
          (a, b) =>
            a.total_allocated_for_property - b.total_allocated_for_property
        );
        break;
      case 'Amount Left':
        sortedCategories = [...budget_categories].sort(
          (a, b) => a.amount_left - b.amount_left
        );
        break;
      case 'Amount Spent':
        sortedCategories = [...budget_categories].sort(
          (a, b) =>
            a.total_allocated_for_property -
            a.amount_left -
            (b.total_allocated_for_property - b.amount_left)
        );
        break;
      default:
        sortedCategories = budget_categories;
    }
    return sortedCategories;
  };

  const sortedBudgetCategories = sortBudgetCategories();

  //currently selected budget category
  const [selectedBudgetCategory, setSelectedBudgetCategory] = useState(null);

  const capitalize = (str) => {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const formatOptionLabel = ({ value, label, url }) => (
    <div>
      <img
        src={url}
        alt={label}
        style={{ width: '20px', marginRight: '10px' }}
      />
      {capitalize(value.split(/(?=[A-Z])/).join(' '))}
    </div>
  );

  return (
    <>
      <Modal
        key={showModal}
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Body>
          <div
            className='modal-header p-3 bg-info-subtle'
            style={{ padding: '0 !important' }}
          >
            <h5 className='modal-title' id='modal-title'>
              Budget Category
            </h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-hidden='true'
              onClick={() => setShowModal(false)}
            ></button>
          </div>
        </Modal.Body>
        <Modal.Body>
          <div className='card-body'>
            <div className='live-preview'>
              <div className='row gy-4'>
                <div className='col-xxl-6 col-md-6'>
                  <div>
                    <label>Category Name</label>
                    <input
                      type='text'
                      className='form-control'
                      name='category_name'
                      value={
                        selectedBudgetCategory
                          ? selectedBudgetCategory.category_name
                          : ''
                      }
                      onChange={(e) => {
                        setSelectedBudgetCategory({
                          ...selectedBudgetCategory,
                          category_name: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className='col-xxl-6col-md-6'>
                  <div>
                    <label>Category Icon</label>

                    <Select
                      name='expense_icon'
                      value={
                        selectedBudgetCategory
                          ? iconSelectOptions.find(
                              (option) =>
                                option.value ===
                                selectedBudgetCategory.category_icon
                            )
                          : null
                      }
                      onChange={(selectedOption) => {
                        setSelectedBudgetCategory({
                          ...selectedBudgetCategory,
                          category_icon: selectedOption
                            ? selectedOption.value
                            : '',
                        });
                      }}
                      options={iconSelectOptions}
                      formatOptionLabel={formatOptionLabel}
                    />
                  </div>
                </div>
                <div className='col-xxl-6 col-md-6'>
                  <div>
                    <label>Total Allocated For Category</label>
                    <input
                      type='text'
                      className='form-control'
                      name='total_allocated_for_property'
                      value={
                        selectedBudgetCategory
                          ? selectedBudgetCategory.total_allocated_for_property
                          : ''
                      }
                      onChange={(e) => {
                        setSelectedBudgetCategory({
                          ...selectedBudgetCategory,
                          total_allocated_for_property: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/*modalItems.length > 1 && (
            <Button onClick={() => setShowModal(false)}>Close</Button>
          )*/}
          <button
            className='btn btn-danger'
            onClick={() => {
              deleteCategory(selectedBudgetCategory.budget_category_id);
              setShowModal(false);
            }}
          >
            Delete Category
          </button>
          {/*Put a space in between */}
          <button
            className='btn btn-success'
            onClick={() => {
              const updatedCategory = {
                ...selectedBudgetCategory,
                // update the properties you want to change
                amount_left:
                  selectedBudgetCategory.total_allocated_for_property,
              };
              updateCategory(updatedCategory);
              setShowModal(false);
            }}
          >
            Update Category
          </button>
        </Modal.Footer>
      </Modal>
      <div className='row pt-3 px-5'>
        <div className='col-12'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent'>
            <div className='flex-shrink-0'>
              <NavDropdown title={`Sort by: ${sortOption}`} id='nav-dropdown'>
                <NavDropdown.Item
                  onClick={() => setSortOption('Category Name')}
                >
                  Category Name
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => setSortOption('Amount Budgeted')}
                >
                  Amount Budgeted
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setSortOption('Amount Left')}>
                  Amount Left
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
        </div>
      </div>

      <div className='row px-4'>
        {sortedBudgetCategories.map((category, index) => {
          const amountBudgeted = category.total_allocated_for_property || 0;
          const amountLeft = category.amount_left || 0;
          const amountSpent = amountBudgeted - amountLeft;
          const progress =
            amountBudgeted !== 0 ? (amountSpent / amountBudgeted) * 100 : 0;

          let barColor = 'bg-secondary';
          if (progress >= 95) {
            barColor = 'bg-danger';
          } else if (progress >= 80) {
            barColor = 'bg-warning';
          } else {
            barColor = 'bg-success';
          }

          return (
            <div key={index} className='col-xl-3'>
              <div className='card card-height-100'>
                <div className='card-body p-0'>
                  <ul className='list list-group list-group-flush mb-0'>
                    <li className='list-group-item' data-id='01'>
                      <div className='d-flex align-items-start'>
                        <div className='flex-shrink-0 me-3'>
                          <div>
                            <img
                              className='image avatar-xs rounded-circle'
                              alt=''
                              src={getIconUrl(category.category_icon).url}
                            />
                          </div>
                        </div>

                        <div className='flex-grow-1'>
                          <div className='d-flex justify-content-between align-items-start'>
                            <div className='overflow-hidden'>
                              <h5 className='contact-name fs-13 mb-1'>
                                {/*go to budgetRelatedExpenses */}
                                <Link
                                  //to={`/budgetRelatedExpenses/${category.budget_category_id}`}
                                  onClick={() => {
                                    setSelectedBudgetCategory(category);
                                    setShowModal(true);
                                  }}
                                  className='link text-body'
                                >
                                  {category.category_name}
                                </Link>
                              </h5>
                            </div>

                            <div className='d-flex flex-column align-items-end'>
                              {category.isMerged ? (
                                <div className='d-flex'>
                                  <div className='flex-shrink-0 ms-2'>
                                    <img
                                      src={`data:image/png;base64,${category.pal_profile_image}`}
                                      alt='Profile'
                                      className='avatar-title bg-info-subtle rounded-circle fs-2'
                                      style={{ width: '25px', height: '25px' }}
                                    />
                                    <div className='mt-0'>
                                      {`${category.pal_first_name?.[0] || ''} ${
                                        category.pal_last_name?.[0] || ''
                                      }`}
                                    </div>
                                  </div>
                                  <div className='flex-shrink-0 ms-2'>
                                    <img
                                      src={`data:image/png;base64,${category.requestor_profile_image}`}
                                      alt='Profile'
                                      className='avatar-title bg-info-subtle rounded-circle fs-2'
                                      style={{ width: '25px', height: '25px' }}
                                    />
                                    <div className='mt-0'>
                                      {`${
                                        category.requestor_first_name?.[0] || ''
                                      } ${
                                        category.requestor_last_name?.[0] || ''
                                      }`}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className='flex-shrink-0 ms-2'>
                                  {category.pal_first_name ||
                                  category.pal_last_name ||
                                  category.pal_profile_image ||
                                  category.requestor_first_name ||
                                  category.requestor_last_name ||
                                  category.requestor_profile_image ? (
                                    <div>
                                      <img
                                        src={`data:image/png;base64,${
                                          category.user_pal_id ===
                                          category.primary_pal_id
                                            ? category.pal_profile_image ||
                                              category.requestor_profile_image
                                            : category.requestor_profile_image ||
                                              category.pal_profile_image
                                        }`}
                                        alt='Profile'
                                        className='avatar-title bg-info-subtle rounded-circle fs-2'
                                        style={{
                                          width: '25px',
                                          height: '25px',
                                        }} // Adjusted size to half
                                      />
                                      <div className='mt-0'>
                                        {category.user_pal_id ===
                                        category.primary_pal_id
                                          ? `${
                                              category.pal_first_name?.[0] ||
                                              category
                                                .requestor_first_name?.[0] ||
                                              ''
                                            } ${
                                              category.pal_last_name?.[0] ||
                                              category
                                                .requestor_last_name?.[0] ||
                                              ''
                                            }`
                                          : `${
                                              category
                                                .requestor_first_name?.[0] ||
                                              category.pal_first_name?.[0] ||
                                              ''
                                            } ${
                                              category
                                                .requestor_last_name?.[0] ||
                                              category.pal_last_name?.[0] ||
                                              ''
                                            }`}
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      className='fs-5 text'
                                      style={{ fontWeight: 'bold' }}
                                    >
                                      $
                                      {amountSpent &&
                                        `${Number(amountSpent).toFixed(2)}`}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className='overflow-hidden'>
                            <div className='no-padding card-body '>
                              <div className='d-flex justify-content-between '>
                                <div>
                                  <h6 className='mb-0'>
                                    <b className='text'>
                                      ${Number(amountSpent)?.toFixed(2)}
                                    </b>{' '}
                                    Spent
                                  </h6>
                                </div>
                                <div>
                                  <h6 className='mb-0'>
                                    ${Number(amountLeft)?.toFixed(2)} Left
                                  </h6>
                                </div>
                                <div>
                                  <h6 className='mb-0'>
                                    ${Number(amountBudgeted).toFixed(2)}{' '}
                                    Budgeted
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className='progress bg-secondary-subtle rounded-0'>
                              <div
                                className={`progress-bar ${barColor}`}
                                role='progressbar'
                                style={{ width: `${progress}%` }}
                                aria-valuenow={progress}
                                aria-valuemin='0'
                                aria-valuemax='100'
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* end cardbody */}
              </div>
              {/* end card */}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CreateBudgetCategoryGrid;
