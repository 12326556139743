///budget/fetch_latest_budget

import http from "../general/httpService";
import api from "../../constants/config.json";
import config from "../../constants/config.json";

export async function fetchLatestBudget() {
    const userId = localStorage.getItem(config.user_id);
    const fullApiEndpoint = api.apiEndpoint + `/budget/fetch_latest_budget`;
    const {data : budgetFound} = await http.post(fullApiEndpoint,{user_id: userId});

    return budgetFound;
}

//get latest budget by userId
export async function fetchLatestBudgetByUserId(userId) {
    const fullApiEndpoint = api.apiEndpoint + `/budget/fetch_latest_budget`;
    const {data : budgetFound} = await http.post(fullApiEndpoint,{user_id: userId});

    return budgetFound;
}

export async function fetchSecondLatestBudget() {
    const userId = localStorage.getItem(config.user_id);
    const fullApiEndpoint = api.apiEndpoint + `/budget/fetch_second_latest_budget`;
    const {data : budgetFound} = await http.post(fullApiEndpoint,{user_id: userId});

    //if no budget found, return null
    if(budgetFound === null){
        return null;
    }
    return budgetFound;
}

///budget/fetch_all_past_budgets
export async function fetchAllPastBudgets() {
    const userId = localStorage.getItem(config.user_id);
    const fullApiEndpoint = api.apiEndpoint + `/budget/fetch_all_past_budgets`;
    const {data : budgetsFound} = await http.post(fullApiEndpoint,{user_id: userId});

    return budgetsFound;
}

//get budget by id
// /budget/fetch_budget_by_id
export async function fetchBudgetById(budgetId) {

    const userId = localStorage.getItem(config.user_id);
    const fullApiEndpoint = api.apiEndpoint + `/budget/fetch_budget_by_id`;
    const {data : budgetFound} = await http.post(fullApiEndpoint,{budget_id: budgetId, user_id: userId});

    return budgetFound;
}

 //get the prior budget before the current budget
 ///budget/fetch_prior_budget
export async function fetchPriorBudget(budgetId) {

    const userId = localStorage.getItem(config.user_id);
    const fullApiEndpoint = api.apiEndpoint + `/budget/fetch_prior_budget`;
    const {data : budgetFound} = await http.post(fullApiEndpoint,{budget_id: budgetId,
        user_id: userId
    });

    return budgetFound;
}

///update amount left in the budget category
export async function updateAmountLeft(budget_id, budget_category_id,new_amount_left) {
    const userId = localStorage.getItem(config.user_id);
    const fullApiEndpoint = api.apiEndpoint + `/budget/update_amount_left`;
    const {data : budgetUpdated} = await http.post(fullApiEndpoint,{user_id: userId,budget_id: budget_id,budget_category_id: budget_category_id,new_amount_left: new_amount_left});

    return budgetUpdated;
}

///budget/create_budget_and_categories
export async function createBudgetAndCategories(budgetData) {
    const fullApiEndpoint = api.apiEndpoint + `/budget/create_budget_and_categories`;
    const {data : budgetCreated} = await http.post(fullApiEndpoint,budgetData);

    return budgetCreated;
}

///budget/deactivate_other_budgets
export async function deactivateOtherBudgets(budget_id) {
    const userId = localStorage.getItem(config.user_id);
    const fullApiEndpoint = api.apiEndpoint + `/budget/deactivate_other_budgets`;
    const {data : budgetUpdated} = await http.post(fullApiEndpoint,{user_id: userId,budget_id: budget_id});

    return budgetUpdated;
}
