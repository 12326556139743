import { base64ImageString } from '../../../../../constants/images/defaultImgs';

import { toast } from 'react-toastify';
import { Navigate, useNavigate } from 'react-router-dom';
import { respondToPalRequest } from '../../../../../services/palz/palzManagement';

const PalCoCard = ({ pal }) => {
  const navigate = useNavigate();
  const handleReject = async () => {
    const palResponseDetail = {
      pal_id: pal.pal_id,
      secondary_pal_accepted_request: false,
      secondary_pal_id: '',
      date_link_created: '',
      has_access: false,
    };

    const response = await respondToPalRequest(palResponseDetail);
    if (!response.isError) {
      //dont need to go anywhere since already on Pending Palz
      toast.success('Pal request rejected');

      navigate(`/approvedPalz`);
    } else {
      toast.error('Error rejecting pal request');
      return;
    }
  };

  function formatDate(date) {
    if (typeof date === 'number') {
      // Convert timestamp to milliseconds
      date = new Date(date * 1000);
    } else if (typeof date === 'string') {
      // Handle date strings
      date = new Date(date);
    }

    // Format the date
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }

  return (
    <div className='card px-4 p-3'>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex flex-column align-items-center'>
          <img
            src={
              pal.pal_profile_image
                ? `data:image/jpeg;base64,${pal.pal_profile_image}`
                : `data:image/jpeg;base64,${base64ImageString}`
            }
            alt='Profile'
            className='rounded-circle'
            style={{ width: '35px', height: '40px' }}
          />
          <p className='mb-0'>{`${pal.pal_first_name} ${pal.pal_last_name}`}</p>
        </div>
        <div className='block text-center'>
          <p className='mb-0'>Palz since</p>
          <p className='mb-0'>{formatDate(pal.date_link_created)}</p>

          {/* only show if already approved */}
          {pal.has_access && pal.has_access === true && (
            <button
              onClick={() => {
                handleReject();
              }}
              className='btn btn-danger btn-sm mt-2'
            >
              <i className='mdi mdi-link-variant me-1'></i>
              Unlink
            </button>
          )}
        </div>
        <div className='d-flex flex-column align-items-center'>
          <img
            src={
              pal.requestor_profile_image
                ? `data:image/jpeg;base64,${pal.requestor_profile_image}`
                : `data:image/jpeg;base64,${base64ImageString}`
            }
            alt='Profile'
            className='rounded-circle'
            style={{ width: '35px', height: '40px' }}
          />
          <p className='mb-0'>{`${pal.requestor_first_name} ${pal.requestor_last_name}`}</p>
        </div>
      </div>
    </div>
  );
};

export default PalCoCard;
