///budget_goal/fetch_budget_goals_by_user_id

import http from "../general/httpService";
import api from "../../constants/config.json";
import config from "../../constants/config.json";

export async function fetchBudgetGoalsByUserId() {
    const userId = localStorage.getItem(config.user_id);
    const fullApiEndpoint = api.apiEndpoint + `/budget_goal/fetch_budget_goals_by_user_id`;
    const {data : budgetGoals} = await http.post(fullApiEndpoint,{user_id: userId});
    return budgetGoals;
}

export async function fetchLatestEightBudgetGoalsByUserId() {
    const userId = localStorage.getItem(config.user_id);
    const fullApiEndpoint = api.apiEndpoint + `/budget_goal/fetch_latest_eight_budget_goals_by_user_id`;
    const {data : budgetGoals} = await http.post(fullApiEndpoint,{user_id: userId});
    return budgetGoals;
}

export async function fetchBudgetGoalsByPalId(userId) {
   
    const fullApiEndpoint = api.apiEndpoint + `/budget_goal/fetch_budget_goals_by_user_id`;
    const {data : budgetGoals} = await http.post(fullApiEndpoint,{user_id: userId});
    return budgetGoals;
}


export async function fetchBudgetGoalById(budgetGoalId) {
    const fullApiEndpoint = api.apiEndpoint + `/budget_goal/get_budget_goal_by_id`;
    const {data : budgetGoal} = await http.post(fullApiEndpoint,{budget_goal_id: budgetGoalId});

    //console.log("Budget goal found");
    //console.log(budgetGoal);

    return budgetGoal;
}

///budget_goal/get_budget_goal_funds_by_budget_goal_id

export async function fetchBudgetGoalFundsByBudgetGoalId(budgetGoalId) {
    const fullApiEndpoint = api.apiEndpoint + `/budget_goal/get_budget_goal_funds_by_budget_goal_id`;
    const {data : budgetGoalFunds} = await http.post(fullApiEndpoint,{budget_goal_id: budgetGoalId});
    return budgetGoalFunds;
}


export async function createBudgetGoal(budgetGoal) {
    const fullApiEndpoint = api.apiEndpoint + `/budget_goal/create_budget_goal`;
    const {data : newBudgetGoal} = await http.post(fullApiEndpoint,budgetGoal);
    return newBudgetGoal;
}

///budget_goal/update_budget_goal

export async function updateBudgetGoal(budgetGoal) {
    const fullApiEndpoint = api.apiEndpoint + `/budget_goal/update_budget_goal`;
    const {data : updatedBudgetGoal} = await http.put(fullApiEndpoint,budgetGoal);
    return updatedBudgetGoal;
}

///budget_goal/create_budget_goal_funds

export async function createBudgetGoalFunds(budgetGoalFunds) {
    const fullApiEndpoint = api.apiEndpoint + `/budget_goal/create_budget_goal_funds`;
    const {data : newBudgetGoalFunds} = await http.post(fullApiEndpoint,budgetGoalFunds);
    return newBudgetGoalFunds;
}