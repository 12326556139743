import React from 'react';

const ExpensesByCategoryChart = ({ expensesByCategory }) => {
  // Convert the expensesByCategory object to an array and sort it in descending order
  const sortedExpenses = Object.entries(expensesByCategory).sort(
    (a, b) => b[1] - a[1]
  );

  // Calculate the total expenses for the progress bar
  const totalExpenses = sortedExpenses.reduce(
    (total, [, expense]) => total + expense,
    0
  );

  return (
    <>
      <div className='card card-height-100'>
        <div className='card-header align-items-center d-flex'>
          <h4 className='card-title mb-0 flex-grow-1'>Expenses By Category</h4>
        </div>
        <div className='card-body'>
          <div className='row align-items-center'>
            <div className='col-6'>
              <h6 className='text-muted text-uppercase fw-semibold text-truncate fs-12 mb-3'>
                Total Expenses
              </h6>
              <h4 className='mb-0'>${Number(totalExpenses)?.toFixed(2)}</h4>
            </div>
            <div className='col-6'>
              <div className='text-center'>
                <img
                  src='assets/images/illustrator-1.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
          </div>
          <div className='mt-3 pt-2'>
            <div className='progress progress-lg rounded-pill'>
              {sortedExpenses.map(([category, expense], index) => {
                const progress = (expense / totalExpenses) * 100;
                const barColor = [
                  'bg-primary',
                  'bg-info',
                  'bg-success',
                  'bg-warning',
                  'bg-danger',
                ][index % 5];

                return (
                  <div
                    key={category}
                    className={`progress-bar ${barColor}`}
                    role='progressbar'
                    style={{ width: `${progress}%` }}
                    aria-valuenow={progress}
                    aria-valuemin='0'
                    aria-valuemax='100'
                  ></div>
                );
              })}
            </div>
          </div>
          <div className='mt-3 pt-2'>
            {sortedExpenses.map(([category, expense], index) => {
              const progress = (expense / totalExpenses) * 100;
              const barColor = [
                'text-primary',
                'text-info',
                'text-success',
                'text-warning',
                'text-danger',
              ][index % 5];

              return (
                <div className='d-flex mb-2' key={category}>
                  <div className='flex-grow-1'>
                    <p className='text-truncate text-muted fs-14 mb-0'>
                      <i
                        className={`mdi mdi-circle align-middle ${barColor} me-2`}
                      ></i>
                      {category.toLocaleUpperCase()}
                    </p>
                  </div>
                  <div className='flex-shrink-0'>
                    <p className='mb-0'>{Number(progress)?.toFixed(2)}%</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpensesByCategoryChart;
