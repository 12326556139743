import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import loader from '../../../styletheme/images/loading/loader.svg';

import profileImg from '../../../styletheme/images/users/multi-user.jpg';

import config from '../../../constants/config.json';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { get, set } from 'lodash';
import DashboardMiniFactCard from './sub/dashboardMiniFactCard';

import { fetchLatestBudget } from '../../../services/budgets/budgetServiceManagement';
import {
  fetchCurrentExpenses,
  fetchExpensesAndTransactionsByUserPalId,
} from '../../../services/expenses/expenseServiceManagement';
import {
  fetchBudgetGoalsByUserId,
  fetchLatestEightBudgetGoalsByUserId,
} from '../../../services/budgetGoal/budgetGoalManagement';
import { fetchPalByPrimaryPalId } from '../../../services/palz/palzManagement';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { getIconUrl } from '../../../constants/iconUrlMap';
import empty_expenses_animation from '../../reusable/animations/lottie/empty_expenses_animation.json';
import no_budget_animation from '../../reusable/animations/lottie/no_budget.json';
import budget_goal_animation from '../../reusable/animations/lottie/budget_goal_animation.json';
import friends_animation from '../../reusable/animations/lottie/friends_animation.json';
import {
  predefinedColors,
  dynamicallyGeneratedColor,
  parseColor,
  darkenColor,
  colorForCategory,
  stringToHash,
} from '../../../services/helpers/colorService';

import ExpenseBreakdownCard from '../../reusable/cards/dashboard/expenseBreakdownCard';
import BudgetBreakdownCard from '../../reusable/cards/dashboard/BudgetBreakdownCard';
import BudgetGoalContributionCard from '../../reusable/cards/dashboard/budgetGoalContributionCard';
import UpgradeAlertCard from '../../reusable/cards/dashboard/upgradeAlertCard';
//'assets/images/user-illustarator-2.png'
import userIllustarator from '../../../styletheme/images/user-illustarator-2.png';
import unlockTruePotential from '../../../styletheme/images/unlock_true_potential.jpg';
import ApprovedPalCard from '../../reusable/cards/palz/approvedPalCard';
import BudgetCard from '../../reusable/cards/budget/budgetCard';
import RecentExpensesCard from '../../reusable/cards/expense/recentExpenseCard';
import BudgetGoalsCard from '../../reusable/cards/budgetGoal/budgetGoalsCard';
import LoaderView from '../../reusable/loader/loaderView';
import ExpenseCompactCalendarView from '../expenses/calendar/expenseCompactCalendarView';
import PageTitleBox from '../../reusable/portal/general/pageTitleBox';
import { useSubscription } from '../../../services/context/subscription/subscriptionContext';
import NotAllowedOverlay from '../../reusable/subscription/notAllowed/notAllowedOverlay';

const Dashboard = ({ isDarkMode }) => {
  const [isLoading, setIsLoading] = useState();
  const pageTitle = 'Dashboard';
  const isPaid = localStorage.getItem(config.is_paid);

  const { subscription } = useSubscription();

  ChartJS.register(ArcElement, Tooltip, Legend);

  //add the viewing date and time
  //need to get the viewing date and time from the database
  //going to have to give availability to the tenant to select a date and time
  const [viewingTourDetails, setViewingTourDetails] = useState({
    viewingDate: '',
    viewingTime: '',
    location: '',
  });

  //currentUserID
  const storedUserId = localStorage.getItem(config.user_id);

  const [latestBudget, setLatestBudget] = useState({
    budget_categories: [
      {
        budget_id: '',
        budget_category_id: '',
        category_name: '',
        total_allocated_for_property: 0,
        amount_left: 0,
      },
    ],
    budget_id: '',
    creator_profile: {
      creator_profile_id: '',
      email_address: '',
      profile_img: '',
      last_name: '',
      user_id: '',
    },
    email_address: '',
    first_name: '',
    is_active_budget: false,
    last_name: '',
    month: 0,
    record_time_stamp: 0,
    total_left: 0,
    total_sum: 0,
    user_id: '',
    year: 0,
  });

  //get the expenses and transactions
  //look at pagination
  const [expensesAndTransactions, setExpensesAndTransactions] = useState([
    {
      account_id: '',
      day_due_each_month: 0,
      expense_amt: 0,
      expense_category: '',
      expense_description: '',
      expense_icon: '',
      expense_id: '',
      expense_name: '',
      has_insight: false,
      is_re_occuring: false,
      record_time_stamp: 0,
      transactions: [
        {
          history_id: '',
          expense_id: '',
          investment_id: '',
          history_description: '',
          saving_acc_id: '',
          account_id: '',
          amount: 0,
          budget_amount_left: 0,
          budget_category_name: '',
          budget_category_selected_id: '',
          currency: '',
          currency_interest: 0,
          expense_image_scans: null,
          history_day: 0,
          history_month: 0,
          history_type: '',
          history_year: 0,
          is_deleted: false,
          time_stamp: 0,
          total_allocated_for_category: 0,
        },
      ],
      user_pal_id: '',
    },
  ]);

  const [fullExpensesAndTransactions, setFullExpensesAndTransactions] =
    useState([
      {
        account_id: '',
        day_due_each_month: 0,
        expense_amt: 0,
        expense_category: '',
        expense_description: '',
        expense_icon: '',
        expense_id: '',
        expense_name: '',
        has_insight: false,
        is_re_occuring: false,
        record_time_stamp: 0,
        transactions: [
          {
            history_id: '',
            expense_id: '',
            investment_id: '',
            history_description: '',
            saving_acc_id: '',
            account_id: '',
            amount: 0,
            budget_amount_left: 0,
            budget_category_name: '',
            budget_category_selected_id: '',
            currency: '',
            currency_interest: 0,
            expense_image_scans: null,
            history_day: 0,
            history_month: 0,
            history_type: '',
            history_year: 0,
            is_deleted: false,
            time_stamp: 0,
            total_allocated_for_category: 0,
          },
        ],
        user_pal_id: '',
      },
    ]);

  const [budgetGoals, setBudgetGoals] = useState([
    {
      ai_insight_msg: '',
      bank_account_id: '',
      budget_goal_id: '',
      budget_goal_sum_total: 0,
      current_amt_saved: 0,
      goal_category_icon: '',
      goal_creator_profile: {},
      goal_name: '',
      goal_type: '',
      has_achieved_goal: false,
      is_deleted: false,
      month: 3,
      pals_working_together_towards_goal: [],
      record_time_stamp: 0,
      target_end_date_raw_date: 0,
      total_left: 0,
      user_id: '',
      year: 0,
    },
  ]);

  const [allApprovedPalz, setAllApprovedPalz] = useState([
    {
      access_right: {
        access_id: '',
        can_comment: false,
        can_edit_budget_goal: false,
        can_edit_expense: false,
        can_edit_income: false,
        can_link_their_budget: false,
        can_view_budget: true,
        can_view_budget_goal: true,
        can_view_expense: true,
        can_view_income: true,
        pal_id: '',
        record_time_stamp: 0,
      },
      date_link_created: '',
      email_address: '',
      expiration_date: 0,
      has_access: false,
      invite_code: '',
      pal_first_name: '',
      pal_id: '',
      pal_last_name: '',
      pal_profile_image: '',
      primary_pal_id: '',
      requestor_first_name: '',
      requestor_last_name: '',
      requestor_profile_image: '',
    },
  ]);

  //budgetGoalChartData
  const [budgetGoalChartData, setBudgetGoalChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Budget Breakdown',
        data: [],

        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  });

  //budgetChartData

  const [budgetExpenseChartData, setBudgetExpenseChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Budget Breakdown',
        data: [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  });
  //expenseChartData
  const [expenseChartData, setExpenseChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Budget Breakdown',
        data: [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  });

  //lottie

  const emptyExpensesLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: empty_expenses_animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const emptyBudgetLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: no_budget_animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const emptyBudgetGoalLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: budget_goal_animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  //
  const emptyPalzLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: friends_animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const getLatestBudget = async () => {
    //get the latest budget
    const latestBudget = await fetchLatestBudget();
    setLatestBudget(latestBudget);

    if (latestBudget) {
      setIsLoading(false);
      getLatestBudgetAndChartData(latestBudget);
    } else {
      setIsLoading(false);
    }
  };

  const getLatestBudgetAndChartData = async (latestBudget) => {
    const labels = latestBudget.budget_categories.map(
      (category) => category.category_name
    );
    const data = latestBudget.budget_categories.map(
      (category) => category.total_allocated_for_property
    );

    const backgroundColor = labels.map((category) =>
      dynamicallyGeneratedColor(category, isDarkMode)
    );
    const borderColor = backgroundColor.map(
      (color) => color.replace('0.2', '1') // Set alpha channel to 1 for solid color
    );

    const hoverColors = predefinedColors.map((color) =>
      darkenColor(color, 0.7)
    ); // darken by 30%

    setBudgetExpenseChartData((prevState) => ({
      ...prevState,
      labels: labels,
      datasets: prevState.datasets.map((dataset) => ({
        ...dataset,
        data: data,
        backgroundColor: predefinedColors, // Use the predefined colors
        borderColor: isDarkMode ? '#6c757d' : 'white',
        borderWidth: 3,
        hoverBackgroundColor: hoverColors, // Use the darker colors for hover
      })),
    }));
  };

  const getLatestExpensesAndTransactionsChartData = async (
    expensesAndTransactions
  ) => {
    const labels = expensesAndTransactions.map(
      (expense) => expense.expense_name
    );

    const data = expensesAndTransactions.map((expense) =>
      expense.transactions.reduce(
        (sum, transaction) => sum + transaction.amount,
        0
      )
    );

    const hoverColors = predefinedColors.map((color) =>
      darkenColor(color, 0.7)
    ); // darken by 30%

    setExpenseChartData((prevState) => ({
      ...prevState,
      labels: labels,
      datasets: prevState.datasets.map((dataset) => ({
        ...dataset,
        data: data,
        backgroundColor: predefinedColors, // Use the predefined colors
        borderColor: isDarkMode ? '#6c757d' : 'white',
        borderWidth: 3,
        hoverBackgroundColor: hoverColors, // Use the darker colors for hover
      })),
    }));
  };

  const getTheLatestExpenses = async () => {
    //get the latest expenses only the current is needed
    const expensesAndTransactions = await fetchCurrentExpenses();

    setExpensesAndTransactions(expensesAndTransactions);

    if (expensesAndTransactions) {
      setFullExpensesAndTransactions(expensesAndTransactions);
      getLatestExpensesAndTransactionsChartData(expensesAndTransactions);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  //TODO work on showing budget goals next
  const getBudgetGoals = async () => {
    //get the budget goals
    const budgetGoals = await fetchLatestEightBudgetGoalsByUserId();

    //show first 8 budget goals

    setBudgetGoals(budgetGoals);
  };

  const getAllApprovedPalz = async () => {
    //get all approved palz
    const allApprovedPalz = await fetchPalByPrimaryPalId();
    //(allApprovedPalz);
    setAllApprovedPalz(allApprovedPalz);
  };

  //TODO: optimize this page so that it loads faster
  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);

    //get the latest budget
    getLatestBudget();

    //get the latest expenses
    getTheLatestExpenses();
    //after loading

    getBudgetGoals();

    getAllApprovedPalz();

    setIsLoading(false);
  }, []);

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}
      {/*/Loader */}

      {/* ============================================================== */}
      {/* Start right Content here */}
      {/* ============================================================== */}

      <div data-bs-theme={isDarkMode ? 'dark' : 'light'}>
        <div className='container-fluid'>
          {/* start page title */}
          <PageTitleBox pageTitle={pageTitle} />
          {/* end page title */}
          <div className='row'>
            {/* Make reusable */}
            <div className='col-xl-3'>
              <BudgetBreakdownCard
                lottieAnimation={emptyBudgetLottieOptions}
                budgetExpenseChartData={budgetExpenseChartData}
                isDarkMode={isDarkMode}
              />
            </div>
            <div className='col-xl-3'>
              <ExpenseBreakdownCard
                lottieAnimation={emptyExpensesLottieOptions}
                expenseChartData={expenseChartData}
                isDarkMode={isDarkMode}
              />
            </div>
            {/* end col */}
            {/* end col */}
            {/* Budget Goals */}
            <div className='col-xl-3 col-md-6'>
              <NotAllowedOverlay message='You need to be a paid user to access this feature'>
                <BudgetGoalContributionCard
                  lottieAnimation={emptyBudgetGoalLottieOptions}
                  budgetGoals={budgetGoals}
                />
              </NotAllowedOverlay>
            </div>
            {/* end col */}
            {/* Enrolled in subscription */}
            {/* CONTINUE HERE NEXT*/}
            <div className='col-xxl-3'>
              <div className='d-flex flex-column h-100'>
                <div className='row h-25'>
                  {isPaid == 'false' && (
                    <UpgradeAlertCard
                      daysLeft={30}
                      upgradeLink='/paywall'
                      imageSrc={unlockTruePotential}
                    />
                  )}

                  {/* end col*/}

                  {allApprovedPalz &&
                  allApprovedPalz.length > 0 &&
                  allApprovedPalz[0].email_address ? (
                    <>
                      <NotAllowedOverlay message='You need to be a paid user to access this feature'>
                        {allApprovedPalz.map((pal) => (
                          <div key={pal.pal_id}>
                            <ApprovedPalCard
                              pal={pal}
                              storedUserId={storedUserId}
                            />
                          </div>
                        ))}
                      </NotAllowedOverlay>
                    </>
                  ) : (
                    <>
                      <div className='col-12'>
                        <div className='card'>
                          <div className='card-body p-0'>
                            <div className='row align-items-end'>
                              <div className='col-sm-8'>
                                <div className='p-3'>
                                  <p className='fs-16 lh-base'>
                                    No palz seen as yet 🥺, your{' '}
                                    <span className='fw-semibold'>Palz</span>{' '}
                                    are on their way.
                                    <i className='mdi mdi-arrow-right'></i>
                                  </p>
                                  <div className='mt-3'>
                                    <Link
                                      to={'/inviteNewPal'}
                                      className='btn btn-info'
                                    >
                                      Invite a Pal
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='col-sm-4'>
                                <div className='px-3'>
                                  <img
                                    src={userIllustarator}
                                    className='img-fluid'
                                    alt=''
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/*Add a compact map here to show what are coming up to fill this space and make palz a smaller card like on mobile */}

                  <div className='col-xl-12 col-md-6'>
                    <ExpenseCompactCalendarView
                      expensesAndTransactions={fullExpensesAndTransactions}
                    />
                  </div>
                </div>
                {/* end row*/}
              </div>
            </div>{' '}
            {/* end col*/}
          </div>{' '}
          {/* end row*/}
          <div className='row'>
            {/* Budget Breakdown */}
            {latestBudget?.budget_categories?.length > 0 &&
              latestBudget.budget_categories[0]?.category_name && (
                <BudgetCard latestBudget={latestBudget} />
              )}
            {/* end col */}

            {/* Expense Breakdown */}
            {expensesAndTransactions &&
              expensesAndTransactions?.length > 0 &&
              expensesAndTransactions[0]?.expense_category && (
                <RecentExpensesCard
                  expensesAndTransactions={expensesAndTransactions}
                />
              )}
            {/*end col */}

            {/* Budget Goals */}

            {budgetGoals &&
              budgetGoals?.length > 0 &&
              budgetGoals[0]?.goal_name && (
                <NotAllowedOverlay message='You need to be a paid user to access this feature'>
                  <BudgetGoalsCard budgetGoals={budgetGoals} />
                </NotAllowedOverlay>
              )}

            {/* end col */}
          </div>
          {/* end row */}
          <div className='row'>{/*Add more cards here */}</div>
          {/* end row */}
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}

      {/* End Page-content */}
    </React.Fragment>
  );
};

export default Dashboard;
