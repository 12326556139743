import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Calendar } from 'rsuite';
import { Modal, Badge, Button } from 'react-bootstrap';
//import 'rsuite/Calendar/styles/index.css';

const ExpenseCompactCalendarView = ({ expensesAndTransactions }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalItems, setModalItems] = useState([]);
  const date = new Date();
  const month = date.toLocaleString('default', {
    month: 'long',
  });
  const year = date.getFullYear();
  function getTodoList(date) {
    const day = date.getDate();

    const todaysExpensesAndTransactions = expensesAndTransactions.filter(
      (item) => {
        return item.day_due_each_month === day;
      }
    );

    return todaysExpensesAndTransactions.map((item) => {
      const lastTransaction =
        item.transactions && item.transactions.length > 0
          ? item.transactions[item.transactions.length - 1]
          : undefined;

      return {
        expenseId: item.expense_id,
        dayDueEachMonth: `${item.day_due_each_month}`,
        expenseName: item.expense_name,
        expenseAmt: item.expense_amt,
        expenseDescription: item.expense_description,
        budgetCategoryId: lastTransaction
          ? lastTransaction.budget_category_selected_id
          : undefined,
      };
    });
  }

  function renderCell(date) {
    const list = getTodoList(date);
    if (list.length) {
      //console.log('List:', list);
      return (
        <Badge
          className='calendar-todo-item-badge'
          style={{ backgroundColor: 'red' }}
          onClick={() => {
            setModalItems(list);
            setShowModal(true);
          }}
        >
          <span>
            {list.length} Expense{list.length > 1 ? 's' : ''}
          </span>
        </Badge>
      );
    }

    return null;
  }
  return (
    <React.Fragment>
      <Modal
        key={showModal}
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Body>
          <div
            className='modal-header p-3 bg-info-subtle'
            style={{ padding: '0 !important' }}
          >
            <h5 className='modal-title' id='modal-title'>
              Expense{modalItems.length > 1 ? 's' : ''} Due on the{' '}
              {modalItems.length > 0 ? modalItems[0]?.dayDueEachMonth : ''}
            </h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-hidden='true'
              onClick={() => setShowModal(false)}
            ></button>
          </div>
        </Modal.Body>
        <Modal.Body>
          <div className='modal-content border-0'>
            <div className='modal-body p-4'>
              <form
                className='needs-validation view-event'
                name='event-form'
                id='form-event'
                noValidate=''
              >
                {modalItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <div key={index} className='card p-3 '>
                      <div className='title'>
                        <h5 className='text-start d-block fw-semibold mb-0'>
                          <b>{item.expenseName}</b>
                        </h5>
                        <div className='text-end'>
                          {item.budgetCategoryId ? (
                            <Link
                              to={`/budgetRelatedExpenses/${item.budgetCategoryId}`}
                              className='btn btn-sm btn-soft-primary'
                              id='edit-event-btn'
                              data-id='edit-event'
                              onClick='editEvent(this)'
                              role='button'
                            >
                              Detail
                            </Link>
                          ) : (
                            <span>Detail</span>
                          )}
                        </div>
                      </div>
                      <div className='event-details'>
                        <div className='d-flex mb-2'>
                          <div className='flex-grow-1 d-flex align-items-center'>
                            <div className='flex-shrink-0 me-3'>
                              <i className='ri-calendar-event-line text-muted fs-16'></i>
                            </div>
                            <div className='flex-grow-1'>
                              <h6
                                className='d-block fw-semibold mb-0'
                                id='event-start-date-tag'
                              >
                                {`${item.dayDueEachMonth} ${month} ${year}`}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex align-items-center mb-2'>
                          <div className='flex-shrink-0 me-3'>
                            <i className='ri-money-dollar-circle-line text-muted fs-16'></i>
                          </div>
                          <div className='flex-grow-1'>
                            <h6 className='d-block fw-semibold mb-0'>
                              <span id='event-timepicker1-tag'>
                                {'$'}
                                {parseFloat(Number(item.expenseAmt)).toFixed(2)}
                              </span>
                            </h6>
                          </div>
                        </div>

                        {item.expenseDescription && (
                          <div className='d-flex mb-3'>
                            <div className='flex-shrink-0 me-3'>
                              <i className='ri-discuss-line text-muted fs-16'></i>
                            </div>
                            <div className='flex-grow-1'>
                              <p
                                className='d-block text-muted mb-0'
                                id='event-description-tag'
                              >
                                {item.expenseDescription}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/*modalItems.length > 1 && (
            <Button onClick={() => setShowModal(false)}>Close</Button>
          )*/}
        </Modal.Footer>
      </Modal>
      <div className='card'>
        <Calendar compact bordered renderCell={renderCell} />
      </div>
    </React.Fragment>
  );
};

export default ExpenseCompactCalendarView;
