import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../../../reusable/form/Input';
import { toast } from 'react-toastify';
import config from '../../../../constants/config.json';
import Joi from 'joi-browser';
import {
  createPal,
  fetchPalByPalId,
} from '../../../../services/palz/palzManagement';
import { v4 as uuid } from 'uuid';
import shortid from 'shortid';
import { getCurrentUserProfileByEmail } from '../../../../services/authentication/authManagementService';
import PageTitleBox from '../../../reusable/portal/general/pageTitleBox';

const InvitePal = ({ isDarkMode }) => {
  const pageTitle = 'Invite Pal';
  const [ignored, setIgnored] = useReducer((x) => x + 1, 0);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const storedUserId = localStorage.getItem(config.user_id);

  //the requestior is the person logged in
  const [requestorProfile, setRequestorProfile] = useState({
    userId: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    profileImg: '',
  });

  const [palProfileData, setPalProfileData] = useState({
    access_right: {
      access_id: '',
      can_comment: true,
      can_edit_budget_goal: true,
      can_edit_expense: true,
      can_edit_income: true,
      can_link_their_budget: true,
      can_view_budget: true,
      can_view_budget_goal: true,
      can_view_expense: true,
      can_view_income: true,
      pal_id: '',
      record_time_stamp: 0,
    },
    date_link_created: '',
    email_address: '',
    expiration_date: 0,
    has_access: false,
    invite_code: '',
    pal_first_name: '',
    pal_id: '',
    pal_last_name: '',
    pal_profile_image: '',
    primary_pal_id: '',
    requestor_first_name: '',
    requestor_last_name: '',
    requestor_profile_image: '',
  });

  const [palProfileFormData, setPalProfileFormData] = useState({
    email_address: '',
    pal_first_name: '',
    pal_last_name: '',
    can_comment: true,
    can_edit_budget_goal: true,
    can_edit_expense: true,
    can_edit_income: true,
    can_link_their_budget: true,
    can_view_budget: true,
    can_view_budget_goal: true,
    can_view_expense: true,
    can_view_income: true,
  });

  const [errors, setErrors] = useState({});

  const [canComment, setCanComment] = useState(palProfileData.can_comment);
  const [canEditBudgetGoal, setCanEditBudgetGoal] = useState(
    palProfileData.can_edit_budget_goal
  );
  const [canEditExpense, setCanEditExpense] = useState(
    palProfileData.can_edit_expense
  );
  const [canEditIncome, setCanEditIncome] = useState(
    palProfileData.can_edit_income
  );
  const [canLinkTheirBudget, setCanLinkTheirBudget] = useState(
    palProfileData.can_link_their_budget
  );
  const [canViewBudget, setCanViewBudget] = useState(
    palProfileData.can_view_budget
  );
  const [canViewBudgetGoal, setCanViewBudgetGoal] = useState(
    palProfileData.can_view_budget_goal
  );
  const [canViewExpense, setCanViewExpense] = useState(
    palProfileData.can_view_expense
  );
  const [canViewIncome, setCanViewIncome] = useState(
    palProfileData.can_view_income
  );

  const schema = {
    email_address: Joi.string().email().required().label('Email Address'),
    pal_first_name: Joi.string().required().label('First Name'),
    pal_last_name: Joi.string().required().label('Last Name'),
    can_comment: Joi.boolean().required().label('Can Comment'),
    can_edit_budget_goal: Joi.boolean()
      .required()
      .label('Can Edit Budget Goal'),
    can_edit_expense: Joi.boolean().required().label('Can Edit Expense'),
    can_edit_income: Joi.boolean().required().label('Can Edit Income'),
    can_link_their_budget: Joi.boolean()
      .required()
      .label('Can Link Their Budget'),
    can_view_budget: Joi.boolean().required().label('Can View Budget'),
    can_view_budget_goal: Joi.boolean()
      .required()
      .label('Can View Budget Goal'),
    can_view_expense: Joi.boolean().required().label('Can View Expense'),
    can_view_income: Joi.boolean().required().label('Can View Income'),
  };

  const getRequestorProfile = async () => {
    const requestorProfile = await getCurrentUserProfileByEmail();
    if (requestorProfile) {
      //console.log('Requestor Profile:');
      //console.log(requestorProfile);
      setRequestorProfile(requestorProfile);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setInitialCheckboxValues();
    if (storedUserId) {
      getRequestorProfile();
    }
  }, []);

  const setInitialCheckboxValues = () => {
    setCanViewBudget(palProfileData.access_right.can_view_budget);
    setCanViewBudgetGoal(palProfileData.access_right.can_view_budget_goal);
    setCanViewExpense(palProfileData.access_right.can_view_expense);
    setCanViewIncome(palProfileData.access_right.can_view_income);
    setCanComment(palProfileData.access_right.can_comment);
    setCanEditBudgetGoal(palProfileData.access_right.can_edit_budget_goal);
    setCanEditExpense(palProfileData.access_right.can_edit_expense);
    setCanEditIncome(palProfileData.access_right.can_edit_income);
    setCanLinkTheirBudget(palProfileData.access_right.can_link_their_budget);
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(palProfileFormData, schema, options);

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaLocal);

    return error ? error.details[0].message : null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //get the values from the form
    // var emailAddress = this.emailAddress.current.value;
    // var password = document.getElementById('txtPassword').value;
    //call the server to save the changes

    const errors = validate();

    for (const error in errors) {
      if (errors.hasOwnProperty(error)) {
        toast.error(errors[error]);
      }
    }
    setErrors({ errors: errors || {} });
    if (errors) return;

    doSubmit();
  };

  const handleChange = (input) => {
    if (input && input.currentTarget) {
      const errorMessage = validateProperty(input.currentTarget);

      const newErrors = { ...errors };
      if (errorMessage) newErrors[input.currentTarget.name] = errorMessage;
      else delete newErrors[input.currentTarget.name];

      let newPalProfileFormData;

      if (
        input.currentTarget.name === 'can_view_budget' ||
        input.currentTarget.name === 'can_view_budget_goal' ||
        input.currentTarget.name === 'can_view_expense' ||
        input.currentTarget.name === 'can_view_income' ||
        input.currentTarget.name === 'can_comment' ||
        input.currentTarget.name === 'can_edit_budget_goal' ||
        input.currentTarget.name === 'can_edit_expense' ||
        input.currentTarget.name === 'can_edit_income' ||
        input.currentTarget.name === 'can_link_their_budget'
      ) {
        newPalProfileFormData = {
          ...palProfileFormData,
          [input.currentTarget.name]: input.currentTarget.checked,
        };

        if (input.currentTarget.name === 'can_view_budget') {
          setCanViewBudget(input.currentTarget.checked);
        }
        if (input.currentTarget.name === 'can_view_budget_goal') {
          setCanViewBudgetGoal(input.currentTarget.checked);
        }
        if (input.currentTarget.name === 'can_view_expense') {
          setCanViewExpense(input.currentTarget.checked);
        }
        if (input.currentTarget.name === 'can_view_income') {
          setCanViewIncome(input.currentTarget.checked);
        }
        if (input.currentTarget.name === 'can_comment') {
          setCanComment(input.currentTarget.checked);
        }
        if (input.currentTarget.name === 'can_edit_budget_goal') {
          setCanEditBudgetGoal(input.currentTarget.checked);
        }
        if (input.currentTarget.name === 'can_edit_expense') {
          setCanEditExpense(input.currentTarget.checked);
        }
        if (input.currentTarget.name === 'can_edit_income') {
          setCanEditIncome(input.currentTarget.checked);
        }
        if (input.currentTarget.name === 'can_link_their_budget') {
          setCanLinkTheirBudget(input.currentTarget.checked);
        }
      } else {
        newPalProfileFormData = {
          ...palProfileFormData,
          [input.currentTarget.name]: input.currentTarget.value,
        };
      }

      setErrors(newErrors);
      setPalProfileFormData(newPalProfileFormData);
    }
  };

  function generateInviteCode(firstName, lastName, requestorName) {
    const uniqueId = shortid.generate().substring(0, 4).toUpperCase(); // Take first 2 characters of uniqueId
    const timestamp = new Date().getTime().toString().substring(0, 4); // Take first 4 characters of timestamp
    const requestorInitial = requestorName.charAt(0).toUpperCase(); // Take first character of requestor's name
    const inviteCode = `${firstName.substring(0, 2).toUpperCase()}${lastName
      .substring(0, 2)
      .toUpperCase()}${uniqueId}${timestamp}${requestorInitial}`;

    return inviteCode;
  }

  /*TODO:
Refactor code to add shortid library for generating unique IDs, also added the send invite form, just need to write email template so the invite an be sent 
to the user with the code from the API so they can be sent back to the registration and if it finds the invite code it will use it to pre-populate the registration form with the info it knows 
then the user can fill in the rest of the info and submit the form to create the account
store that invite code in application storage so when they go to main screen it can present them with a modal for them to accept the invite or decline it and the special discount for 
the first month of the subscription
  */

  const doSubmit = async () => {
    //setIsLoading(true);

    //the person logged in is the requestor

    const palId = uuid();
    //date string
    const dateLinkCreated = new Date().toISOString();
    const hasAccess = false;
    const accessId = uuid();
    const inviteCode = generateInviteCode(
      palProfileFormData.pal_first_name,
      palProfileFormData.pal_last_name,
      requestorProfile.firstName
    );

    const pal = {
      access_right: {
        access_id: accessId,
        can_comment: canComment,
        can_edit_budget_goal: canEditBudgetGoal,
        can_edit_expense: canEditExpense,
        can_edit_income: canEditIncome,
        can_link_their_budget: canLinkTheirBudget,
        can_view_budget: canViewBudget,
        can_view_budget_goal: canViewBudgetGoal,
        can_view_expense: canViewExpense,
        can_view_income: canViewIncome,
        pal_id: palId,
        record_time_stamp: dateLinkCreated,
      },
      date_link_created: dateLinkCreated,
      email_address: palProfileFormData.email_address,
      expiration_date: 0,
      has_access: hasAccess,
      invite_code: inviteCode,
      pal_first_name: palProfileFormData.pal_first_name,
      pal_id: palId,
      pal_last_name: palProfileFormData.pal_last_name,
      pal_profile_image: '',
      primary_pal_id: requestorProfile.userId, //the person logged in
      requestor_first_name: requestorProfile.firstName,
      requestor_last_name: requestorProfile.lastName,
      requestor_profile_image: requestorProfile.profileImg,
      secondary_pal_accepted_request: false,
      secondary_has_responded: false,
      secondary_pal_id: '', //not set yet
    };

    //console.log('Pal Request:');
    //console.log(pal);

    const response = await createPal(pal);
    if (response) {
      toast.success('Pal created successfully');

      //re-enable later
      navigate('/approvedPalz');
    } else {
      toast.error('Pal creation failed');
    }

    //setIsLoading(false);
  };

  return (
    <React.Fragment>
      <div data-bs-theme={isDarkMode ? 'dark' : 'light'}>
        <div className='container-fluid'>
          <PageTitleBox pageTitle={pageTitle} />
          <div className='row'>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-header align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    Pal Invitation
                  </h4>
                  <div className='flex-shrink-0'></div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='row gy-4 mb-3 px-4'>
                    <div className='col-xxl-3 col-md-6'>
                      <Input
                        name='pal_first_name'
                        label='First Name'
                        value={palProfileFormData.pal_first_name}
                        onChange={handleChange}
                        error={errors.pal_first_name}
                        type='text'
                      />
                    </div>
                    <div className='col-xxl-3 col-md-6'>
                      <Input
                        name='pal_last_name'
                        label='Last Name'
                        value={palProfileFormData.pal_last_name}
                        onChange={handleChange}
                        error={errors.pal_last_name}
                        type='text'
                      />
                    </div>
                    <div className='col-xxl-3 col-md-6'>
                      <Input
                        name='email_address'
                        label='Email Address'
                        value={palProfileFormData.email_address}
                        onChange={handleChange}
                        error={errors.email_address}
                        type='email'
                      />
                    </div>
                  </div>

                  <div className='row gy-4 px-4'>
                    <div className='col-xxl-3 col-md-6'>
                      <div>
                        <label
                          htmlFor='can_view_budget'
                          style={{ display: 'block' }}
                        >
                          Can View Budget
                        </label>
                        <div className='form-check form-switch form-switch-right form-switch-md'>
                          <label
                            htmlFor='can_view_budget'
                            className='form-label text-muted'
                          >
                            {palProfileFormData.can_view_budget ? 'Yes' : 'No'}
                          </label>
                          <input
                            className='form-check-input code-switcher'
                            type='checkbox'
                            id='can_view_budget'
                            name='can_view_budget'
                            checked={palProfileFormData.can_view_budget}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-xxl-3 col-md-6'>
                      <div>
                        <label
                          htmlFor='can_view_budget_goal'
                          style={{ display: 'block' }}
                        >
                          Can View Budget Goal
                        </label>
                        <div className='form-check form-switch form-switch-right form-switch-md'>
                          <label
                            htmlFor='can_view_budget_goal'
                            className='form-label text-muted'
                          >
                            {palProfileFormData.can_view_budget_goal
                              ? 'Yes'
                              : 'No'}
                          </label>
                          <input
                            className='form-check-input code-switcher'
                            type='checkbox'
                            id='can_view_budget_goal'
                            name='can_view_budget_goal'
                            checked={palProfileFormData.can_view_budget_goal}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='col-xxl-3 col-md-6'>
                      <div>
                        <label
                          htmlFor='can_view_expense'
                          style={{ display: 'block' }}
                        >
                          Can View Expense
                        </label>
                        <div className='form-check form-switch form-switch-right form-switch-md'>
                          <label
                            htmlFor='can_view_expense'
                            className='form-label text-muted'
                          >
                            {palProfileFormData.can_view_expense ? 'Yes' : 'No'}
                          </label>
                          <input
                            className='form-check-input code-switcher'
                            type='checkbox'
                            id='can_view_expense'
                            name='can_view_expense'
                            checked={palProfileFormData.can_view_expense}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='col-xxl-3 col-md-6'>
                      <div>
                        <label
                          htmlFor='can_view_income'
                          style={{ display: 'block' }}
                        >
                          Can View Income
                        </label>
                        <div className='form-check form-switch form-switch-right form-switch-md'>
                          <label
                            htmlFor='can_view_income'
                            className='form-label text-muted'
                          >
                            {palProfileFormData.can_view_income ? 'Yes' : 'No'}
                          </label>
                          <input
                            className='form-check-input code-switcher'
                            type='checkbox'
                            id='can_view_income'
                            name='can_view_income'
                            checked={palProfileFormData.can_view_income}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='col-xxl-3 col-md-6'>
                      <div>
                        <label
                          htmlFor='can_comment'
                          style={{ display: 'block' }}
                        >
                          Can Comment
                        </label>
                        <div className='form-check form-switch form-switch-right form-switch-md'>
                          <label
                            htmlFor='can_comment'
                            className='form-label text-muted'
                          >
                            {palProfileFormData.can_comment ? 'Yes' : 'No'}
                          </label>
                          <input
                            className='form-check-input code-switcher'
                            type='checkbox'
                            id='can_comment'
                            name='can_comment'
                            checked={palProfileFormData.can_comment}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='col-xxl-3 col-md-6'>
                      <div>
                        <label
                          htmlFor='can_edit_budget_goal'
                          style={{ display: 'block' }}
                        >
                          Can Edit Budget Goal
                        </label>
                        <div className='form-check form-switch form-switch-right form-switch-md'>
                          <label
                            htmlFor='can_edit_budget_goal'
                            className='form-label text-muted'
                          >
                            {palProfileFormData.can_edit_budget_goal
                              ? 'Yes'
                              : 'No'}
                          </label>
                          <input
                            className='form-check-input code-switcher'
                            type='checkbox'
                            id='can_edit_budget_goal'
                            name='can_edit_budget_goal'
                            checked={palProfileFormData.can_edit_budget_goal}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='col-xxl-3 col-md-6'>
                      <div>
                        <label
                          htmlFor='can_edit_expense'
                          style={{ display: 'block' }}
                        >
                          Can Edit Expense
                        </label>
                        <div className='form-check form-switch form-switch-right form-switch-md'>
                          <label
                            htmlFor='can_edit_expense'
                            className='form-label text-muted'
                          >
                            {palProfileFormData.can_edit_expense ? 'Yes' : 'No'}
                          </label>
                          <input
                            className='form-check-input code-switcher'
                            type='checkbox'
                            id='can_edit_expense'
                            name='can_edit_expense'
                            checked={palProfileFormData.can_edit_expense}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='col-xxl-3 col-md-6'>
                      <div>
                        <label
                          htmlFor='can_edit_income'
                          style={{ display: 'block' }}
                        >
                          Can Edit Income
                        </label>
                        <div className='form-check form-switch form-switch-right form-switch-md'>
                          <label
                            htmlFor='can_edit_income'
                            className='form-label text-muted'
                          >
                            {palProfileFormData.can_edit_income ? 'Yes' : 'No'}
                          </label>
                          <input
                            className='form-check-input code-switcher'
                            type='checkbox'
                            id='can_edit_income'
                            name='can_edit_income'
                            checked={palProfileFormData.can_edit_income}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='col-xxl-3 col-md-6'>
                      <div>
                        <label
                          htmlFor='can_link_their_budget'
                          style={{ display: 'block' }}
                        >
                          Can Link Their Budget
                        </label>
                        <div className='form-check form-switch form-switch-right form-switch-md'>
                          <label
                            htmlFor='can_link_their_budget'
                            className='form-label text-muted'
                          >
                            {palProfileFormData.can_link_their_budget
                              ? 'Yes'
                              : 'No'}
                          </label>
                          <input
                            className='form-check-input code-switcher'
                            type='checkbox'
                            id='can_link_their_budget'
                            name='can_link_their_budget'
                            checked={palProfileFormData.can_link_their_budget}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='card-footer'>
                      <button disabled={validate()} className='btn btn-success'>
                        Send Invite
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InvitePal;
