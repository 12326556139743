import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';

const ExpenseBreakdownCard = ({
  expenseChartData,
  isDarkMode,
  lottieAnimation,
}) => {
  return (
    <>
      <div className='card card-height-100'>
        <div className='card-header align-items-center d-flex'>
          <h4 className='card-title mb-0 flex-grow-1'>Expense Breakdown</h4>
        </div>
        {/* end card header */}
        <div className='card-body'>
          <div style={{ width: '100%', height: '100%' }}>
            {expenseChartData &&
            expenseChartData.labels.length > 0 &&
            expenseChartData.datasets.some(
              (dataset) => dataset.data.length > 0
            ) ? (
              <Doughnut
                data={expenseChartData}
                options={{
                  maintainAspectRatio: true,
                  responsive: true,
                  plugins: {
                    legend: {
                      display: true,
                      labels: {
                        color: isDarkMode ? 'white' : '#6c757d',
                      },
                    },
                  },
                }}
              />
            ) : (
              <div className='row'>
                <Lottie options={lottieAnimation} height={400} width={400} />

                <div className='text-center'>
                  <h3>No Expenses Found for the current Period.</h3>
                  <span>An active budget is required.</span>
                </div>
                <div className='mt-3 text-center'>
                  <Link to={'/createNewExpense'} className='btn btn-success'>
                    Create new Expense
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* end card body */}
      </div>
      {/* end card */}
    </>
  );
};

export default ExpenseBreakdownCard;
