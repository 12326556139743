import http from "../general/httpService";
import api from "../../constants/config.json";
import config from "../../constants/config.json";



///create-checkout-session
export async function createCheckoutSession(lookupKey) {
    const fullApiEndpoint = api.apiEndpoint + `/create-checkout-session`;
    const {data : sessionId} = await http.post(fullApiEndpoint, {lookup_key: lookupKey});
    return sessionId;
}

///create-portal-session
export async function createPortalSession(sessionId) {
    const fullApiEndpoint = api.apiEndpoint + `/create-portal-session`;
    const {data : portalSession} = await http.post(fullApiEndpoint, {session_id: sessionId});
    return portalSession;
}

///TODO: webhook to be set up in stripe


///get_last_payment_status and is a post and takes a stripe_customer_id
export async function getLastPaymentStatus(stripeCustomerId) {
    const customerStripeId = localStorage.getItem(config.stripe_customer_id);
    const fullApiEndpoint = api.apiEndpoint + `/get_last_payment_status`;
    const {data : paymentStatus} = await http.post(fullApiEndpoint, {stripe_customer_id: customerStripeId});
    return paymentStatus;
}


///create-customer-session
export async function createCustomerSession() {
    const customerStripeId = localStorage.getItem(config.stripe_customer_id);
    const fullApiEndpoint = api.apiEndpoint + `/create-customer-session`;
    const {data : customerSession} = await http.post(fullApiEndpoint, {stripe_customer_id: customerStripeId});
    return customerSession;
}

///get_all_subscription_history
export async function getAllSubscriptionHistory() {
    const customerStripeId = localStorage.getItem(config.stripe_customer_id);
    const fullApiEndpoint = api.apiEndpoint + `/get_all_subscription_history`;
    const {data : subscriptionHistory} = await http.post(fullApiEndpoint, {stripe_customer_id: customerStripeId});
    return subscriptionHistory;
}

///get_active_subscriptions_from_stripe
export async function getActiveSubscriptionsFromStripe() {
    const customerStripeId = localStorage.getItem(config.stripe_customer_id);
    const fullApiEndpoint = api.apiEndpoint + `/get_active_subscriptions_from_stripe`;
    const {data : activeSubscriptions} = await http.post(fullApiEndpoint, {stripe_customer_id: customerStripeId});
    return activeSubscriptions;
}

//pause subscription

//cancel subscription
///cancel_subscription
export async function cancelSubscription(subscription_id) {
    
    const fullApiEndpoint = api.apiEndpoint + `/cancel_subscription`;
    const {data : subscriptionStatus} = await http.post(fullApiEndpoint, {subscription_id: subscription_id});
    return subscriptionStatus;
}