import React, { useState } from 'react';
import { getIconUrl } from '../../../../constants/iconUrlMap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';

const RecentExpensesCard = ({ expensesAndTransactions }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('Expense Name');

  const filteredExpenses = expensesAndTransactions
    .filter((expense) =>
      expense.expense_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      switch (sortOption) {
        case 'Expense Name':
          return a.expense_name.localeCompare(b.expense_name);
        case 'Amount':
          const aAmount =
            a.transactions.length > 0
              ? a.transactions[a.transactions.length - 1].amount
              : a.expense_amt;
          const bAmount =
            b.transactions.length > 0
              ? b.transactions[b.transactions.length - 1].amount
              : b.expense_amt;
          return aAmount - bAmount;
        case 'Date':
          const aDate =
            a.transactions.length > 0
              ? new Date(a.transactions[a.transactions.length - 1].date)
              : new Date();
          const bDate =
            b.transactions.length > 0
              ? new Date(b.transactions[b.transactions.length - 1].date)
              : new Date();
          return aDate - bDate;
        default:
          return 0;
      }
    });

  return (
    <div className='col-xl-3'>
      <div className='card card-height-100'>
        <div className='card-header align-items-center d-flex'>
          <h4 className='card-title mb-0 flex-grow-1'>Recent Expenses</h4>

          <div className='flex-shrink-0'>
            <NavDropdown title={`Sort by: ${sortOption}`} id='nav-dropdown'>
              <NavDropdown.Item onClick={() => setSortOption('Expense Name')}>
                Expense Name
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => setSortOption('Amount')}>
                Amount
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => setSortOption('Date')}>
                Date
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>
        {/*end card header */}

        <div className='card-body'>
          <p className='text-muted'>Filter Expenses by Name</p>
          <div id='contact-existing-list'>
            <div className='row mb-2'>
              <div className='col'>
                <div>
                  <input
                    className='search form-control'
                    placeholder='Search'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div data-simplebar styles='height: 242px;' className='mx-n3'>
              <ul className='list list-group list-group-flush mb-0'>
                {filteredExpenses.map((expense, index) => (
                  <li key={index} className='list-group-item' data-id='01'>
                    <div className='d-flex align-items-start'>
                      <div className='flex-shrink-0 me-3'>
                        <div>
                          <img
                            className='image avatar-xs rounded-circle'
                            alt=''
                            src={getIconUrl(expense.expense_icon).url}
                          />
                        </div>
                      </div>

                      <div className='flex-grow-1 overflow-hidden'>
                        <h5 className='contact-name fs-13 mb-1'>
                          <Link
                            to={`/budgetRelatedExpenses/${
                              expense.transactions &&
                              expense.transactions.length > 0
                                ? expense.transactions[
                                    expense.transactions.length - 1
                                  ].budget_category_selected_id
                                : ''
                            }`}
                            className='link text-body'
                          >
                            {expense.expense_name}
                          </Link>
                        </h5>
                        <p className='contact-born text-muted mb-0'>
                          {expense.transactions.length > 0
                            ? expense.transactions[
                                expense.transactions.length - 1
                              ].history_description
                            : ''}
                        </p>
                      </div>

                      <div className='flex-shrink-0 ms-2'>
                        <div
                          className='fs-5 text'
                          style={{ color: 'red', fontWeight: 'bold' }}
                        >
                          {'-$'}{' '}
                          {expense.transactions.length > 0
                            ? Number(
                                expense.transactions[
                                  expense.transactions.length - 1
                                ].amount
                              ).toFixed(2)
                            : Number(expense.expense_amt)?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentExpensesCard;
