import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';

import {
  predefinedColors,
  dynamicallyGeneratedColor,
} from '../../../../../services/helpers/colorService';

Chart.register(CategoryScale, LinearScale, Title, Tooltip, Legend, BarElement);

const APPLE_EPOCH_UNIX_TIMESTAMP = 978307200; // Number of seconds between 1970 and 2001

const GoalFundsBarChart = ({ funds, isDarkMode }) => {
  // Calculate total funds
  const totalFunds = funds.reduce(
    (total, fund) => total + fund.fund_amt_added,
    0
  );

  // Prepare the data
  const data = {
    labels: funds.map((fund) => {
      const unixTimestamp = fund.fund_date + APPLE_EPOCH_UNIX_TIMESTAMP;
      return new Date(fund.fund_date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    }),
    datasets: [
      {
        label: `Total Funds: $${totalFunds}`,
        data: funds.map((fund) => fund.fund_amt_added),
        backgroundColor: funds.map((fund) =>
          dynamicallyGeneratedColor(
            fund.fund_source_name || 'default',
            isDarkMode
          ).replace('0.2', '1')
        ),
        borderColor: funds.map((fund) =>
          dynamicallyGeneratedColor(
            fund.fund_source_name || 'default',
            isDarkMode
          ).replace('0.2', '1')
        ),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: isDarkMode ? 'white' : '#6c757d',
        },
      },
      title: {
        display: true,
        text: 'Funds Breakdown',
        color: isDarkMode ? 'white' : '#6c757d',
      },
    },
    scales: {
      x: {
        ticks: {
          color: isDarkMode ? 'white' : '#6c757d',
        },
      },
      y: {
        type: 'linear',
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <div className='card card-height-100'>
        <div className='card-header align-items-center d-flex'>
          <h4 className='card-title mb-0 flex-grow-1'>Funds Breakdown</h4>
        </div>
        {/* end card header */}
        <div className='card-body'>
          <div style={{ width: '100%', height: '100%' }}>
            <Bar data={data} options={options} />
          </div>
        </div>
        {/* end card body */}
      </div>
      {/* end card */}
    </>
  );
};

export default GoalFundsBarChart;
