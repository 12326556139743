//constant file to store the icon url map


export const getIconUrl = (iconName) => {

  let iconData = iconUrlMap[iconName];

  if (!iconData) {
    // Search by val if key not found
    for (let key in iconUrlMap) {
      if (iconUrlMap[key].val === iconName) {
        iconData = iconUrlMap[key];
        break;
      }
      else if (iconUrlMap[key].key === iconName) {
        iconData = iconUrlMap[key];
        break;
      }
    }
  }

  return iconData || { key: "WalletMoneyNote", val: "wallet-money-note-cash", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fwallet-money-note-cash.png?alt=media&token=3222c5ab-a902-4016-8515-a4c5364ce38a" };
};

 export const iconUrlMap = {
    "BookReading": { key: "BookReading", val: "book-reading-study-education", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fbook-reading-study-education.png?alt=media&token=42ed87ac-6bc1-4ef6-9c82-99e65ae6693f" },
    "BudgetExpense": { key: "BudgetExpense", val: "budget-expense-money-account", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fbudget-expense-money-account.png?alt=media&token=0327cdd3-4cdb-4ff7-82cb-2496a446d102" },
    "CalculateGraphReport": { key: "CalculateGraphReport", val: "calculate-graph-report-analysis", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fcalculate-graph-report-analysis.png?alt=media&token=42b504d1-50ef-47ee-bc59-c73cc2c35e57" },
    "ClothShirtFashion": { key: "ClothShirtFashion", val: "cloth-shirt-fashion-wear", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fcloth-shirt-fashion-wear.png?alt=media&token=b14ceaf7-641a-4f45-8f31-dae4d721fa40" },
    "CoffeeTakeaway": { key: "CoffeeTakeaway", val: "coffee-takeaway-cup-hot", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fcoffee-takeaway-cup-hot.png?alt=media&token=102110f1-4511-4104-959b-6943b3c2901e" },
    "CreditCardPayment": { key: "CreditCardPayment", val: "credit-card-payment-pay", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fcredit-card-payment-pay.png?alt=media&token=bd4bd490-c5f3-40f2-9091-00e77ea4bc93" },
    "DrinkWineBeverage": { key: "DrinkWineBeverage", val: "drink-wine-beverage-alcohol", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fdrink-wine-beverage-alcohol.png?alt=media&token=51242594-75cb-4bca-bf8c-5418b9739256" },
    "ElectricityPowerTransmission": { key: "ElectricityPowerTransmission", val: "electricity-power-transmission-plant", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Felectricity-power-transmission-plant.png?alt=media&token=a6f87cc8-2393-4caa-912f-87f8bb3779e9" },
    "EntertainmentMovieMusic": { key: "EntertainmentMovieMusic", val: "entertainment-movie-music-concert", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fentertainment-movie-music-concert.png?alt=media&token=439e2c4d-b53f-4072-ac7c-c71947cd6868" },
    "FastFoodRestaurant": { key: "FastFoodRestaurant", val: "fast-food-restaurant-meal", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Ffast-food-restaurant-meal.png?alt=media&token=f0b3757c-bf39-4498-a5cf-a2fe3478ea8d" },
    "FinancialCalculator": { key: "FinancialCalculator", val: "financial-calculator-accounting-bill", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Ffinancial-calculator-accounting-bill.png?alt=media&token=12031cca-caba-4470-a966-d47aa6283bc3" },
    "FoodSteakMeal": { key: "FoodSteakMeal", val: "food-steak-meal-meat", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Ffood-steak-meal-meat.png?alt=media&token=48aab50e-446a-4f9e-9241-ae915f8332af" },
    "FruitBananaApple": { key: "FruitBananaApple", val: "fruit-banana-apple-healthy", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Ffruit-banana-apple-healthy.png?alt=media&token=b8eb84a7-ba1a-44ae-8a4b-46af49c935d8" },
    "GasFuelPetrol": { key: "GasFuelPetrol", val: "gas-fuel-petrol-gasoline", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fgas-fuel-petrol-gasoline.png?alt=media&token=0c266279-3a4e-4bf6-bc75-6ddcf9879f3e" },
    "GiftPresentChristmas": { key: "GiftPresentChristmas", val: "gift-present-christmas-box", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fgift-present-christmas-box.png?alt=media&token=53b002e6-5f35-4498-88a4-d9e101e8b28b" },
    "GroceriesFoodVegetable": { key: "GroceriesFoodVegetable", val: "groceries-food-vegetable-shop", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fgroceries-food-vegetable-shop.png?alt=media&token=3caf6a04-2d29-48f3-bfa0-bc491f4cab75" },
    "GymRunningMachine": { key: "GymRunningMachine", val: "gym-running-machine-exercise", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fgym-running-machine-exercise.png?alt=media&token=10d59c96-d571-4c0f-8086-c87ef28a268b" },
    "HobbyPaintingArt": { key: "HobbyPaintingArt", val: "hobby-painting-art-museum", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fhobby-painting-art-museum.png?alt=media&token=895357e1-cdc9-4a1c-9eb7-2e4841701b25" },
    "IncomeMoneyEarn": { key: "IncomeMoneyEarn", val: "income-money-earn-loan", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fincome-money-earn-loan.png?alt=media&token=d47c51dd-7b74-4f65-abb9-cb3ffc3081f3" },
    "InsuranceShieldDocuments": { key: "InsuranceShieldDocuments", val: "insurance-shield-documents-health", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Finsurance-shield-documents-health.png?alt=media&token=2a371449-c7bf-4383-a473-f833f7198b34" },
    "InvestmentsMoneyGrowth": { key: "InvestmentsMoneyGrowth", val: "Investments-money-growth-fund", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2FInvestments-money-growth-fund.png?alt=media&token=d2594ed5-9efe-4623-abd6-bd7e929d6914" },
    "LaundryWashingMachine": { key: "LaundryWashingMachine", val: "laundry-washing-machine-clothes", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Flaundry-washing-machine-clothes.png?alt=media&token=a1b7d007-38ef-491a-8e15-735588d3da86" },
    "MagazineBookFashion": { key: "MagazineBookFashion", val: "magazine-book-fashion-monthly", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fmagazine-book-fashion-monthly.png?alt=media&token=1fa597e1-4b55-496b-aa09-48dc946c42ab" },
    "MedicalMedicinePill": { key: "MedicalMedicinePill", val: "medical-medicine-pill-drug", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fmedical-medicine-pill-drug.png?alt=media&token=17f49c60-b42f-466f-878d-0db1d9bfdc42" },
    "MortgageRentalProperty": { key: "MortgageRentalProperty", val: "mortgage-rental-property-bank", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fmortgage-rental-property-bank.png?alt=media&token=331195fa-02f1-401c-b166-9fac16a435a2" },
    "MovieHobbyCinema": { key: "MovieHobbyCinema", val: "movie-hobby-cinema-entertainment", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fmovie-hobby-cinema-entertainment.png?alt=media&token=45fc08d3-8e9a-4707-b4ce-b3f3b587d347" },
    "MusicHeadphoneSong": { key: "MusicHeadphoneSong", val: "music-headphone-song-headset", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fmusic-headphone-song-headset.png?alt=media&token=936cbe5f-96ec-4763-acb9-3b258b20edd8" },
    "ParkingParkCar": { key: "ParkingParkCar", val: "parking-park-car-sign", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fparking-park-car-sign.png?alt=media&token=2106c68c-51af-46cf-9b92-45452ea0781e" },
    "PublicTransportationBus": { key: "PublicTransportationBus", val: "public-transportation-bus-transport", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fpublic-transportation-bus-transport.png?alt=media&token=7ee5bbb4-be80-4054-833c-f4240f48cd63" },
    "SalonBeautyHaircut": { key: "SalonBeautyHaircut", val: "salon-beauty-hair-cut", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fsalon-beauty-hair-cut.png?alt=media&token=1156a40b-97ed-42f2-9f31-9a72c020e8c8" },
    "ShoppingCartTrolley": { key: "ShoppingCartTrolley", val: "shopping-cart-trolley-buy", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fshopping-cart-trolley-buy.png?alt=media&token=f92bfbe7-fc55-4cb6-9b6c-9c2d4e79cbfd" },
    "SnackFoodPotatoChips": { key: "SnackFoodPotatoChips", val: "snack-food-potato-chips", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fsnack-food-potato-chips.png?alt=media&token=32c54654-5f1c-4ef3-9211-ced61ab3d47c" },
    "SpaMassageRelax": { key: "SpaMassageRelax", val: "spa-massage-relax-therapy", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fspa-massage-relax-therapy.png?alt=media&token=77085848-621b-4ec6-b5aa-f5ae5999d6da" },
    "ToyWoodenKit": { key: "ToyWoodenKit", val: "toy-wooden-kid-kit", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Ftoy-wooden-kid-kit.png?alt=media&token=be6963b7-4498-439b-8dcc-beaa795e0a29" },
    "TravelAirplaneFlight": { key: "TravelAirplaneFlight", val: "travel-airplane-fly-flight", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Ftravel-airplane-fly-flight.png?alt=media&token=d9c5bbc4-8b8c-4408-a897-dda51872e107" },
    "WalletMoneyNote": { key: "WalletMoneyNote", val: "wallet-money-note-cash", url: "https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Project%20Icons%2Fwallet-money-note-cash.png?alt=media&token=3222c5ab-a902-4016-8515-a4c5364ce38a" }
  };
