import React from 'react';

import ExpenseBreakdownCard from '../../../../../reusable/cards/dashboard/expenseBreakdownCard';
import ExpenseGrid from '../../../../../reusable/cards/expense/expenseGrid';
import ExpensesByCategoryChart from '../expensesByCategoryChart';
import BasicStatCard from '../basicStatCard';

const PalzExpenseSection = ({
  lottieAnimation,
  expenseChartData,
  isDarkMode,
  expensesByCategory,
  totalExpenses,
  totalTransactions,
  recurringExpenses,
  nonRecurringExpenses,
  expensesByRequestor,
  expensesByPal,
  combinedUserExpensesAndTransactions,
}) => {
  return (
    <div>
      <div className='row'>
        <div className='col-xl-3'>
          <ExpenseBreakdownCard
            lottieAnimation={lottieAnimation}
            expenseChartData={expenseChartData}
            isDarkMode={isDarkMode}
          />
        </div>
        <div className='col-xl-3'>
          <ExpensesByCategoryChart expensesByCategory={expensesByCategory} />
        </div>
        <div className='col-xl-3'>
          <div className='row'>
            <div className='col-md-6'>
              <BasicStatCard
                title={'Total Expenses'}
                percentage={0}
                total={totalExpenses}
                icon={'bx bx-wallet text-primary'}
              />
            </div>
            <div className='col-md-6'>
              <BasicStatCard
                title={'Total Transactions'}
                percentage={0}
                total={totalTransactions}
                icon={'bx bx-transfer text-primary'}
                isMonetary={false}
              />
            </div>
            <div className='col-md-6'>
              <BasicStatCard
                title={'Recurring Expenses'}
                percentage={0}
                total={recurringExpenses}
                icon={'bx bx-receipt text-primary'}
              />
            </div>
            <div className='col-md-6'>
              <BasicStatCard
                title={'Non-Recurring Expenses'}
                percentage={0}
                total={nonRecurringExpenses}
                icon={'bx bx-receipt text-primary'}
              />
            </div>
            <div className='col-md-6'>
              <BasicStatCard
                title={'Expenses By Requestor'}
                percentage={0}
                total={expensesByRequestor}
                icon={'bx bx-user text-primary'}
              />
            </div>
            <div className='col-md-6'>
              <BasicStatCard
                title={'Expenses By Pal'}
                percentage={0}
                total={expensesByPal}
                icon={'bx bx-user text-primary'}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        {combinedUserExpensesAndTransactions &&
          combinedUserExpensesAndTransactions?.length > 0 &&
          combinedUserExpensesAndTransactions[0]?.expense_name && (
            <ExpenseGrid expenses={combinedUserExpensesAndTransactions} />
          )}
      </div>
    </div>
  );
};

export default PalzExpenseSection;
