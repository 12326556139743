import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Input from '../../../reusable/form/Input';
import { toast } from 'react-toastify';
import Joi from 'joi-browser';
import { iconUrlMap } from '../../../../constants/iconUrlMap';
import PageTitleBox from '../../../reusable/portal/general/pageTitleBox';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import 'react-datepicker/dist/react-datepicker.css';
import { budgetGoalCategories } from '../../../../constants/budgetGoal/budgetGoalCategories';
import config from '../../../../constants/config.json';
import {
  updateBudgetGoal,
  fetchBudgetGoalById,
} from '../../../../services/budgetGoal/budgetGoalManagement';
import LoaderView from '../../../reusable/loader/loaderView';

//works just like createBudgetGoal except it updates the budget goal
const EditBudgetGoal = ({ isDarkMode }) => {
  const pageTitle = 'Edit Budget Goal';
  const { budgetGoalId } = useParams();
  const navigate = useNavigate();
  const [budgetGoalFormData, setBudgetGoalFormData] = useState({
    goal_date: new Date(), //not stored in the database
    ai_insight_msg: '',
    bank_account_id: '',
    budget_goal_id: '',
    budget_goal_sum_total: 0,
    current_amt_saved: 0,
    goal_category_icon: '',
    goal_creator_profile: {},
    goal_name: '',
    goal_type: '',
    has_achieved_goal: false,
    is_deleted: false,
    month: 0,
    pals_working_together_towards_goal: [],
    record_time_stamp: 0,
    target_end_date_raw_date: 0,
    total_left: 0,
    user_id: '',
    year: 0,
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const schema = {
    goal_date: Joi.date().optional().allow('').label('Goal Date'),
    ai_insight_msg: Joi.string()
      .optional()
      .allow('')
      .label('AI Insight Message'),
    bank_account_id: Joi.string().optional().allow('').label('Bank Account ID'),
    budget_goal_id: Joi.string().optional().allow('').label('Budget Goal ID'),
    budget_goal_sum_total: Joi.number()
      .required()
      .label('Budget Goal Sum Total'),
    current_amt_saved: Joi.number().required().label('Current Amount Saved'),
    goal_category_icon: Joi.string()
      .optional()
      .allow('')
      .label('Goal Category Icon'),
    goal_creator_profile: Joi.object()
      .optional()
      .allow('')
      .label('Goal Creator Profile'),
    goal_name: Joi.string().required().label('Goal Name'),
    goal_type: Joi.string().optional().allow('').label('Goal Type'),
    has_achieved_goal: Joi.boolean()
      .optional()
      .allow('')
      .label('Has Achieved Goal'),
    is_deleted: Joi.boolean().optional().allow('').label('Is Deleted'),
    month: Joi.number().optional().label('Month'),
    pals_working_together_towards_goal: Joi.array()
      .optional()
      .allow('')
      .label('Pals Working Together Towards Goal'),
    record_time_stamp: Joi.number()
      .optional()
      .allow('')
      .label('Record Time Stamp'),
    target_end_date_raw_date: Joi.number()
      .optional()
      .allow('')
      .label('Target End Date Raw Date'),
    total_left: Joi.number().optional().allow('').label('Total Left'),
    user_id: Joi.string().optional().allow('').label('User ID'),
    year: Joi.number().optional().label('Year'),
  };

  const [iconSelectOptions, setIconSelectOptions] = useState([]);

  const getIconOptions = () => {
    const options = Object.keys(iconUrlMap).map((key) => {
      const { val, url } = iconUrlMap[key];
      return { value: key, label: val, url };
    });
    setIconSelectOptions(options);
  };

  //form handling
  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(budgetGoalFormData, schema, options);
    if (!error) return null;

    const errors = {};

    for (let item of error.details) errors[item.path[0]] = item.message;

    //console.log('Errors: ', errors);

    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaLocal);

    //console.log('Error: ', error);
    return error ? error.details[0].message : null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //get the values from the form
    // var emailAddress = this.emailAddress.current.value;
    // var password = document.getElementById('txtPassword').value;
    //call the server to save the changes

    const errors = validate();

    for (const error in errors) {
      if (errors.hasOwnProperty(error)) {
        toast.error(errors[error]);
      }
    }
    setErrors({ errors: errors || {} });
    if (errors) return;

    doSubmit();
  };

  const handleChange = (input) => {
    const currentErrors = { ...errors };
    let name, value;

    if (input && input.currentTarget) {
      name = input.currentTarget.name;
      value = input.currentTarget.value;
    } else if (input && input.value) {
      if (input && input.value && input.label) {
        name = 'goal_category_icon';
        value = input.value;

        // Update goal_date to a timestamp in milliseconds
        budgetGoalFormData.goal_date = new Date(
          budgetGoalFormData.goal_date
        ).getTime();
      }
    } else if (input && input.value && input.label) {
      name = 'goal_category_icon';
      value = input;
    }

    const errorMessage = validateProperty({ name, value });
    if (errorMessage) {
      currentErrors[name] = errorMessage;

      //console.log('Current Errors: ', currentErrors);
    } else delete currentErrors[name];

    setBudgetGoalFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors(currentErrors);
  };

  const handleGoalDateChange = (date) => {
    const newDate = new Date(date);
    const timestamp = newDate.getTime();

    setBudgetGoalFormData((prevData) => ({
      ...prevData,
      goal_date: timestamp,
      month: newDate.getMonth() + 1, // getMonth() returns a zero-based month, so add 1
      year: newDate.getFullYear(),
      target_end_date_raw_date: timestamp,
    }));
  };

  const doSubmit = async () => {
    try {
      setIsLoading(true);

      //setting the date related records
      if (budgetGoalFormData.goal_date) {
        const date = new Date(budgetGoalFormData.goal_date);
        budgetGoalFormData.month = Number(date.getMonth() + 1); // getMonth() returns a zero-based month, so add 1
        budgetGoalFormData.year = Number(date.getFullYear());
        budgetGoalFormData.target_end_date_raw_date = Number(date.getTime()); // getTime() returns the timestamp in milliseconds
      }

      //set the user id etc
      budgetGoalFormData.user_id = localStorage.getItem(config.user_id);
      budgetGoalFormData.record_time_stamp = Number(Date.now());
      budgetGoalFormData.budget_goal_id = budgetGoalId;
      budgetGoalFormData.is_deleted = false;
      budgetGoalFormData.has_achieved_goal = false;
      budgetGoalFormData.ai_insight_msg = '';
      budgetGoalFormData.bank_account_id = '';
      budgetGoalFormData.total_left =
        Number(budgetGoalFormData.budget_goal_sum_total) -
        Number(budgetGoalFormData.current_amt_saved);
      budgetGoalFormData.budget_goal_sum_total = Number(
        budgetGoalFormData.budget_goal_sum_total
      );
      budgetGoalFormData.current_amt_saved = Number(
        budgetGoalFormData.current_amt_saved
      );
      budgetGoalFormData.record_time_stamp = Number(Date.now());

      budgetGoalFormData.goal_creator_profile = {
        creator_profile_id: uuidv4(),
        user_id: localStorage.getItem(config.user_id),
        first_name: localStorage.getItem(config.first_name),
        last_name: localStorage.getItem(config.last_name),
        email_address: localStorage.getItem(config.email),
        profile_img: localStorage.getItem(config.profile_img),
        time_stamp: Number(budgetGoalFormData.goal_creator_profile.time_stamp),
        modified_time_stamp: Number(Date.now()),
      };

      budgetGoalFormData.pals_working_together_towards_goal = [];

      // Remove the goal_date field from the form data
      const processedBudgetGoalFormData = { ...budgetGoalFormData };
      delete processedBudgetGoalFormData.goal_date;

      //console.log('Budget Goal Form Data: ', processedBudgetGoalFormData);

      const response = await updateBudgetGoal(processedBudgetGoalFormData);
      //console.log('Response: ', response);
      if (response) {
        setIsLoading(false);
        toast.success('Budget Goal updated successfully');
        navigate(`/budgetGoal/${budgetGoalId}`);
      } else {
        setIsLoading(false);
        toast.error('Failed to update Budget Goal');
      }
    } catch (error) {
      setIsLoading(false);
      //console.log('Error: ', error);
    }
  };

  const [selectedOptionBudgetGoalType, setSelectedOptionBudgetGoalType] =
    useState(null);
  const handleBudgetGoalChangeType = (option) => {
    setSelectedOptionBudgetGoalType(option);
    budgetGoalFormData.goal_type = option.value;
  };

  const capitalize = (str) => {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  const formatOptionLabel = ({ value, label, url }) => (
    <div>
      <img
        src={url}
        alt={label}
        style={{ width: '20px', marginRight: '10px' }}
      />
      {capitalize(value.split(/(?=[A-Z])/).join(' '))}
    </div>
  );
  const selectedIconOption = iconSelectOptions.find(
    (option) => option.value === budgetGoalFormData.goal_category_icon
  );

  const getBudgetGoal = async () => {
    //get the budget goals
    //console.log('Budget goal id: ', budgetGoalId);
    const budgetGoal = await fetchBudgetGoalById(budgetGoalId);

    //console.log('Budget Goal: ', budgetGoal.target_end_date_raw_date);
    const goalDate = new Date(budgetGoal.target_end_date_raw_date);
    // Convert goal_date to a timestamp in milliseconds
    const goalDateTimestamp = goalDate;
    //console.log('Goal Date Timestamp: ', goalDateTimestamp);
    budgetGoalFormData.goal_date = goalDateTimestamp;
    // Set the budgetGoal and the formattedDate as the goal_date in the budgetGoalFormData
    //console.log('Today Date: ', new Date());
    setBudgetGoalFormData({
      ...budgetGoal,
      goal_date: goalDateTimestamp,
      // Set the month, year, and target_end_date_raw_date fields
      month: new Date(goalDateTimestamp).getMonth() + 1, // getMonth() returns a zero-based month, so add 1
      year: new Date(goalDateTimestamp).getFullYear(),
      target_end_date_raw_date: goalDateTimestamp.getTime(),
    });

    //need to manually set the goal type and the select goal deadline
    const selectedOption = budgetGoalCategories.find(
      (option) => option.value === budgetGoal.goal_type
    );
    setSelectedOptionBudgetGoalType(selectedOption);

    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    getIconOptions();

    //get the budget goal
    getBudgetGoal();

    setIsLoading(false);
  }, []);

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}
      {/*/Loader */}
      <div data-bs-theme={isDarkMode ? 'dark' : 'light'}>
        <div className='container-fluid'>
          <PageTitleBox pageTitle={pageTitle} />
          <div className='row '>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-header align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    Edit Budget Goal
                  </h4>
                  <div className='flex-shrink-0'></div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='card-body'>
                    <div className='live-preview'>
                      <div className='row gy-4'>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='goal_name'
                              label='Goal Name'
                              value={budgetGoalFormData.goal_name}
                              onChange={handleChange}
                              error={errors.goal_name}
                            />
                          </div>
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <label htmlFor='form-grid-category'>
                              Goal Type
                            </label>
                            <Select
                              value={selectedOptionBudgetGoalType}
                              onChange={handleBudgetGoalChangeType}
                              options={budgetGoalCategories}
                            />
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='budget_goal_sum_total'
                              label='Budget Goal Sum Total'
                              type={'number'}
                              value={budgetGoalFormData.budget_goal_sum_total}
                              onChange={handleChange}
                              error={errors.budget_goal_sum_total}
                            />
                          </div>
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='current_amt_saved'
                              label='Amount Already Saved'
                              type={'number'}
                              value={budgetGoalFormData.current_amt_saved}
                              onChange={handleChange}
                              error={errors.current_amt_saved}
                            />
                          </div>
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <label htmlFor='form-grid-category'>
                              Goal Icon
                            </label>

                            <Select
                              name='goal_category_icon'
                              value={selectedIconOption}
                              onChange={handleChange}
                              options={iconSelectOptions}
                              formatOptionLabel={formatOptionLabel}
                            />
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='goal_date'
                              className='form-control'
                              label={'Select Goal Deadline'}
                              type='date'
                              value={
                                budgetGoalFormData.goal_date
                                  ? new Date(budgetGoalFormData.goal_date)
                                      .toISOString()
                                      .split('T')[0]
                                  : ''
                              }
                              error={errors.goal_date}
                              onChange={handleGoalDateChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card-footer'>
                      <button
                        disabled={validate()}
                        className='btn btn-success'
                        type='submit'
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditBudgetGoal;
