import React, { useEffect, useState } from 'react';
import config from '../../../../constants/config.json';
import LoaderView from '../../../reusable/loader/loaderView';
import {
  fetchAllPalRequestsByEmail,
  fetchAllPalsBySecondaryPalIdWhoArePending,
  fetchAllPalsBySecondaryPalIdWhoAreRejected,
  fetchPalByPrimaryPalId,
} from '../../../../services/palz/palzManagement';

import friends_animation from '../../../reusable/animations/lottie/friends_animation.json';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';
import PageTitleBox from '../../../reusable/portal/general/pageTitleBox';
import PendingPalGrid from './sub/pendingPalGrid';

const PendingPalz = ({ isDarkMode }) => {
  const [isLoading, setIsLoading] = useState();
  const storedUserId = localStorage.getItem(config.user_id);
  const pageTitle = 'Pending Palz';

  //shows both the pending sent out and the pending receieved from someone else
  const [allPendingPalz, setAllPendingPalz] = useState([
    {
      access_right: {
        access_id: '',
        can_comment: false,
        can_edit_budget_goal: false,
        can_edit_expense: false,
        can_edit_income: false,
        can_link_their_budget: false,
        can_view_budget: true,
        can_view_budget_goal: true,
        can_view_expense: true,
        can_view_income: true,
        pal_id: '',
        record_time_stamp: 0,
      },
      date_link_created: '',
      email_address: '',
      expiration_date: 0,
      has_access: false,
      invite_code: '',
      pal_first_name: '',
      pal_id: '',
      pal_last_name: '',
      pal_profile_image: '',
      primary_pal_id: '',
      requestor_first_name: '',
      requestor_last_name: '',
      requestor_profile_image: '',
    },
  ]);

  const emptyPalzLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: friends_animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);

    Promise.all([
      fetchAllPalRequestsByEmail(),
      fetchAllPalsBySecondaryPalIdWhoAreRejected(),
      fetchAllPalsBySecondaryPalIdWhoArePending(),
    ])
      .then(([pendingPalz1, rejectedPalz, pendingPalz2]) => {
        //console.log('All fetchAllPalsBySecondaryPalIdWhoArePending palz');
        //console.log(pendingPalz1);
        setAllPendingPalz(pendingPalz1);

        //console.log('All fetchAllPalsBySecondaryPalIdWhoAreRejected palz');
        //console.log(rejectedPalz);
        if (rejectedPalz.length > 0) {
          setAllPendingPalz(addUniquePalz(rejectedPalz, rejectedPalz));
        }

        //console.log('All fetchAllPalsBySecondaryPalIdWhoArePending palz');
        //console.log(pendingPalz2);
        if (pendingPalz2.length > 0) {
          setAllPendingPalz(addUniquePalz(pendingPalz2, pendingPalz2));
        }

        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  const addUniquePalz = (prevPalz, newPalz) => {
    if (!Array.isArray(prevPalz) || !Array.isArray(newPalz)) {
      console.error(
        'Invalid inputs to addUniquePalz. Both inputs should be arrays.'
      );
      return [];
    }

    const existingKeys = new Set(
      prevPalz.map(
        (pal) =>
          `${pal.id}-${pal.pal_first_name}-${pal.pal_last_name}-${pal.email_address}`
      )
    );

    const uniqueNewPalz = newPalz.filter(
      (pal) =>
        typeof pal === 'object' &&
        pal !== null &&
        'id' in pal &&
        'pal_first_name' in pal &&
        'pal_last_name' in pal &&
        'email_address' in pal &&
        !existingKeys.has(
          `${pal.id}-${pal.pal_first_name}-${pal.pal_last_name}-${pal.email_address}`
        )
    );

    return [...prevPalz, ...uniqueNewPalz];
  };

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}
      {/*/Loader */}

      {/* Grid with all approved palz */}

      <div data-bs-theme={isDarkMode ? 'dark' : 'light'}>
        <div className='container-fluid'>
          {allPendingPalz && allPendingPalz.length < 1 && (
            <PageTitleBox pageTitle={pageTitle} />
          )}
          <div className='row py-2 '>
            <div className='col-xl-4'>
              {/* <ExpenseBreakdownCard
                expenseChartData={expenseChartData}
                isDarkMode={isDarkMode}
                    />*/}
            </div>
            <div className='col-xl-6'>
              <div className='row'>
                <div className='col-md-6'>
                  {/*}  <SpendingCard
                    mostSpentCategory={mostSpentCategory}
                    percentageChange={percentageChange}
                    />*/}
                </div>
                <div className='col-md-6'>
                  {/*} <BudgetLeftCard
                    leftToBudget={leftToBudget}
                    totalBudget={totalBudget}
                    />*/}
                </div>
                <div className='col-md-6'>
                  {/*} <TotalSpendCard
                    totalSpentPercentageChange={totalSpentPercentageChange}
                    totalSpent={totalSpent}
                    secondLatestBudgetSummary={secondLatestBudgetSummary}
                    />*/}
                </div>
                <div className='col-md-6'>
                  {/* <SavingCard
                    mostSavedCategory={mostSavedCategory}
                    percentageChange={percentageChangeInSavings}
                    />*/}
                </div>
              </div>
            </div>
          </div>
          {/* end col*/}
        </div>{' '}
        {/* end row*/}
        <div className='row'>
          {/* Expense Transaction Grid */}
          {allPendingPalz &&
          allPendingPalz.length > 0 &&
          allPendingPalz[0]?.email_address ? (
            <PendingPalGrid
              allApprovedPalz={allPendingPalz}
              storedUserId={storedUserId}
            />
          ) : (
            <>
              {' '}
              <div className='col-12'>
                <div className='card'>
                  <div className='card-body p-0'>
                    <div className='row align-items-end'>
                      <div className='row p-3'>
                        <Lottie
                          options={emptyPalzLottieOptions}
                          height={400}
                          width={400}
                        />

                        <div className='text-center'>
                          <h3> No Pal Requests found 🥺</h3>
                          <span>Your Palz are on their way.</span>
                        </div>
                        <div className='mt-3 text-center'>
                          <Link
                            to={'/inviteNewPal'}
                            className='btn btn-success'
                          >
                            Invite a Pal
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* end col */}
        </div>
        {/* end row */}
        {/* container-fluid */}
      </div>
      {/* End Page-content */}

      {/* End Page-content */}
    </React.Fragment>
  );
};

export default PendingPalz;
