

export const budgetGoalCategories = [
  { value: 'investment', label: 'Investment' },
  { value: 'vacation', label: 'Vacation' },
  { value: 'emergency_fund', label: 'Emergency Fund' },
  { value: 'retirement', label: 'Retirement' },
  { value: 'education', label: 'Education' },
  { value: 'housing', label: 'Housing' },
  { value: 'debt_repayment', label: 'Debt Repayment' },
  { value: 'health_wellness', label: 'Health & Wellness' },
  { value: 'charitable_giving', label: 'Charitable Giving' },
  { value: 'entertainment_leisure', label: 'Entertainment & Leisure' },
  { value: 'savings', label: 'Savings' },
  {value: 'other', label: 'Other'}
];

export function findExpenseCategoryByKey(key) {
  const category = budgetGoalCategories.find((option) => option.value === key);
  return category ? category.label : null;
}

