export const predefinedColors = [
  'rgba(230, 115, 115, 1)', // Lighter matte red
  'rgba(115, 230, 115, 1)', // Lighter matte green
  'rgba(115, 115, 230, 1)', // Lighter matte blue
  'rgba(255, 165, 0, 1)', // Lighter matte orange (already quite light)
  'rgba(239, 130, 173, 1)', // Lighter matte pink
  'rgba(160, 130, 240, 1)', // Lighter matte purple
  'rgba(255, 245, 157, 1)', // Lighter matte yellow
  'rgba(115, 255, 255, 1)', // Lighter matte cyan
  // ...additional colors as needed
];

export function dynamicallyGeneratedColor(categoryName, isDarkMode) {
  const hash = Math.abs(stringToHash(categoryName));
  const hue = (hash % 360) / 360;
  const saturation = 95; // Keep saturation for a matte effect
  const lightness = isDarkMode ? 35 : 55; // Increase lightness slightly for hover differentiation

  return `hsla(${hue * 360}, ${saturation}%, ${lightness}%, 1)`;
}

export function parseColor(color) {
  // This will parse an RGBA color string and return the individual components
  const rgba = color.match(
    /rgba?\((\d+), (\d+), (\d+)(?:, (\d+(?:\.\d+)?))?\)/i
  );
  return rgba
    ? {
        r: parseInt(rgba[1], 10),
        g: parseInt(rgba[2], 10),
        b: parseInt(rgba[3], 10),
        a: rgba[4] !== undefined ? parseFloat(rgba[4]) : 1,
      }
    : null;
}

export function darkenColor(color, amount = 0.8) {
  // This function darkens the color by a given amount
  const colorObj = parseColor(color);
  if (colorObj) {
    colorObj.r = Math.max(0, Math.min(255, Math.round(colorObj.r * amount)));
    colorObj.g = Math.max(0, Math.min(255, Math.round(colorObj.g * amount)));
    colorObj.b = Math.max(0, Math.min(255, Math.round(colorObj.b * amount)));
    // Keep the alpha channel the same
    return `rgba(${colorObj.r}, ${colorObj.g}, ${colorObj.b}, ${colorObj.a})`;
  }
  return color; // If the color parsing fails, return the original color
}

export function colorForCategory(categoryName) {
  const hash = Math.abs(stringToHash(categoryName));
  if (hash < predefinedColors.length) {
    return predefinedColors[hash % predefinedColors.length];
  } else {
    return dynamicallyGeneratedColor(categoryName);
  }
}

export function stringToHash(string) {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}