import React from 'react';

const UpgradeAlertCard = ({ daysLeft, upgradeLink, imageSrc }) => {
  return (
    <div className='col-12'>
      <div className='card'>
        <div className='card-body p-0'>
          <div
            className='alert alert-warning border-0 rounded-0 m-0 d-flex align-items-center'
            role='alert'
          >
            <i
              data-feather='alert-triangle'
              className='text-warning me-2 icon-sm'
            ></i>
            <div className='flex-grow-1 text-truncate'>
              Upgrade anytime—downgrade to Free whenever.
            </div>
            <div className='flex-shrink-0'>
              <a
                href={upgradeLink}
                className='text-reset text-decoration-underline'
              >
                <b>Upgrade</b>
              </a>
            </div>
          </div>
          <div className='row align-items-end'>
            <div className='col-sm-8'>
              <div className='p-3'>
                <p className='fs-16 lh-base'>
                  You're on the <span className='fw-semibold'>Free Plan</span>.
                  Upgrade to unlock Budget Goals, Connect with Palz, and more!
                  <i className='mdi mdi-arrow-up'></i>
                </p>
                <div className='mt-3'>
                  <a href={upgradeLink} className='btn btn-success'>
                    Upgrade Account!
                  </a>
                </div>
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='px-3'>
                <img
                  style={{ height: '125px', width: '125px' }}
                  src={imageSrc}
                  className='img-fluid'
                  alt='Upgrade Account'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeAlertCard;
