import React from 'react';

const PageTitleBox = ({ pageTitle }) => {
  return (
    <div className='row px-4'>
      <div className='col-12'>
        <div className='page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent'>
          <h4 className='mb-sm-0'>{pageTitle}</h4>

          <div className='page-title-right'>
            <ol className='breadcrumb m-0'>
              <li className='breadcrumb-item'>
                <a
                  href='#'
                  onClick={(event) => {
                    event.preventDefault();
                    // your code here
                  }}
                >
                  Pages
                </a>
              </li>
              <li className='breadcrumb-item active'>{pageTitle}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTitleBox;
