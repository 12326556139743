import React, { useEffect, useState } from 'react';
import LoaderView from '../../../reusable/loader/loaderView';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
  predefinedColors,
  dynamicallyGeneratedColor,
  parseColor,
  darkenColor,
  colorForCategory,
  stringToHash,
} from '../../../../services/helpers/colorService';
import {
  fetchCurrentExpenses,
  fetchExpensesAndTransactionsByUserPalId,
} from '../../../../services/expenses/expenseServiceManagement';
import ExpenseTransactionGrid from '../../../reusable/cards/expense/expenseTransansactionGrid';
import ExpenseTransactionGraph from '../../../reusable/cards/expense/graphs/expenseTransactionGraph';
import ExpenseBreakdownCard from '../../../reusable/cards/dashboard/expenseBreakdownCard';
import RecentExpensesCard from '../../../reusable/cards/expense/recentExpenseCard';
import ExpenseGrid from '../../../reusable/cards/expense/expenseGrid';
import empty_expenses_animation from '../../../reusable/animations/lottie/empty_expenses_animation.json';
import PageTitleBox from '../../../reusable/portal/general/pageTitleBox';

const CurrentExpenses = ({ isDarkMode }) => {
  const pageTitle = 'Current Expenses';
  ChartJS.register(ArcElement, Tooltip, Legend);
  const [isLoading, setIsLoading] = useState();
  //look at pagination
  const [expensesAndTransactions, setExpensesAndTransactions] = useState([
    {
      account_id: '',
      day_due_each_month: 0,
      expense_amt: 0,
      expense_category: '',
      expense_description: '',
      expense_icon: '',
      expense_id: '',
      expense_name: '',
      has_insight: false,
      is_re_occuring: false,
      record_time_stamp: 0,
      transactions: [
        {
          history_id: '',
          expense_id: '',
          investment_id: '',
          history_description: '',
          saving_acc_id: '',
          account_id: '',
          amount: 0,
          budget_amount_left: 0,
          budget_category_name: '',
          budget_category_selected_id: '',
          currency: '',
          currency_interest: 0,
          expense_image_scans: null,
          history_day: 0,
          history_month: 0,
          history_type: '',
          history_year: 0,
          is_deleted: false,
          time_stamp: 0,
          total_allocated_for_category: 0,
        },
      ],
      user_pal_id: '',
    },
  ]);

  //expenseChartData
  const [expenseChartData, setExpenseChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Budget Breakdown',
        data: [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  });

  //lottie

  const emptyExpensesLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: empty_expenses_animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const getLatestExpensesAndTransactionsChartData = async (
    expensesAndTransactions
  ) => {
    const labels = expensesAndTransactions.map(
      (expense) => expense.expense_name
    );

    const data = expensesAndTransactions.map((expense) =>
      expense.transactions.reduce(
        (sum, transaction) => sum + transaction.amount,
        0
      )
    );

    const hoverColors = predefinedColors.map((color) =>
      darkenColor(color, 0.7)
    ); // darken by 30%

    setExpenseChartData((prevState) => ({
      ...prevState,
      labels: labels,
      datasets: prevState.datasets.map((dataset) => ({
        ...dataset,
        data: data,
        backgroundColor: predefinedColors, // Use the predefined colors
        borderColor: isDarkMode ? '#6c757d' : 'white',
        borderWidth: 3,
        hoverBackgroundColor: hoverColors, // Use the darker colors for hover
      })),
    }));
  };

  const getTheCurrentExpenses = async () => {
    //get the latest expenses
    const expensesAndTransactions = await fetchCurrentExpenses();

    setExpensesAndTransactions(expensesAndTransactions);

    //console.log('Expenses and Transactions');
    //console.log(expensesAndTransactions);

    if (expensesAndTransactions) {
      getLatestExpensesAndTransactionsChartData(expensesAndTransactions);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);

    //get the latest expenses
    getTheCurrentExpenses();

    setIsLoading(false);
  }, []);

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}
      {/*/Loader */}

      {/* ============================================================== */}
      {/* Start right Content here */}
      {/* ============================================================== */}

      <div data-bs-theme={isDarkMode ? 'dark' : 'light'}>
        <div className='container-fluid'>
          <PageTitleBox pageTitle={pageTitle} />
          {/* end page title */}
          <div className='row'>
            <div className='col-xl-3'>
              <ExpenseBreakdownCard
                lottieAnimation={emptyExpensesLottieOptions}
                expenseChartData={expenseChartData}
                isDarkMode={isDarkMode}
              />
            </div>
            <div className='col-xl-6'>
              <div className='row'>
                <div className='col-md-6'>
                  {/*}  <SpendingCard
                    mostSpentCategory={mostSpentCategory}
                    percentageChange={percentageChange}
                    />*/}
                </div>
                <div className='col-md-6'>
                  {/*} <BudgetLeftCard
                    leftToBudget={leftToBudget}
                    totalBudget={totalBudget}
                    />*/}
                </div>
                <div className='col-md-6'>
                  {/*} <TotalSpendCard
                    totalSpentPercentageChange={totalSpentPercentageChange}
                    totalSpent={totalSpent}
                    secondLatestBudgetSummary={secondLatestBudgetSummary}
                    />*/}
                </div>
                <div className='col-md-6'>
                  {/* <SavingCard
                    mostSavedCategory={mostSavedCategory}
                    percentageChange={percentageChangeInSavings}
                    />*/}
                </div>
              </div>
            </div>
          </div>
          {/* end col*/}
        </div>{' '}
        {/* end row*/}
        <div className='row'>
          {/* Expense Transaction Grid */}
          {expensesAndTransactions &&
            expensesAndTransactions?.length > 0 &&
            expensesAndTransactions[0]?.expense_id && (
              <ExpenseGrid
                expenses={expensesAndTransactions}
                title='Current Expenses'
              />
            )}

          {/* end col */}
        </div>
        {/* end row */}
        {/* container-fluid */}
      </div>
      {/* End Page-content */}

      {/* End Page-content */}
    </React.Fragment>
  );
};

export default CurrentExpenses;
