import React from 'react';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';

const BudgetGoalContributionCard = ({ budgetGoals, lottieAnimation }) => {
  return (
    <>
      <div className='card card-height-100'>
        <div className='card-header align-items-center d-flex'>
          <h4 className='card-title mb-0 flex-grow-1'>
            Budget Goal Contribution Breakdown
          </h4>
          <div className='flex-shrink-0' hidden>
            <button
              type='button'
              className='btn btn-soft-primary btn-sm material-shadow-none'
            >
              All Goals
            </button>
          </div>
        </div>
        <div className='card-body'>
          <div className='row align-items-center'>
            <div className='col-6'>
              <h6 className='text-muted text-uppercase fw-semibold text-truncate fs-12 mb-3'>
                Total Saved
              </h6>
              <h4 className='mb-0'>
                $
                {budgetGoals.reduce(
                  (total, goal) => total + goal.current_amt_saved,
                  0
                )}
              </h4>
            </div>
            <div className='col-6'>
              <div className='text-center'>
                <img
                  src='assets/images/illustrator-1.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
          </div>

          {budgetGoals && budgetGoals.length > 0 && budgetGoals[0].goal_name ? (
            <>
              <div className='mt-3 pt-2'>
                <div className='progress progress-lg rounded-pill'>
                  {budgetGoals.map((goal, index) => {
                    const progress =
                      (goal.current_amt_saved / goal.budget_goal_sum_total) *
                      100;
                    const barColor = [
                      'bg-primary',
                      'bg-info',
                      'bg-success',
                      'bg-warning',
                      'bg-danger',
                    ][index % 5];

                    return (
                      <div
                        key={goal.budget_goal_id}
                        className={`progress-bar ${barColor}`}
                        role='progressbar'
                        style={{ width: `${progress}%` }}
                        aria-valuenow={progress}
                        aria-valuemin='0'
                        aria-valuemax='100'
                      ></div>
                    );
                  })}
                </div>
              </div>
              <div className='mt-3 pt-2'>
                {budgetGoals.map((goal, index) => {
                  const progress =
                    (goal.current_amt_saved / goal.budget_goal_sum_total) * 100;
                  const barColor = [
                    'text-primary',
                    'text-info',
                    'text-success',
                    'text-warning',
                    'text-danger',
                  ][index % 5];

                  return (
                    <div className='d-flex mb-2' key={goal.budget_goal_id}>
                      <div className='flex-grow-1'>
                        <p className='text-truncate text-muted fs-14 mb-0'>
                          <i
                            className={`mdi mdi-circle align-middle ${barColor} me-2`}
                          ></i>
                          {goal.goal_name}
                        </p>
                      </div>
                      <div className='flex-shrink-0'>
                        <p className='mb-0'>{Number(progress)?.toFixed(2)}%</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <div className='row'>
                <Lottie options={lottieAnimation} height={400} width={400} />

                <div className='text-center'>
                  <h3>No Budget Goals Found!</h3>
                  <span>
                    It is always a good idea to have some budget goals like a
                    nice vacation or buying a house 😁. If you already do, its
                    on its way.
                  </span>
                </div>
                <div className='mt-3 text-center'>
                  <Link to={'/createNewBudgetGoal'} className='btn btn-success'>
                    Create new Goal
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BudgetGoalContributionCard;
