import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../../../reusable/form/Input';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import { v4 as uuidv4 } from 'uuid';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Joi from 'joi-browser';
import MultiLineInput from '../../../reusable/form/multilineinput';
import PageTitleBox from '../../../reusable/portal/general/pageTitleBox';
//so you can copy from the previous budget
import {
  createBudgetAndCategories,
  deactivateOtherBudgets,
  fetchLatestBudget,
  updateAmountLeft,
} from '../../../../services/budgets/budgetServiceManagement';
import { getIconUrl, iconUrlMap } from '../../../../constants/iconUrlMap';
import { set } from 'lodash';
import Select from 'react-select';
import BudgetCardGrid from '../../../reusable/cards/budget/budgetCardGrid';
import CreateBudgetCategoryGrid from '../../../reusable/cards/budget/createBudgetCategoryGrid';
import config from '../../../../constants/config.json';

//future: the copy from previous budget
const CreateNewBudget = ({ isDarkMode }) => {
  const pageTitle = 'Create New Budget';
  const [ignored, setIgnored] = useReducer((x) => x + 1, 0);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  //merege budget and budget categories later when storing
  //profile info
  const first_name = localStorage.getItem(config.first_name);
  const last_name = localStorage.getItem(config.last_name);
  const email_address = localStorage.getItem(config.email);
  const user_id = localStorage.getItem(config.user_id);

  const [budgetFormData, setBudgetFormData] = useState({
    budget_date: Date(), //not saved
    budget_id: '',
    creator_profile: {
      creator_profile_id: '',
      email_address: '',
      profile_img: '',
      first_name: '',
      last_name: '',
      user_id: '',
      profile_img: '',
    },

    email_address: '',
    first_name: '',
    last_name: '',
    is_active_budget: true,
    month: 0,
    record_time_stamp: 0,
    total_left: 0,
    total_sum: 0,
    user_id: '',
    year: 0,
  });
  const [isActiveBudget, setIsActiveBudget] = useState(
    budgetFormData.is_active_budget
  );

  //managed as a list of objects
  const [budgetCategoriesFormData, setBudgetCategoriesFormData] = useState([]);

  //temporary object to hold the new category
  const [newCategory, setNewCategory] = useState({
    amount_left: 0,
    amount_rolled_over_from_previous: 0,
    budget_category_id: '',
    budget_id: '',
    category_icon: '',
    category_name: '',
    month: 0,
    record_time_stamp: 0,
    system_budget_cat_id: '',
    total_allocated_for_property: 0,
    year: 0,
  });

  const [errors, setErrors] = useState({});

  //validate only the budget categories
  const schema = {
    budget_date: Joi.date().required().label('Budget Date'),
    budget_id: Joi.string().label('Budget Id').optional().allow(''),
    creator_profile: Joi.object().optional().label('Creator Profile'),
    email_address: Joi.string().label('Email Address').optional().allow(''),
    first_name: Joi.string().label('First Name').optional().allow(''),
    is_active_budget: Joi.boolean().label('Is Active Budget').optional(),
    last_name: Joi.string().label('Last Name').optional().allow(''),
    month: Joi.number().label('Month').optional(),
    record_time_stamp: Joi.number().label('Record Time Stamp').optional(),
    total_left: Joi.number().label('Total Left').optional(),
    total_sum: Joi.number().label('Total Sum').optional(),
    user_id: Joi.string().label('User Id').optional().allow(''),
    year: Joi.number().label('Year').optional(),
  };

  //need to find a way to validate the budget categories whih is a list of objects
  const budgetCategorySchema = {
    category_icon: Joi.string().required().label('Category Icon'),
    category_name: Joi.string().required().label('Category Name'),
    total_allocated_for_property: Joi.number()
      .required()
      .label('Total Allocated For Property'),
    //the rest of the fields are not required and are set by the system
    amount_left: Joi.number().label('Amount Left').optional(),
    amount_rolled_over_from_previous: Joi.number()
      .label('Amount Rolled Over From Previous')
      .optional(),
    budget_category_id: Joi.string()
      .label('Budget Category Id')
      .optional()
      .allow(''),
    budget_id: Joi.string().label('Budget Id').optional().allow(''),
    month: Joi.number().label('Month').optional(),
    record_time_stamp: Joi.number().label('Record Time Stamp').optional(),
    system_budget_cat_id: Joi.string()
      .label('System Budget Cat Id')
      .optional()
      .allow(''),
    year: Joi.number().label('Year').optional(),
  };

  //have to modify the validation since it just needs to have one or more categories and a budget date
  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(budgetFormData, schema, options);

    const errors = {};

    // Check if budgetCategoriesFormData is empty
    if (budgetCategoriesFormData.length === 0) {
      errors.budgetCategories = 'At least one budget category is required.';
    }

    if (!error && Object.keys(errors).length === 0) return null;

    // Check if error is not null before trying to access error.details
    if (error) {
      for (let item of error.details) errors[item.path[0]] = item.message;
    }

    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaLocal);

    return error ? error.details[0].message : null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validate();

    for (const error in errors) {
      if (errors.hasOwnProperty(error)) {
        toast.error(errors[error]);
      }
    }
    setErrors({ errors: errors || {} });
    if (errors) return;

    doSubmit();
  };

  const validateNewCategory = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(newCategory, budgetCategorySchema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const handleChange = (input) => {
    const currentErrors = { ...errors }; // Renamed to currentErrors to avoid shadowing
    const errorMessage = validateProperty(input);
    if (errorMessage) currentErrors[input.name] = errorMessage;
    else delete currentErrors[input.name];

    const data = { ...budgetFormData };
    if (input.name === 'budget_date') {
      data[input.name] = input.value;
      data.month = input.value.getMonth() + 1; // JavaScript months are 0-indexed
      data.year = input.value.getFullYear();
    } else {
      data[input.name] = input.value;
    }
    setBudgetFormData(data);
    setErrors(currentErrors); // Use currentErrors here
  };
  const doSubmit = async () => {
    try {
      setIsLoading(true);

      const budget_id = uuidv4();

      if (budgetCategoriesFormData.length > 0) {
        //total budget
        const totalBudget = budgetCategoriesFormData.reduce(
          (acc, category) =>
            acc + Number(category.total_allocated_for_property),
          0
        );

        //update each category with the budget id and ensure each is a number
        let budgetCategoriesFormDataWithBudgetId = [];

        for (let category of budgetCategoriesFormData) {
          let updatedCategory = { ...category, budget_id: budget_id };

          // Ensure properties that should be numbers are numbers
          updatedCategory.amount_left = Number(updatedCategory.amount_left);
          updatedCategory.amount_rolled_over_from_previous = Number(
            updatedCategory.amount_rolled_over_from_previous
          );
          updatedCategory.total_allocated_for_property = Number(
            updatedCategory.total_allocated_for_property
          );
          updatedCategory.month = Number(updatedCategory.month);
          updatedCategory.record_time_stamp = Number(
            updatedCategory.record_time_stamp
          );
          updatedCategory.year = Number(updatedCategory.year);

          budgetCategoriesFormDataWithBudgetId.push(updatedCategory);
        }

        const budgetData = {
          budget_id: budget_id,
          creator_profile: {
            creator_profile_id: '',
            email_address: email_address,
            profile_img: '',
            first_name: first_name,
            last_name: last_name,
            user_id: user_id,
            profile_img: '',
          },
          email_address: email_address,
          first_name: first_name,
          is_active_budget: isActiveBudget,
          last_name: last_name,
          month: Number(budgetFormData.month),
          record_time_stamp: Date.now(),
          total_left: Number(totalBudget),
          total_sum: Number(totalBudget),
          user_id: user_id,
          year: Number(budgetFormData.year),
          budget_categories: budgetCategoriesFormDataWithBudgetId,
        };

        //console.log('Budget Data:', budgetData);
        //submit to the server
        const response = await createBudgetAndCategories(budgetData);
        //console.log('Response:', response);
        if (!response.is_error) {
          //toast.success('Budget created successfully.');
          const deactivated = await deactivateOtherBudgets(budget_id);
          if (deactivated) {
            //console.log('Deactivated:', deactivated.server_message);
            toast.success(`${response.server_message}`);

            navigate('/budget');
          } else {
            toast.error('Budget could not be created.');
          }
        } else {
          toast.error('Budget could not be created.');
          return;
        }
      } else {
        toast.error('Please add at least one budget category.');
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...errors };
        errors.email = ex.response.data;
        setErrors({ errors });
      }
    }
    setIsLoading(false);
  };

  const [selectedOptionExpenseIcon, setSelectedOptionExpenseIcon] =
    useState(null);
  const [iconSelectOptions, setIconSelectOptions] = useState([]);

  const getIconOptions = () => {
    const options = Object.keys(iconUrlMap).map((key) => {
      const { val, url } = iconUrlMap[key];
      return { value: key, label: val, url };
    });
    setIconSelectOptions(options);
  };

  const handleExpenseChangeIcon = (_selectedExpenseIconOption) => {
    setSelectedOptionExpenseIcon(_selectedExpenseIconOption);
    handleChange({
      target: {
        name: 'expense_icon',
        value: _selectedExpenseIconOption
          ? _selectedExpenseIconOption.value
          : '',
      },
    });
  };

  const updateCategory = (updatedCategory) => {
    setBudgetCategoriesFormData((budgetCategoriesFormData) =>
      budgetCategoriesFormData.map((category) =>
        category.budget_category_id === updatedCategory.budget_category_id
          ? updatedCategory
          : category
      )
    );
  };

  const deleteCategory = (categoryId) => {
    setBudgetCategoriesFormData((budgetCategoriesFormData) =>
      budgetCategoriesFormData.filter(
        (category) => category.budget_category_id !== categoryId
      )
    );
  };

  useEffect(() => {
    //future enhancements, get the latest budget and copy the categories and previous budget categories category name to auto fill name and icon
    setIsLoading(true);
    window.scrollTo(0, 0);

    setInitialBudgetMonth();

    getIconOptions();

    setIsLoading(false);
  }, []);

  const setInitialBudgetMonth = async () => {
    const currentDate = new Date();
    setBudgetFormData({
      ...budgetFormData,
      budget_date: currentDate,
      month: currentDate.getMonth() + 1, // JavaScript months are 0-indexed
      year: currentDate.getFullYear(),
    });
  };

  const getMonthName = (monthNumber) => {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return monthNames[monthNumber - 1]; // Subtract 1 because arrays are 0-indexed
  };

  const capitalize = (str) => {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const formatOptionLabel = ({ value, label, url }) => (
    <div>
      <img
        src={url}
        alt={label}
        style={{ width: '20px', marginRight: '10px' }}
      />
      {capitalize(value.split(/(?=[A-Z])/).join(' '))}
    </div>
  );

  return (
    <React.Fragment>
      <div data-bs-theme={isDarkMode ? 'dark' : 'light'}>
        <div className='container-fluid'>
          <PageTitleBox pageTitle={pageTitle} />
          <div className='row '>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-header align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    Create New Expense
                  </h4>
                  <div className='flex-shrink-0'></div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='card-body'>
                    <div className='live-preview'>
                      <div className='row gy-4'>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            {/* <Input
                              name='expense_name'
                              label='Expense Name'
                              value={expenseData.expense_name}
                              onChange={handleChange}
                              error={errors.expense_name}
                            />*/}
                            <label
                              htmlFor='budget_date'
                              style={{ display: 'block' }}
                            >
                              Select Budget's Month
                            </label>
                            <DatePicker
                              name='budget_date'
                              className='form-control'
                              selected={new Date(budgetFormData.budget_date)}
                              onChange={(date) =>
                                handleChange({
                                  name: 'budget_date',
                                  value: date,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <label
                              htmlFor='is_re_occuring'
                              style={{ display: 'block' }}
                            >
                              Is An Active Budget
                            </label>
                            <div className='form-check form-switch form-switch-right form-switch-md'>
                              <label
                                htmlFor='is_re_occuring'
                                className='form-label text-muted'
                              >
                                {isActiveBudget ? 'Yes' : 'No'}
                              </label>
                              <input
                                className='form-check-input code-switcher'
                                type='checkbox'
                                id='is_re_occuring'
                                name='is_re_occuring'
                                checked={budgetFormData.is_active_budget}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card-footer'>
                      <button
                        disabled={validate()}
                        className='btn btn-success'
                        type='submit'
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className='row '>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-header align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    {getMonthName(budgetFormData.month)} {budgetFormData.year}{' '}
                    Budget Categories
                  </h4>
                  <div className='flex-shrink-0'></div>
                </div>
                <div className='card-body'>
                  <div className='live-preview'>
                    <div className='row gy-4'>
                      <div className='col-xxl-3 col-md-6'>
                        <div>
                          <label>
                            Total Budget Amount:{' '}
                            {budgetCategoriesFormData.reduce(
                              (acc, category) =>
                                acc +
                                Number(category.total_allocated_for_property),
                              0
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='row gy-4'>
                      <div className='col-xxl-3 col-md-6'>
                        <div>
                          <label>Category Name</label>
                          <input
                            type='text'
                            className='form-control'
                            name='category_name'
                            value={newCategory.category_name}
                            onChange={(e) => {
                              setNewCategory({
                                ...newCategory,
                                category_name: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className='col-xxl-3 col-md-6'>
                        <div>
                          <label>Category Icon</label>

                          <Select
                            name='expense_icon'
                            value={
                              iconSelectOptions.find(
                                (option) =>
                                  option.value === newCategory.category_icon
                              ) || null
                            }
                            onChange={(selectedOption) => {
                              setNewCategory({
                                ...newCategory,
                                category_icon: selectedOption
                                  ? selectedOption.value
                                  : '',
                              });
                            }}
                            options={iconSelectOptions}
                            formatOptionLabel={formatOptionLabel}
                          />
                        </div>
                      </div>
                      <div className='col-xxl-3 col-md-6'>
                        <div>
                          <label>Total Allocated For Category</label>
                          <input
                            type='number'
                            className='form-control'
                            name='total_allocated_for_property'
                            value={newCategory.total_allocated_for_property}
                            onClick={(e) => {
                              if (e.target.value === '0') {
                                e.target.value = '';
                              }
                            }}
                            onChange={(e) => {
                              setNewCategory({
                                ...newCategory,
                                total_allocated_for_property: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='card-footer'>
                      <button
                        className='btn btn-primary'
                        onClick={() => {
                          const errors = validateNewCategory();
                          if (errors) {
                            // Show error message
                            for (const error in errors) {
                              if (errors.hasOwnProperty(error)) {
                                toast.error(errors[error]);

                                //console.log(errors[error]);
                              }
                            }
                          } else {
                            // Create a copy of newCategory and add the new properties
                            const newCategoryWithAdditionalProps = {
                              ...newCategory,
                              amount_left:
                                newCategory.total_allocated_for_property,
                              amount_rolled_over_from_previous: 0,
                              budget_category_id: uuidv4(),
                              budget_id: '',
                              month: budgetFormData.month,
                              record_time_stamp: Date.now(),
                              system_budget_cat_id: '',
                              year: budgetFormData.year,
                            };

                            // Add the new category to budgetCategoriesFormData
                            setBudgetCategoriesFormData([
                              ...budgetCategoriesFormData,
                              newCategoryWithAdditionalProps,
                            ]);
                            //set the icon

                            setNewCategory({
                              amount_left: 0,
                              amount_rolled_over_from_previous: 0,
                              budget_category_id: '',
                              budget_id: '',
                              category_icon: '',
                              category_name: '',
                              month: 0,
                              record_time_stamp: 0,
                              system_budget_cat_id: '',
                              total_allocated_for_property: 0,
                              year: 0,
                            });
                          }
                        }}
                      >
                        Add New Category
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row '>
            <div className='budget-categories-grid'>
              {budgetCategoriesFormData &&
                budgetCategoriesFormData.length > 0 &&
                budgetCategoriesFormData[0].category_name !== '' && (
                  <CreateBudgetCategoryGrid
                    updateCategory={updateCategory}
                    deleteCategory={deleteCategory}
                    iconSelectOptions={iconSelectOptions}
                    budget_categories={budgetCategoriesFormData.filter(
                      (category) => category != null
                    )}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateNewBudget;
