///palz/fetch_pal_by_primary_pal_id

import http from "../general/httpService";
import api from "../../constants/config.json";
import config from "../../constants/config.json";

export async function fetchPalByPrimaryPalId() {
    
    const userId = localStorage.getItem(config.user_id);
    const fullApiEndpoint = api.apiEndpoint + `/palz/fetch_pal_by_primary_pal_id`;
    const {data : pal} = await http.post(fullApiEndpoint,{primary_pal_id: userId});
    return pal;
}

///palz/fetch_pal_by_pal_id

export async function fetchPalByPalId(palId) {
    const fullApiEndpoint = api.apiEndpoint + `/palz/fetch_pal_by_pal_id`;
    const {data : pal} = await http.post(fullApiEndpoint,{pal_id: palId});
    return pal;
}

///palz/create_pal
//TODO: Add the pal object to the request body
export async function createPal(pal) {

    const fullApiEndpoint = api.apiEndpoint + `/palz/create_pal`;
    const {data : response} = await http.post(fullApiEndpoint,pal);
    return response;
}

///palz/fetch_pal_by_invite_code
export async function fetchPalByInviteCode(inviteCode) {
    const fullApiEndpoint = api.apiEndpoint + `/palz/get_pal_basic_by_invite_code`;
    const {data : pal} = await http.post(fullApiEndpoint,{invite_code: inviteCode});
    return pal;
}

///palz/fetch_all_pal_requests_by_email
export async function fetchAllPalRequestsByEmail() {
    const email = localStorage.getItem(config.email);
    const fullApiEndpoint = api.apiEndpoint + `/palz/fetch_all_pal_requests_by_email`;
    const {data : palRequests} = await http.post(fullApiEndpoint,{email_address: email});
    return palRequests;
}

///palz/respond_to_pal_request
export async function respondToPalRequest(palRequest) {
    const fullApiEndpoint = api.apiEndpoint + `/palz/respond_to_pal_request`;
    const {data : response} = await http.post(fullApiEndpoint,palRequest);
    return response;
}

///palz/fetch_all_pals_by_secondary_pal_id_who_are_pending"
export async function fetchAllPalsBySecondaryPalIdWhoArePending() {
    const user_id = localStorage.getItem(config.user_id);
    const fullApiEndpoint = api.apiEndpoint + `/palz/fetch_all_pals_by_secondary_pal_id_who_are_pending`;
    const {data : pals} = await http.post(fullApiEndpoint,{user_id : user_id});
    return pals;
}

///palz/fetch_all_pals_by_secondary_pal_id_who_are_rejected
export async function fetchAllPalsBySecondaryPalIdWhoAreRejected() {
    const userId = localStorage.getItem(config.user_id);
    const fullApiEndpoint = api.apiEndpoint + `/palz/fetch_all_pals_by_secondary_pal_id_who_are_rejected`;
    const {data : pals} = await http.post(fullApiEndpoint,{user_id: userId});
    return pals;
}

///palz/delete_pal
export async function deletePal(palId) {
      const userId = localStorage.getItem(config.user_id);
    const fullApiEndpoint = api.apiEndpoint + `/palz/delete_pal`;
    const {data : response} = await http.post(fullApiEndpoint,{pal_id: palId, user_id: userId});
    return response;
}