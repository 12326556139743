import React from 'react';

const TotalSpendCard = ({
  totalSpentPercentageChange,
  totalSpent,
  secondLatestBudgetSummary,
}) => {
  return (
    <>
      {/* card */}
      <div className='card card-animate'>
        <div className='card-body'>
          <div className='d-flex align-items-center'>
            <div className='flex-grow-1'>
              <p className='text-uppercase fw-medium text-muted mb-0'>
                Total Spend this period vs Last period
              </p>
            </div>
            <div className='flex-shrink-0'>
              <h5 className='text-success fs-14 mb-0'>
                {totalSpentPercentageChange > 0 ? (
                  <i className='ri-arrow-up-line fs-13 align-middle'></i>
                ) : (
                  <i className='ri-arrow-down-line fs-13 align-middle'></i>
                )}
                {/* Percent difference using totalSpent and secondLatestBudgetSummary.totalSpent*/}
                {totalSpentPercentageChange}
              </h5>
            </div>
          </div>
          <div className='d-flex align-items-end justify-content-between mt-4'>
            <div>
              <h4 className='fs-22 fw-semibold ff-secondary mb-4'>
                $
                <span className='counter-value' data-target='559.25'>
                  {Number(totalSpent)?.toFixed(2) || 0}
                </span>
              </h4>
            </div>
            <h4 className='fs-22 fw-semibold ff-secondary mb-4'>VS</h4>
            <div>
              <h4 className='fs-22 fw-semibold ff-secondary mb-4'>
                $
                <span className='counter-value' data-target='559.25'>
                  {Number(secondLatestBudgetSummary.totalSpent)?.toFixed(2) ||
                    0}
                </span>
              </h4>
            </div>
            <div className='avatar-sm flex-shrink-0'>
              <span className='avatar-title bg-success-subtle rounded fs-3 material-shadow'>
                <i className='bx bx-dollar-circle text-success'></i>
              </span>
            </div>
          </div>
        </div>
        {/* end card body */}
      </div>
    </>
  );
};

export default TotalSpendCard;
