import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../../../reusable/form/Input';
import { toast } from 'react-toastify';
import Joi from 'joi-browser';
import { iconUrlMap } from '../../../../constants/iconUrlMap';
import PageTitleBox from '../../../reusable/portal/general/pageTitleBox';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { budgetGoalCategories } from '../../../../constants/budgetGoal/budgetGoalCategories';
import config from '../../../../constants/config.json';
import { first, set } from 'lodash';
import { createBudgetGoal } from '../../../../services/budgetGoal/budgetGoalManagement';
import LoaderView from '../../../reusable/loader/loaderView';

//need a budget goal options

const CreateNewBudgetGoal = ({ isDarkMode }) => {
  const pageTitle = 'Create New Budget Goal';
  const navigate = useNavigate();
  const [budgetGoalFormData, setBudgetGoalFormData] = useState({
    goal_date: new Date(), //not stored in the database
    ai_insight_msg: '',
    bank_account_id: '',
    budget_goal_id: '',
    budget_goal_sum_total: 0,
    current_amt_saved: 0,
    goal_category_icon: '',
    goal_creator_profile: {},
    goal_name: '',
    goal_type: '',
    has_achieved_goal: false,
    is_deleted: false,
    month: 0,
    pals_working_together_towards_goal: [],
    record_time_stamp: 0,
    target_end_date_raw_date: 0,
    total_left: 0,
    user_id: '',
    year: 0,
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const schema = {
    goal_date: Joi.date().optional().allow('').label('Goal Date'),
    ai_insight_msg: Joi.string()
      .optional()
      .allow('')
      .label('AI Insight Message'),
    bank_account_id: Joi.string().optional().allow('').label('Bank Account ID'),
    budget_goal_id: Joi.string().optional().allow('').label('Budget Goal ID'),
    budget_goal_sum_total: Joi.number()
      .required()
      .label('Budget Goal Sum Total'),
    current_amt_saved: Joi.number().required().label('Current Amount Saved'),
    goal_category_icon: Joi.string()
      .optional()
      .allow('')
      .label('Goal Category Icon'),
    goal_creator_profile: Joi.object()
      .optional()
      .allow('')
      .label('Goal Creator Profile'),
    goal_name: Joi.string().required().label('Goal Name'),
    goal_type: Joi.string().optional().allow('').label('Goal Type'),
    has_achieved_goal: Joi.boolean()
      .optional()
      .allow('')
      .label('Has Achieved Goal'),
    is_deleted: Joi.boolean().optional().allow('').label('Is Deleted'),
    month: Joi.number().required().label('Month'),
    pals_working_together_towards_goal: Joi.array()
      .optional()
      .allow('')
      .label('Pals Working Together Towards Goal'),
    record_time_stamp: Joi.number()
      .optional()
      .allow('')
      .label('Record Time Stamp'),
    target_end_date_raw_date: Joi.number()
      .optional()
      .allow('')
      .label('Target End Date Raw Date'),
    total_left: Joi.number().optional().allow('').label('Total Left'),
    user_id: Joi.string().optional().allow('').label('User ID'),
    year: Joi.number().required().label('Year'),
  };

  const [iconSelectOptions, setIconSelectOptions] = useState([]);

  const getIconOptions = () => {
    const options = Object.keys(iconUrlMap).map((key) => {
      const { val, url } = iconUrlMap[key];
      return { value: key, label: val, url };
    });
    setIconSelectOptions(options);
  };

  //form hanling
  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(budgetGoalFormData, schema, options);
    if (!error) return null;

    const errors = {};

    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaLocal);

    return error ? error.details[0].message : null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //get the values from the form
    // var emailAddress = this.emailAddress.current.value;
    // var password = document.getElementById('txtPassword').value;
    //call the server to save the changes

    const errors = validate();

    for (const error in errors) {
      if (errors.hasOwnProperty(error)) {
        toast.error(errors[error]);
      }
    }
    setErrors({ errors: errors || {} });
    if (errors) return;

    doSubmit();
  };

  const handleChange = (input) => {
    const currentErrors = { ...errors };
    let name, value;

    // Check if input is an event object, a date string, or a selected option object
    if (input && input.currentTarget) {
      name = input.currentTarget.name;
      value = input.currentTarget.value;
    } else if (input && input.value) {
      if (typeof input.value === 'string') {
        name = 'goal_date';
        value = input.value;

        // Parse the date string into a Date object
        const date = new Date(value);

        // Set the month, year, and target_end_date_raw_date fields
        budgetGoalFormData.month = date.getMonth() + 1; // getMonth() returns a zero-based month, so add 1
        budgetGoalFormData.year = date.getFullYear();
        budgetGoalFormData.target_end_date_raw_date = date.getTime(); // getTime() returns the timestamp in milliseconds
      }
    }

    if (input && input.value && input.label) {
      // Handle the selected option object from the Select component
      name = 'goal_category_icon';
      value = input.value;
      //console.log('Selected Option: ', value);

      setSelectedOptionBudgetGoalIcon(input);
    }

    const errorMessage = validateProperty({ name, value });
    if (errorMessage) currentErrors[name] = errorMessage;
    else delete currentErrors[name];

    const data = { ...budgetGoalFormData };
    data[name] = value;

    setBudgetGoalFormData(data);
    setErrors(currentErrors);
  };

  const doSubmit = async () => {
    try {
      setIsLoading(true);
      //set the user id etc
      budgetGoalFormData.user_id = localStorage.getItem(config.user_id);
      budgetGoalFormData.record_time_stamp = Number(Date.now());
      budgetGoalFormData.budget_goal_id = uuidv4();
      budgetGoalFormData.is_deleted = false;
      budgetGoalFormData.has_achieved_goal = false;
      budgetGoalFormData.ai_insight_msg = '';
      budgetGoalFormData.bank_account_id = '';
      budgetGoalFormData.goal_creator_profile = {
        creator_profile_id: uuidv4(),
        user_id: localStorage.getItem(config.user_id),
        first_name: localStorage.getItem(config.first_name),
        last_name: localStorage.getItem(config.last_name),
        email_address: localStorage.getItem(config.email),
        profile_img: localStorage.getItem(config.profile_img),
        time_stamp: Number(Date.now()),
        modified_time_stamp: Number(Date.now()),
      };
      budgetGoalFormData.pals_working_together_towards_goal = [];
      budgetGoalFormData.budget_goal_sum_total = Number(
        budgetGoalFormData.budget_goal_sum_total
      );
      budgetGoalFormData.current_amt_saved = Number(
        budgetGoalFormData.current_amt_saved
      );
      budgetGoalFormData.month = Number(budgetGoalFormData.month);
      budgetGoalFormData.total_left =
        Number(budgetGoalFormData.budget_goal_sum_total) -
        Number(budgetGoalFormData.current_amt_saved);
      budgetGoalFormData.year = Number(budgetGoalFormData.year);
      budgetGoalFormData.target_end_date_raw_date = Number(
        budgetGoalFormData.target_end_date_raw_date
      );

      //console.log('Budget Goal Form Data: ', budgetGoalFormData);

      const response = await createBudgetGoal(budgetGoalFormData);
      //console.log('Response: ', response);
      if (response) {
        setIsLoading(false);
        toast.success('Budget Goal created successfully');
        navigate('/allBudgetGoals');
      } else {
        setIsLoading(false);
        toast.error('Failed to create Budget Goal');
      }
    } catch (error) {
      setIsLoading(false);
      //console.log('Error: ', error);
    }
  };

  const [selectedOptionBudgetGoalType, setSelectedOptionBudgetGoalType] =
    useState(null);
  const handleBudgetGoalChangeType = (option) => {
    setSelectedOptionBudgetGoalType(option);
    budgetGoalFormData.goal_type = option.value;
  };

  const [selectedOptionBudgetGoalIcon, setSelectedOptionBudgetGoalIcon] =
    useState(null);

  //remove later if not used
  const handleBudgetGoalChangeIcon = (_selectedBudgetGoalIconOption) => {
    setSelectedOptionBudgetGoalIcon(_selectedBudgetGoalIconOption);
    handleChange({
      target: {
        name: 'goal_category_icon',
        value: _selectedBudgetGoalIconOption
          ? _selectedBudgetGoalIconOption.value
          : '',
      },
    });
  };

  const capitalize = (str) => {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  const formatOptionLabel = ({ value, label, url }) => (
    <div>
      <img
        src={url}
        alt={label}
        style={{ width: '20px', marginRight: '10px' }}
      />
      {capitalize(value.split(/(?=[A-Z])/).join(' '))}
    </div>
  );
  const selectedIconOption = iconSelectOptions.find(
    (option) => option.value === budgetGoalFormData.goal_category_icon
  );

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    getIconOptions();

    setIsLoading(false);
  }, []);

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}
      {/*/Loader */}
      <div data-bs-theme={isDarkMode ? 'dark' : 'light'}>
        <div className='container-fluid'>
          <PageTitleBox pageTitle={pageTitle} />
          <div className='row '>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-header align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    New Budget Goal
                  </h4>
                  <div className='flex-shrink-0'></div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='card-body'>
                    <div className='live-preview'>
                      <div className='row gy-4'>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='goal_name'
                              label='Goal Name'
                              value={budgetGoalFormData.goal_name}
                              onChange={handleChange}
                              error={errors.goal_name}
                            />
                          </div>
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <label htmlFor='form-grid-category'>
                              Goal Type
                            </label>
                            <Select
                              value={selectedOptionBudgetGoalType}
                              onChange={handleBudgetGoalChangeType}
                              options={budgetGoalCategories}
                            />
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='budget_goal_sum_total'
                              label='Budget Goal Sum Total'
                              type={'number'}
                              value={budgetGoalFormData.budget_goal_sum_total}
                              onChange={handleChange}
                              error={errors.budget_goal_sum_total}
                            />
                          </div>
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='current_amt_saved'
                              label='Amount Already Saved'
                              type={'number'}
                              value={budgetGoalFormData.current_amt_saved}
                              onChange={handleChange}
                              error={errors.current_amt_saved}
                            />
                          </div>
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <label htmlFor='form-grid-category'>
                              Goal Icon
                            </label>

                            <Select
                              name='goal_category_icon'
                              value={selectedIconOption}
                              onChange={handleChange}
                              options={iconSelectOptions}
                              formatOptionLabel={formatOptionLabel}
                            />
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='goal_date'
                              className='form-control'
                              label={'Select Goal Deadline'}
                              type='date'
                              selected={new Date(budgetGoalFormData.goal_date)} // Convert timestamp to 'YYYY-MM-DD'
                              error={errors.target_end_date_raw_date}
                              onChange={(date) =>
                                handleChange({
                                  name: 'goal_date',
                                  value: date,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card-footer'>
                      <button
                        disabled={validate()}
                        className='btn btn-success'
                        type='submit'
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateNewBudgetGoal;
