import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Calendar, Badge } from 'rsuite';
import 'rsuite/Calendar/styles/index.css';
import { fetchExpensesAndTransactionsByUserPalId } from '../../../../services/expenses/expenseServiceManagement';
import LoaderView from '../../../reusable/loader/loaderView';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

const ExpenseCalendarView = () => {
  const [isLoading, setIsLoading] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalItems, setModalItems] = useState([]);
  const date = new Date();
  const month = date.toLocaleString('default', {
    month: 'long',
  });
  const year = date.getFullYear();
  //look at pagination
  const [expensesAndTransactions, setExpensesAndTransactions] = useState([
    {
      account_id: '',
      day_due_each_month: 0,
      expense_amt: 0,
      expense_category: '',
      expense_description: '',
      expense_icon: '',
      expense_id: '',
      expense_name: '',
      has_insight: false,
      is_re_occuring: false,
      record_time_stamp: 0,
      transactions: [
        {
          history_id: '',
          expense_id: '',
          investment_id: '',
          history_description: '',
          saving_acc_id: '',
          account_id: '',
          amount: 0,
          budget_amount_left: 0,
          budget_category_name: '',
          budget_category_selected_id: '',
          currency: '',
          currency_interest: 0,
          expense_image_scans: null,
          history_day: 0,
          history_month: 0,
          history_type: '',
          history_year: 0,
          is_deleted: false,
          time_stamp: 0,
          total_allocated_for_category: 0,
        },
      ],
      user_pal_id: '',
    },
  ]);

  const getTheLatestExpenses = async () => {
    //get the latest expenses
    const expensesAndTransactions =
      await fetchExpensesAndTransactionsByUserPalId();

    setExpensesAndTransactions(expensesAndTransactions);

    //console.log('Expenses and Transactions');
    // console.log(expensesAndTransactions);

    if (expensesAndTransactions) {
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);

    //get the latest expenses
    getTheLatestExpenses();
  }, []);

  function getTodoList(date, expensesAndTransactions) {
    const day = date.getDate();

    // Filter the expenses and transactions that are due on the given day
    const todaysExpensesAndTransactions = expensesAndTransactions.filter(
      (item) => item.day_due_each_month === day
    );

    // Map over the filtered expenses and transactions to create the list of events
    const list = todaysExpensesAndTransactions.map((item) => ({
      expenseId: item.expense_id,
      dayDueEachMonth: `${item.day_due_each_month}`,
      expenseName: item.expense_name,
      expenseAmt: item.expense_amt,
      expenseDescription: item.expense_description,
      budgetCategoryId:
        item.transactions && item.transactions.length > 0
          ? item.transactions[item.transactions.length - 1]
              .budget_category_selected_id
          : undefined,
    }));

    return list;
  }

  function renderCell(date) {
    const list = getTodoList(date, expensesAndTransactions);
    const displayList = list.filter((item, index) => index < 2);

    if (list.length) {
      const moreCount = list.length - displayList.length;
      const moreItem = (
        <div>
          <Button
            variant='link'
            onClick={() => {
              setShowModal(true);
              setModalItems(list);
            }}
          >
            <b>{moreCount} more</b>
          </Button>
        </div>
      );

      return (
        <React.Fragment>
          <div>
            {displayList.map((item, index) => (
              <div key={index}>
                <Badge />{' '}
                <b
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {item.expenseName}
                </b>
              </div>
            ))}
            {moreCount ? moreItem : null}
          </div>
        </React.Fragment>
      );
    }

    return null;
  }

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}
      {/*/Loader */}

      <Modal
        key={showModal}
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Body>
          <div
            className='modal-header p-3 bg-info-subtle'
            style={{ padding: '0 !important' }}
          >
            <h5 className='modal-title' id='modal-title'>
              Expense{modalItems.length > 1 ? 's' : ''} Due on the{' '}
              {modalItems.length > 0 ? modalItems[0]?.dayDueEachMonth : ''}
            </h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-hidden='true'
              onClick={() => setShowModal(false)}
            ></button>
          </div>
        </Modal.Body>
        <Modal.Body>
          <div className='modal-content border-0'>
            <div className='modal-body p-4'>
              <form
                className='needs-validation view-event'
                name='event-form'
                id='form-event'
                noValidate=''
              >
                {modalItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <div key={index} className='card p-3 '>
                      <div className='title'>
                        <h5 className='text-start d-block fw-semibold mb-0'>
                          <b>{item.expenseName}</b>
                        </h5>
                        <div className='text-end'>
                          {item.budgetCategoryId ? (
                            <Link
                              to={`/budgetRelatedExpenses/${item.budgetCategoryId}`}
                              className='btn btn-sm btn-soft-primary'
                              id='edit-event-btn'
                              data-id='edit-event'
                              onClick='editEvent(this)'
                              role='button'
                            >
                              Detail
                            </Link>
                          ) : (
                            <span>Detail</span>
                          )}
                        </div>
                      </div>
                      <div className='event-details'>
                        <div className='d-flex mb-2'>
                          <div className='flex-grow-1 d-flex align-items-center'>
                            <div className='flex-shrink-0 me-3'>
                              <i className='ri-calendar-event-line text-muted fs-16'></i>
                            </div>
                            <div className='flex-grow-1'>
                              <h6
                                className='d-block fw-semibold mb-0'
                                id='event-start-date-tag'
                              >
                                {`${item.dayDueEachMonth} ${month} ${year}`}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex align-items-center mb-2'>
                          <div className='flex-shrink-0 me-3'>
                            <i className='ri-money-dollar-circle-line text-muted fs-16'></i>
                          </div>
                          <div className='flex-grow-1'>
                            <h6 className='d-block fw-semibold mb-0'>
                              <span id='event-timepicker1-tag'>
                                {'$'}
                                {parseFloat(Number(item.expenseAmt)).toFixed(2)}
                              </span>
                            </h6>
                          </div>
                        </div>

                        {item.expenseDescription && (
                          <div className='d-flex mb-3'>
                            <div className='flex-shrink-0 me-3'>
                              <i className='ri-discuss-line text-muted fs-16'></i>
                            </div>
                            <div className='flex-grow-1'>
                              <p
                                className='d-block text-muted mb-0'
                                id='event-description-tag'
                              >
                                {item.expenseDescription}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/*<Button onClick={() => setShowModal(false)}>Close</Button>*/}
        </Modal.Footer>
      </Modal>

      {/* ============================================================== */}
      {/* Start right Content here */}
      {/* ============================================================== */}

      <div className='row pt-4 px-5'>
        <div className='col-12'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent'>
            <div className='col-md-3'>
              <h4 className='mb-sm-0'>Expense Calendar</h4>
            </div>
          </div>
        </div>
      </div>

      <div className='row pt-4 px-5'>
        <div className='card card-h-100'>
          <div className='card-body'>
            <Calendar bordered renderCell={renderCell} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExpenseCalendarView;
