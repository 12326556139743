import React from 'react';

const BasicStatCard = ({
  title,
  percentage,
  total,
  icon,
  isMonetary = true,
}) => (
  <div className='card card-animate'>
    <div className='card-body'>
      <div className='d-flex align-items-center'>
        <div className='flex-grow-1'>
          <p className='text-uppercase fw-medium text-muted mb-0'>{title}</p>
        </div>
        <div className='flex-shrink-0' hidden>
          <h5 className='text-muted fs-14 mb-0'>
            {isNaN(Number(percentage)) ? '0.00' : Number(percentage).toFixed(2)}
          </h5>
        </div>
      </div>
      <div className='d-flex align-items-end justify-content-between mt-4'>
        <div>
          <h4 className='fs-22 fw-semibold ff-secondary mb-4'>
            {isMonetary ? '$' : ''}
            <span className='counter-value'>
              {isNaN(Number(total)) ? '0.00' : Number(total).toFixed(2)}
            </span>
          </h4>
        </div>
        <div className='avatar-sm flex-shrink-0'>
          <span className='avatar-title bg-primary-subtle rounded fs-3 material-shadow'>
            <i className={icon}></i>
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default BasicStatCard;
