import React, { useState, useEffect } from 'react';
import {
  cancelSubscription,
  getActiveSubscriptionsFromStripe,
} from '../../../../services/subscription/subscriptionManagementService';
import { toast } from 'react-toastify';
import { capitalizeFirstLetterOfEachWord } from '../../../../services/utilis/stringUtils';
import logo from '../../../../styletheme/logos/savingpalz_logo.png';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../../services/utilis/dateUtils';

//Need to show customers when their plan expires etc
const ManageSubscription = ({ isDarkMode }) => {
  //next need to add an are you sure button to the
  const [isLoading, setIsLoading] = useState();
  const companyName = 'SavingPalz';
  const companyStreetAddress = 'PO BOX 543';
  const companyTownState = 'AUBURN, Maine';
  const companyCountry = 'United States';
  const companyZipCode = '04212';
  const companyPhoneNumber = '+1 207-659-3879';
  const companyEmail = 'support@gurenter.com';
  const companyWebsite = 'https://savingpalz.com';

  const redirectUrl = process.env.REACT_APP_STRIPE_MANAGE_BILLING_URL;

  const [subscription, setSubscription] = useState({
    id: '',
    object: '',
    application: '',
    application_fee_percent: '',
    automatic_tax: {
      enabled: true,
      liability: {
        type: '',
      },
    },
    billing_cycle_anchor: '',
    billing_cycle_anchor_config: '',
    billing_thresholds: '',
    cancel_at: '',
    cancel_at_period_end: false,
    canceled_at: '',
    cancellation_details: {
      comment: '',
      feedback: '',
      reason: '',
    },
    collection_method: '',
    created: '',
    currency: '',
    current_period_end: '',
    current_period_start: '',
    customer: '',
    days_until_due: '',
    default_payment_method: '',
    default_source: '',
    default_tax_rates: [],
    description: '',
    discount: '',
    discounts: [],
    ended_at: '',
    invoice_settings: {
      account_tax_ids: '',
      issuer: {
        type: '',
      },
    },
    items: {
      object: '',
      data: [
        {
          id: '',
          object: '',
          billing_thresholds: '',
          created: '',
          discounts: [],
          metadata: {},
          plan: {
            id: '',
            object: '',
            active: true,
            aggregate_usage: '',
            amount: '',
            amount_decimal: '',
            billing_scheme: '',
            created: '',
            currency: '',
            interval: '',
            interval_count: 1,
            livemode: false,
            metadata: {},
            meter: '',
            nickname: '',
            product: '',
            tiers_mode: '',
            transform_usage: '',
            trial_period_days: '',
            usage_type: '',
          },
          price: {
            id: '',
            object: '',
            active: true,
            billing_scheme: '',
            created: '',
            currency: '',
            custom_unit_amount: '',
            livemode: false,
            lookup_key: '',
            metadata: {},
            nickname: '',
            product: '',
            recurring: {
              aggregate_usage: '',
              interval: '',
              interval_count: 1,
              meter: '',
              trial_period_days: '',
              usage_type: '',
            },
            tax_behavior: '',
            tiers_mode: '',
            transform_quantity: '',
            type: '',
            unit_amount: '',
            unit_amount_decimal: '',
          },
          quantity: 1,
          subscription: '',
          tax_rates: [],
        },
      ],
      has_more: false,
      total_count: 1,
      url: '',
    },
    latest_invoice: '',
    livemode: false,
    metadata: {},
    next_pending_invoice_item_invoice: '',
    on_behalf_of: '',
    pause_collection: '',
    payment_settings: {
      payment_method_options: {
        acss_debit: '',
        bancontact: '',
        card: {
          network: '',
          request_three_d_secure: 'automatic',
        },
        customer_balance: '',
        konbini: '',
        sepa_debit: '',
        us_bank_account: '',
      },
      payment_method_types: '',
      save_default_payment_method: 'off',
    },
    pending_invoice_item_interval: '',
    pending_setup_intent: '',
    pending_update: '',
    plan: {
      id: '',
      object: '',
      active: true,
      aggregate_usage: '',
      amount: '',
      amount_decimal: '',
      billing_scheme: '',
      created: '',
      currency: '',
      interval: '',
      interval_count: 1,
      livemode: false,
      metadata: {},
      meter: '',
      nickname: '',
      product: '',
      tiers_mode: '',
      transform_usage: '',
      trial_period_days: '',
      usage_type: '',
    },
    quantity: 1,
    schedule: '',
    start_date: '',
    status: 'active',
    test_clock: '',
    transfer_data: '',
    trial_end: '',
    trial_settings: {
      end_behavior: {
        missing_payment_method: 'create_invoice',
      },
    },
    trial_start: '',
  });

  //TODO: get the current subscription information so it can be used to ancle or update billing info (put update on another view, updating payment as well)
  useEffect(() => {
    //get active subscriptions from stripe
    fetchActiveSubscriptionFromStripe();
  }, []);

  const fetchActiveSubscriptionFromStripe = async () => {
    setIsLoading(true);

    try {
      const activeSubscriptions = await getActiveSubscriptionsFromStripe();
      //console.log('Active Subscriptions from Stripe');
      //console.log(activeSubscriptions);
      setSubscription(activeSubscriptions);
      setIsLoading(false);
    } catch (ex) {
      //console.log(ex);
      setIsLoading(false);
    }
  };

  const cancelStripeSubscription = async () => {
    // cancel subscription logic here
    //show the are you sure
    // alert(subscription.id);
    //console.log(subscription);
    if (!subscription) {
      toast.error(
        "Can't cancel, because there is no active subscription found."
      );
      return;
    }

    const subscriptionId = subscription.id;

    const response = await cancelSubscription(subscriptionId);

    //console.log('Cancel Subscription Detail');
    //console.log(response);
    //make the toast persist till they manually close it
    toast.success(
      `${capitalizeFirstLetterOfEachWord(
        response.status
      )} successfully. You will have access to the Subscription benefits and features until the end of your billing cycle.`
    );
  };

  return (
    <React.Fragment>
      <div className='row justify-content-center'>
        <div className='col-12 col-xl-12'>
          <div className='card'>
            <div className='card-header'>
              <div className='row'>
                <div className='col-12 col-lg-4'>
                  <div className='flex-grow-1'>
                    <img
                      src={logo}
                      className='card-logo card-logo-dark'
                      alt='logo dark'
                      height='60'
                    />
                    <img
                      src={logo}
                      className='card-logo card-logo-light'
                      alt='logo light'
                      height='60'
                    />
                    <div className='row justify-content-between'>
                      <div className='col-lg-6 '>
                        <div className='mt-sm-2 mt-2'>
                          {' '}
                          {/* Adjusted margin-top classes */}
                          <h6 className='text-muted text-uppercase fw-semibold'>
                            Company Address
                          </h6>
                          <p className='text-muted mb-1' id='address-details'>
                            {companyStreetAddress}
                          </p>
                          <p className='text-muted mb-1' id='address-details'>
                            {companyTownState}
                          </p>
                          <p className='text-muted mb-1' id='address-details'>
                            {companyCountry}
                          </p>
                          <p className='text-muted mb-0' id='zip-code'>
                            <span>Zip Code:</span> {companyZipCode}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-lg-4'>
                  {/* Empty column for alignment */}
                </div>
                <div className='col-12 col-lg-4 text-lg-end'>
                  <h4>
                    Start: {formatDate(subscription.current_period_start)}
                  </h4>
                  <h4>End: {formatDate(subscription.current_period_end)}</h4>
                </div>
              </div>
            </div>
            <div className='card-body'>
              <div className='row'>
                <div className='col-12 col-lg-4'>
                  <h5>Current Period</h5>
                  <p>Start: {formatDate(subscription.current_period_start)}</p>
                  <p>End: {formatDate(subscription.current_period_end)}</p>
                  <p>
                    Status:{' '}
                    <span
                      className={
                        subscription.status === 'active'
                          ? 'badge bg-success'
                          : 'badge bg-warning'
                      }
                    >
                      {subscription.status}
                    </span>
                  </p>
                </div>
                <div className='col-12 col-lg-4 text-center'></div>
                <div className='col-12 col-lg-4 text-lg-end'>
                  <h5>Other Details</h5>
                  <p>Currency: {subscription.currency.toUpperCase()}</p>
                  <p>Amount: ${(subscription?.plan.amount / 100).toFixed(2)}</p>
                  <p>Plan Interval: {subscription.plan.interval}</p>
                </div>
              </div>
              <div className='row mt-4'>
                <div className='col-12 col-lg-4'>
                  <button
                    onClick={() => {
                      cancelStripeSubscription();
                    }}
                    className='btn btn-danger'
                  >
                    <i className='ri-delete-bin-line align-bottom me-1'></i>
                    Cancel Subscription
                  </button>
                </div>
                <div className='col-12 col-lg-4'>
                  {/* Empty column for alignment */}
                </div>
                <div className='col-12 col-lg-4 text-lg-end'>
                  {/*Need to come for .env */}
                  <a
                    href={process.env.REACT_APP_STRIPE_MANAGE_BILLING_URL}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='btn btn-success'
                  >
                    <i className='ri-delete-bin-line align-bottom me-1'></i>
                    Update Payment
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManageSubscription;
