import React, { useState } from 'react';
import { getIconUrl } from '../../../../constants/iconUrlMap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { base64ImageString } from '../../../../constants/images/defaultImgs';

const BudgetCardGrid = ({ latestBudget, storedUserId }) => {
  const [sortOption, setSortOption] = useState('Category Name');

  const sortBudgetCategories = () => {
    let sortedCategories;
    switch (sortOption) {
      case 'Category Name':
        sortedCategories = [...latestBudget.budget_categories].sort((a, b) =>
          a.category_name.localeCompare(b.category_name)
        );
        break;
      case 'Amount Budgeted':
        sortedCategories = [...latestBudget.budget_categories].sort(
          (a, b) =>
            a.total_allocated_for_property - b.total_allocated_for_property
        );
        break;
      case 'Amount Left':
        sortedCategories = [...latestBudget.budget_categories].sort(
          (a, b) => a.amount_left - b.amount_left
        );
        break;
      case 'Amount Spent':
        sortedCategories = [...latestBudget.budget_categories].sort(
          (a, b) =>
            a.total_allocated_for_property -
            a.amount_left -
            (b.total_allocated_for_property - b.amount_left)
        );
        break;
      default:
        sortedCategories = latestBudget.budget_categories;
    }
    return sortedCategories;
  };

  const sortedBudgetCategories = sortBudgetCategories();

  return (
    <>
      <div className='row pt-3 px-5'>
        <div className='col-12'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent'>
            <h4 className='mb-sm-0'>
              {' '}
              {new Date(0, latestBudget.month - 1).toLocaleString('default', {
                month: 'long',
              })}{' '}
              Budget
            </h4>

            <div className='flex-shrink-0'>
              <NavDropdown title={`Sort by: ${sortOption}`} id='nav-dropdown'>
                <NavDropdown.Item
                  onClick={() => setSortOption('Category Name')}
                >
                  Category Name
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => setSortOption('Amount Budgeted')}
                >
                  Amount Budgeted
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setSortOption('Amount Left')}>
                  Amount Left
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setSortOption('Amount Spent')}>
                  Amount Spent
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
        </div>
      </div>

      <div className='row px-4'>
        {sortedBudgetCategories.map((category, index) => {
          const amountBudgeted = category.total_allocated_for_property || 0;
          const amountLeft = category.amount_left || 0;
          const amountSpent = amountBudgeted - amountLeft;
          const progress =
            amountBudgeted !== 0 ? (amountSpent / amountBudgeted) * 100 : 0;

          let barColor = 'bg-secondary';
          if (progress >= 95) {
            barColor = 'bg-danger';
          } else if (progress >= 80) {
            barColor = 'bg-warning';
          } else {
            barColor = 'bg-success';
          }

          return (
            <div key={index} className='col-xl-3'>
              <div className='card card-height-100'>
                <div className='card-body p-0'>
                  <ul className='list list-group list-group-flush mb-0'>
                    <li className='list-group-item' data-id='01'>
                      <div className='d-flex align-items-start'>
                        <div className='flex-shrink-0 me-3'>
                          <div>
                            <img
                              className='image avatar-xs rounded-circle'
                              alt=''
                              src={getIconUrl(category.category_icon).url}
                            />
                          </div>
                        </div>

                        <div className='flex-grow-1'>
                          <div className='d-flex justify-content-between align-items-start'>
                            <div className='overflow-hidden'>
                              <h5 className='contact-name fs-13 mb-1'>
                                {/*go to budgetRelatedExpenses */}
                                <Link
                                  to={`/budgetRelatedExpenses/${category.budget_category_id}`}
                                  className='link text-body'
                                >
                                  {category.category_name}
                                </Link>
                              </h5>
                            </div>

                            <div className='d-flex flex-column align-items-end'>
                              {category.isMerged ? (
                                <div className='d-flex'>
                                  <div className='flex-shrink-0 ms-2'>
                                    <img
                                      src={`data:image/png;base64,${
                                        category.pal_profile_image ||
                                        base64ImageString
                                      }`}
                                      alt='Profile'
                                      className='avatar-title bg-info-subtle rounded-circle fs-2'
                                      style={{ width: '25px', height: '25px' }}
                                    />
                                    <div className='mt-0'>
                                      {`${category.pal_first_name?.[0] || ''} ${
                                        category.pal_last_name?.[0] || ''
                                      }`}
                                    </div>
                                  </div>
                                  <div className='flex-shrink-0 ms-2'>
                                    <img
                                      src={`data:image/png;base64,${
                                        category.requestor_profile_image ||
                                        base64ImageString
                                      }`}
                                      alt='Profile'
                                      className='avatar-title bg-info-subtle rounded-circle fs-2'
                                      style={{ width: '25px', height: '25px' }}
                                    />
                                    <div className='mt-0'>
                                      {`${
                                        category.requestor_first_name?.[0] || ''
                                      } ${
                                        category.requestor_last_name?.[0] || ''
                                      }`}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className='flex-shrink-0 ms-2'>
                                  {category.pal_first_name ||
                                  category.pal_last_name ||
                                  category.pal_profile_image ||
                                  category.requestor_first_name ||
                                  category.requestor_last_name ||
                                  category.requestor_profile_image ? (
                                    <div>
                                      <img
                                        src={`data:image/png;base64,${
                                          category.pal_profile_image ||
                                          base64ImageString
                                        }`}
                                        alt='Profile'
                                        className='avatar-title bg-info-subtle rounded-circle fs-2'
                                        style={{
                                          width: '25px',
                                          height: '25px',
                                        }}
                                      />
                                      <div className='mt-0'>
                                        {category.user_pal_id ===
                                        category.primary_pal_id
                                          ? `${
                                              category.pal_first_name?.[0] ||
                                              category
                                                .requestor_first_name?.[0] ||
                                              ''
                                            } ${
                                              category.pal_last_name?.[0] ||
                                              category
                                                .requestor_last_name?.[0] ||
                                              ''
                                            }`
                                          : `${
                                              category
                                                .requestor_first_name?.[0] ||
                                              category.pal_first_name?.[0] ||
                                              ''
                                            } ${
                                              category
                                                .requestor_last_name?.[0] ||
                                              category.pal_last_name?.[0] ||
                                              ''
                                            }`}
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      className='fs-5 text'
                                      style={{ fontWeight: 'bold' }}
                                    >
                                      $
                                      {amountSpent &&
                                        `${amountSpent.toFixed(2)}`}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className='overflow-hidden'>
                            <div className='no-padding card-body '>
                              <div className='d-flex justify-content-between '>
                                <div>
                                  <h6 className='mb-0'>
                                    <b className='text'>
                                      ${Number(amountSpent)?.toFixed(2)}
                                    </b>{' '}
                                    Spent
                                  </h6>
                                </div>
                                <div>
                                  <h6 className='mb-0'>
                                    ${Number(amountLeft)?.toFixed(2)} Left
                                  </h6>
                                </div>
                                <div>
                                  <h6 className='mb-0'>
                                    ${Number(amountBudgeted)?.toFixed(2)}{' '}
                                    Budgeted
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className='progress bg-secondary-subtle rounded-0'>
                              <div
                                className={`progress-bar ${barColor}`}
                                role='progressbar'
                                style={{ width: `${progress}%` }}
                                aria-valuenow={progress}
                                aria-valuemin='0'
                                aria-valuemax='100'
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* end cardbody */}
              </div>
              {/* end card */}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default BudgetCardGrid;
