import React from 'react';
import { Link } from 'react-router-dom';
import { getIconUrl } from '../../../../../constants/iconUrlMap';

const BudgetGoalCard = ({
  goal,
  amountLeft,
  progress,
  barColor,
  isModifyMode = false,
}) => {
  return (
    <div className='d-flex align-items-start'>
      <div className='flex-shrink-0 me-3'>
        <div>
          <img
            className='image avatar-xs rounded-circle'
            alt=''
            src={getIconUrl(goal.goal_category_icon).url}
          />
        </div>
      </div>

      <div className='flex-grow-1'>
        <div className='d-flex justify-content-between align-items-start'>
          <div className='overflow-hidden'>
            <h5 className='contact-name fs-13 mb-1'>
              <Link
                to={`/budgetGoal/${goal.budget_goal_id}`}
                className='link text-body'
              >
                {goal.goal_name}
              </Link>
            </h5>
          </div>

          <div className='flex-shrink-0 ms-2'>
            <div className='fs-5 text' style={{ fontWeight: 'bold' }}>
              {'$'} {Number(goal.current_amt_saved)?.toFixed(2)}
            </div>
          </div>
        </div>

        <div className='overflow-hidden'>
          <div className='no-padding card-body '>
            <div className='d-flex justify-content-between '>
              <div>
                <h6 className='mb-0'>
                  Saved{' '}
                  <b className='text'>
                    ${Number(goal.current_amt_saved)?.toFixed(2)}
                  </b>{' '}
                </h6>
              </div>
              <div>
                <h6 className='mb-0'>${Number(amountLeft)?.toFixed(2)} Left</h6>
              </div>
              <div>
                <h6 className='mb-0'>
                  Goal {''} ${Number(goal.budget_goal_sum_total)?.toFixed(2)}
                </h6>
              </div>
            </div>
          </div>
          <div className='progress bg-secondary-subtle rounded-0'>
            <div
              className={`progress-bar ${barColor}`}
              role='progressbar'
              style={{ width: `${progress}%` }}
              aria-valuenow={progress}
              aria-valuemin='0'
              aria-valuemax='100'
            ></div>
          </div>
          {isModifyMode && (
            <div className='row'>
              <div className='col-xl-3 py-3'>
                <Link
                  className='btn btn-sm btn-secondary'
                  to={`/editBudgetGoal/${goal.budget_goal_id}`}
                >
                  Edit Goal
                </Link>
              </div>
              <div className='col-xl-3 py-3'>
                <Link
                  className='btn btn-sm btn-success'
                  to={`/addBudgetGoalFunds/${goal.budget_goal_id}`}
                >
                  Add Funds
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BudgetGoalCard;
