import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import loader from '../../../../styletheme/images/loading/loader.svg';
import ExpenseTransactionGrid from '../../../reusable/cards/expense/expenseTransansactionGrid';
import { fetchExpensesByBudgetCategorySelectedId } from '../../../../services/expenses/expenseServiceManagement';
import ExpenseTransactionGraph from '../../../reusable/cards/expense/graphs/expenseTransactionGraph';
import LoaderView from '../../../reusable/loader/loaderView';
///expense/fetch_expenses_by_budget_category_selected_id
//budget_category_selected_id
const BudgetRelatedExpenses = ({ isDarkMode }) => {
  const { categoryId } = useParams();
  //budgetCategoryId
  const [budgetCategoryId, setBudgetCategoryId] = useState(categoryId);

  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    fetchRelatedTransactions();
  }, [budgetCategoryId]);

  const [transactions, setTransactions] = useState([
    {
      history_id: '',
      expense_id: '',
      investment_id: '',
      history_description: '',
      saving_acc_id: '',
      account_id: '',
      amount: 0,
      budget_amount_left: 0,
      budget_category_name: '',
      budget_category_selected_id: '',
      currency: '',
      currency_interest: 0,
      expense_image_scans: null,
      history_day: 0,
      history_month: 0,
      history_type: '',
      history_year: 0,
      is_deleted: false,
      time_stamp: 0,
      total_allocated_for_category: 0,
    },
  ]);

  const fetchRelatedTransactions = async () => {
    setIsLoading(true);
    const expenseTransactions = await fetchExpensesByBudgetCategorySelectedId(
      budgetCategoryId
    );
    setTransactions(expenseTransactions);

    //console.log('Transactions');
    //console.log(expenseTransactions);
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}
      {/*/Loader */}

      {/* ============================================================== */}
      {/* Start right Content here */}
      {/* ============================================================== */}

      <div data-bs-theme={isDarkMode ? 'dark' : 'light'}>
        <div className='container-fluid'>
          {/* end page title */}
          <div className='row'>
            <div className='col-xl-4'>
              <ExpenseTransactionGraph
                transactions={transactions}
                isDarkMode={isDarkMode}
              />
            </div>
            <div className='col-xl-6'>
              <div className='row'>
                <div className='col-md-6'>
                  {/*}  <SpendingCard
                    mostSpentCategory={mostSpentCategory}
                    percentageChange={percentageChange}
                    />*/}
                </div>
                <div className='col-md-6'>
                  {/*} <BudgetLeftCard
                    leftToBudget={leftToBudget}
                    totalBudget={totalBudget}
                    />*/}
                </div>
                <div className='col-md-6'>
                  {/*} <TotalSpendCard
                    totalSpentPercentageChange={totalSpentPercentageChange}
                    totalSpent={totalSpent}
                    secondLatestBudgetSummary={secondLatestBudgetSummary}
                    />*/}
                </div>
                <div className='col-md-6'>
                  {/* <SavingCard
                    mostSavedCategory={mostSavedCategory}
                    percentageChange={percentageChangeInSavings}
                    />*/}
                </div>
              </div>
            </div>
          </div>
          {/* end col*/}
        </div>{' '}
        {/* end row*/}
        <div className='row'>
          {/* Expense Transaction Grid */}
          {transactions && (
            <ExpenseTransactionGrid transactions={transactions} />
          )}

          {/* end col */}
        </div>
        {/* end row */}
        {/* container-fluid */}
      </div>
      {/* End Page-content */}

      {/* End Page-content */}
    </React.Fragment>
  );
};

export default BudgetRelatedExpenses;
