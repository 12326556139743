import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "../src/styletheme/css/bootstrap.min.css";
import "../src/styletheme/css/icons.min.css";
import "../src/styletheme/css/app.css";
import "../src/styletheme/css/custom.min.css";
import { PalRequestProvider } from "./services/context/palz/palRequestContext";
import { SubscriptionProvider } from "./services/context/subscription/subscriptionContext";
import { SettingsProvider } from "./services/context/settings/settingsContext";




const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter>
        <SettingsProvider>
            <PalRequestProvider>
                <SubscriptionProvider>
                <App />
                </SubscriptionProvider>
            </PalRequestProvider>
            </SettingsProvider>
        </BrowserRouter>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
