import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import loader from '../../../../styletheme/images/loading/loader.svg';
import { fetchAllPastBudgets } from '../../../../services/budgets/budgetServiceManagement';
import LoaderView from '../../../reusable/loader/loaderView';
import PastBudgetCardGrid from '../../../reusable/cards/budget/past/pastBudgetCardGrid';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';
import no_budget_animation from '../../../reusable/animations/lottie/no_budget.json';

const PastBudgetGrid = ({ isDarkMode }) => {
  const [isLoading, setIsLoading] = useState();
  const [pastBudgets, setPastBudgets] = useState([
    {
      budget_categories: [
        {
          budget_id: '',
          budget_category_id: '',
          category_name: '',
          total_allocated_for_property: 0,
          amount_left: 0,
        },
      ],
      budget_id: '',
      creator_profile: {
        creator_profile_id: '',
        email_address: '',
        profile_img: '',
        last_name: '',
        user_id: '',
      },
      email_address: '',
      first_name: '',
      is_active_budget: false,
      last_name: '',
      month: 0,
      record_time_stamp: 0,
      total_left: 0,
      total_sum: 0,
      user_id: '',
      year: 0,
    },
  ]);

  const emptyBudgetLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: no_budget_animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    getPastBudgets();
    setIsLoading(false);
  }, []);

  const getPastBudgets = async () => {
    setIsLoading(true);
    const pastBudgets = await fetchAllPastBudgets();
    setPastBudgets(pastBudgets);

    //console.log('Past Budgets');
    //console.log(pastBudgets);
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}
      {/*/Loader */}

      {/* ============================================================== */}
      {/* Start right Content here */}
      {/* ============================================================== */}

      <div data-bs-theme={isDarkMode ? 'dark' : 'light'}>
        <div className='container-fluid'>
          {/* end page title */}
          <div className='row'>
            <div className='col-xl-4'>
              {/*<ExpenseTransactionGraph
                transactions={transactions}
                isDarkMode={isDarkMode}
              />
    */}
            </div>
            <div className='col-xl-6'>
              <div className='row'>
                <div className='col-md-6'>
                  {/*}  <SpendingCard
                    mostSpentCategory={mostSpentCategory}
                    percentageChange={percentageChange}
                    />*/}
                </div>
                <div className='col-md-6'>
                  {/*} <BudgetLeftCard
                    leftToBudget={leftToBudget}
                    totalBudget={totalBudget}
                    />*/}
                </div>
                <div className='col-md-6'>
                  {/*} <TotalSpendCard
                    totalSpentPercentageChange={totalSpentPercentageChange}
                    totalSpent={totalSpent}
                    secondLatestBudgetSummary={secondLatestBudgetSummary}
                    />*/}
                </div>
                <div className='col-md-6'>
                  {/* <SavingCard
                    mostSavedCategory={mostSavedCategory}
                    percentageChange={percentageChangeInSavings}
                    />*/}
                </div>
              </div>
            </div>
          </div>
          {/* end col*/}
        </div>{' '}
        {/* end row*/}
        <div className='row'>
          {pastBudgets &&
          pastBudgets?.length > 0 &&
          pastBudgets[0].budget_id ? (
            <PastBudgetCardGrid pastBudgets={pastBudgets} />
          ) : (
            <>
              <div className='card pt-3 px-5' style={{ paddingBottom: '1%' }}>
                <Lottie
                  options={emptyBudgetLottieOptions}
                  height={400}
                  width={400}
                />

                <div className='text-center'>
                  <h3>No Past Budgets Found!</h3>
                  <span>Check back later when you have created budgets.</span>
                </div>
                <div className='mt-3 text-center'>
                  <Link to={'/createNewBudget'} className='btn btn-success'>
                    Create new Budget
                  </Link>
                </div>
              </div>
            </>
          )}
          {/* end col */}
        </div>
        {/* end row */}
        {/* container-fluid */}
      </div>
      {/* End Page-content */}

      {/* End Page-content */}
    </React.Fragment>
  );
};

export default PastBudgetGrid;
