import React, { useContext, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

//import profileImg from "../../../styletheme/images/users/avatar-1.jpg";
import profileImg from '../../../styletheme/images/users/multi-user.jpg';
//import logo from "../../../styletheme/assets/img/brand/logo.png";
//import logo_white from "../../../styletheme/assets/img/brand/logo-white.png";
import config from '../../../constants/config.json';
//import "../../../styletheme/assets/css/menu/navstyles.css";
//import user_img from "../../../styletheme/assets/img/users/6.jpg";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import logo from '../../../styletheme/logos/savingpalz_logo.png';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { getCurrentUser } from '../../../services/authentication/authManagementService';
import { usePalRequest } from '../../../services/context/palz/palRequestContext';
import { useSettings } from '../../../services/context/settings/settingsContext';
import { useSubscription } from '../../../services/context/subscription/subscriptionContext';

const NavigationBar = ({ userProfile, subscription }) => {
  const { palRequests } = usePalRequest();
  const { settings, setSettings } = useSettings();
  //const { subscription } = useSubscription();

  const [cartItems, setCartItems] = useState(5);
  const [notifications, setNotifications] = useState(3);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const first_name = localStorage.getItem(config.first_name);
  const last_name = localStorage.getItem(config.last_name);
  const profile_img = localStorage.getItem(config.profile_img);
  const email = localStorage.getItem(config.email);

  const toggleDarkMode = () => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      isDarkMode: !prevSettings.isDarkMode,
    }));
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };
  /*
 <li className="nav-item">
                                        <a href="apps-calendar.html" className="nav-link" data-key="t-calendar"> Calendar </a>
                                    </li>
*/

  function capitalizeFirstLetterOfEachWord(str) {
    if (!str) {
      return 'Inactive';
    }
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }
  return (
    <React.Fragment>
      <Navbar
        id='page-topbar'
        collapseOnSelect
        expand='lg'
        bg={settings.isDarkMode ? 'dark' : 'medium'}
        variant={settings.isDarkMode ? 'dark' : 'medium'}
        className=''
        //bg={isDarkMode ? "dark" : "medium"}
        // variant={isDarkMode ? "dark" : "medium"}
        style={{ paddingLeft: '10px', paddingRight: '10px' }}
      >
        <Navbar.Brand as={Link} to='/dashboard' style={{ paddingLeft: '10px' }}>
          <img
            src={logo}
            width='100'
            height='80'
            className='d-inline-block align-top'
            alt='Logo'
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls='responsive-navbar-nav'
          style={{ paddingRight: '10px' }}
        />
        {!getCurrentUser() && (
          <Navbar.Collapse
            id='responsive-navbar-nav'
            style={{ justifyContent: 'right' }}
          >
            <Nav className='ml-auto fs-5'>
              <Nav.Link as={Link} to='/signup' className='nav-link '>
                Sign Up
              </Nav.Link>
            </Nav>
            <Nav className='ml-auto fs-5'>
              <Nav.Link as={Link} to='/' className='nav-link '>
                Login
              </Nav.Link>
            </Nav>
            <Nav className='ml-auto fs-5'>
              {/*
              <Nav.Link as={Link} to="/dashboard" className="nav-link ">
                Dashboard
              </Nav.Link>
      
              <Nav.Link as={Link} to='/advert'>
                Property Advertisement
              </Nav.Link>

                */}
              {/*
              <NavDropdown title="For Landlords" id="basic-nav-dropdown">
                <NavDropdown.Item href="about.html">About Us</NavDropdown.Item>
                <NavDropdown.Item href="features.html">
                  Features & Addons
                </NavDropdown.Item>
                <NavDropdown.Item href="pricing.html">
                  Pricing Packages
                </NavDropdown.Item>
                <NavDropdown.Item href="download.html">
                  Download Page
                </NavDropdown.Item>
                <NavDropdown.Item href="projects.html">
                  Our Projects
                </NavDropdown.Item>
                <NavDropdown.Item href="project-details.html">
                  Project Details
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="For Tenants" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/tenantapplication">
                  Rental Application Form
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link as={Link} to="/blogs">
                Blogs
              </Nav.Link>
              */}
              {/*
              <Nav.Link as={Link} to="/contactus">
                Contact Us
              </Nav.Link>
            */}
            </Nav>
          </Navbar.Collapse>
        )}

        {getCurrentUser() && (
          <>
            <Navbar.Collapse
              id='responsive-navbar-nav'
              style={{ justifyContent: 'center' }}
            >
              <Nav className='ml-auto fs-5'>
                <Nav.Link as={Link} to='/dashboard' className='nav-link '>
                  Dashboard
                </Nav.Link>

                <NavDropdown title='Budget' id='basic-nav-dropdown'>
                  <NavDropdown.Item href='/budget'>
                    Current Budget
                  </NavDropdown.Item>
                  <NavDropdown.Item href='/pastBudgets'>
                    Past Budgets
                  </NavDropdown.Item>

                  <NavDropdown.Item href='/createNewBudget'>
                    Create New Budget
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title='Expense' id='basic-nav-dropdown'>
                  <NavDropdown.Item href='/createNewExpense'>
                    Add New Expense
                  </NavDropdown.Item>
                  <NavDropdown.Item href='/currentExpenses'>
                    Current Expenses
                  </NavDropdown.Item>
                  <NavDropdown.Item href='/allExpenses'>
                    All Expenses
                  </NavDropdown.Item>
                  <NavDropdown.Item href='/expenseCalendar'>
                    Expense Calendar
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title='Palz' id='basic-nav-dropdown'>
                  <NavDropdown.Item href='/inviteNewPal'>
                    Invite a Pal
                  </NavDropdown.Item>
                  <NavDropdown.Item href='/approvedPalz'>
                    Approved Palz
                  </NavDropdown.Item>
                  <NavDropdown.Item href='/pendingPalz'>
                    Pending Palz
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title='Budget Goals' id='basic-nav-dropdown'>
                  <NavDropdown.Item href='/allBudgetGoals'>
                    All Budget Goals
                  </NavDropdown.Item>
                  <NavDropdown.Item href='/createNewBudgetGoal'>
                    Add New Budget Goal
                  </NavDropdown.Item>
                </NavDropdown>

                <Nav.Link as={Link} to='/expenseCalendar' className='nav-link '>
                  Calendar
                </Nav.Link>

                {/*
               
               
                <Nav.Link as={Link} to="/myproperties">
                  My Properties
                </Nav.Link>

                <NavDropdown title="For Landlords" id="basic-nav-dropdown">
                  <NavDropdown.Item href="about.html">
                    About Us
                  </NavDropdown.Item>
                  <NavDropdown.Item href="features.html">
                    Features & Addons
                  </NavDropdown.Item>
                  <NavDropdown.Item href="pricing.html">
                    Pricing Packages
                  </NavDropdown.Item>
                  <NavDropdown.Item href="download.html">
                    Download Page
                  </NavDropdown.Item>
                  <NavDropdown.Item href="projects.html">
                    Our Projects
                  </NavDropdown.Item>
                  <NavDropdown.Item href="project-details.html">
                    Project Details
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="For Tenants" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/tenantapplication">
                    Rental Application Form
                  </NavDropdown.Item>
                </NavDropdown>

                <Nav.Link as={Link} to="/blogs">
                  Blogs
                </Nav.Link>
        */}
                {/*
                <Nav.Link as={Link} to="/contactus">
                  Contact Us
                </Nav.Link>
      */}
              </Nav>
            </Navbar.Collapse>

            <Navbar.Collapse
              style={{
                justifyContent: 'right',
                paddingRight: '100px',
              }}
            >
              <Nav
                style={{
                  paddingRight: '2%',
                }}
                className='fs-5'
              >
                <div className='ms-1 header-item d-none d-sm-flex'>
                  <button
                    type='button'
                    className='btn btn-icon btn-topbar btn-ghost-secondary rounded-circle'
                    data-toggle='fullscreen'
                    onClick={toggleFullscreen}
                  >
                    <i className='bx bx-fullscreen fs-22'></i>
                  </button>
                </div>

                <div className='ms-1 header-item d-none d-sm-flex'>
                  <button
                    type='button'
                    className='btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode'
                    onClick={toggleDarkMode}
                  >
                    <i
                      className={`bx ${
                        settings.isDarkMode ? 'bx-sun' : 'bx-moon'
                      } fs-22`}
                    ></i>
                  </button>
                </div>
                <div
                  className='dropdown topbar-head-dropdown ms-1 header-item position-relative'
                  id='notificationDropdown'
                >
                  <Link
                    to={'/pendingPalz'}
                    className='btn btn-icon btn-topbar btn-ghost-secondary rounded-circle position-relative'
                    id='page-header-notifications-dropdown'
                    data-bs-toggle='dropdown'
                    data-bs-auto-close='outside'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <i className='bx bx-user fs-22'></i>

                    {palRequests.palRequestCount > 0 && (
                      <span className='position-relative topbar-badge fs-10 top-100 start-0 translate-middle badge rounded-pill bg-danger'>
                        <span className='visually-hidden'>unread messages</span>
                        {palRequests.palRequestCount} {''}
                      </span>
                    )}
                  </Link>
                </div>
              </Nav>

              <Nav className='fs-5'>
                {/* TODO*/}
                {profile_img ? (
                  <img
                    className='rounded-circle header-profile-user'
                    src={`data:image/jpeg;base64,${profile_img}`}
                    alt='Profile Avatar'
                    style={{ width: '40px', height: '40px' }}
                  />
                ) : (
                  <img
                    className='rounded-circle header-profile-user'
                    src={profileImg}
                    alt='Profile Avatar'
                    style={{ width: '40px', height: '40px' }}
                  />
                )}

                <NavDropdown
                  title={first_name + ' ' + last_name}
                  id='basic-nav-dropdown'
                >
                  <NavDropdown.Item className='dropdown-item ' disabled>
                    <i className='mdi mdi-email-outline text-muted fs-16 align-middle me-1'></i>
                    <span className='align-middle'>{email}</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item as='div'>
                    <Link to='/billing'>
                      <i className='mdi mdi-receipt text-muted fs-16 align-middle me-1'></i>{' '}
                      <span className='align-middle'>
                        Billing{' '}
                        {/*} <b>
                          {capitalizeFirstLetterOfEachWord(subscription.status)}
                        </b>*/}
                      </span>
                    </Link>
                  </NavDropdown.Item>

                  {/*
                         <NavDropdown.Item
                    href='features.html'
                    className='dropdown-item '
                  >
                    <i className='mdi mdi-account-circle text-muted fs-16 align-middle me-1'></i>{' '}
                    <span className='align-middle'>Profile</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href='features.html'
                    className='dropdown-item'
                  >
                    <i className='mdi mdi-message-text-outline text-muted fs-16 align-middle me-1'></i>{' '}
                    <span className='align-middle'>Messages</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href='features.html'
                    className='dropdown-item'
                  >
                    <i className='mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1'></i>{' '}
                    <span className='align-middle'>Taskboard</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href='features.html'
                    className='dropdown-item'
                  >
                    <i className='mdi mdi-lifebuoy text-muted fs-16 align-middle me-1'></i>{' '}
                    <span className='align-middle'>Help</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href='features.html'
                    className='dropdown-item'
                  >
                    <i className='mdi mdi-wallet text-muted fs-16 align-middle me-1'></i>{' '}
                    <span className='align-middle'>
                      Balance : <b>$5971.67</b>
                    </span>
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    href="features.html"
                    className="dropdown-item"
                  >
                    <span className="badge bg-success-subtle text-success mt-1 float-end">
                      New
                    </span>
                    <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">Settings</span>
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    href="features.html"
                    className="dropdown-item"
                  >
                    <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">Lock screen</span>
                  </NavDropdown.Item>
                */}
                  <NavDropdown.Item as='div'>
                    <Link
                      to='/logout'
                      onClick={() => {
                        //clear profile
                        userProfile = {};
                      }}
                    >
                      <i className='mdi mdi-logout text-muted fs-16 align-middle me-1'></i>{' '}
                      <span className='align-middle' data-key='t-logout'>
                        Logout
                      </span>
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Navbar>
    </React.Fragment>
  );
};

export default NavigationBar;
