import React, { useState } from 'react';
import { getIconUrl } from '../../../../constants/iconUrlMap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import BudgetGoalCard from './sub/budgetGoalCard';

const BudgetGoalsGrid = ({ budgetGoals }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('Goal Name');

  const filteredGoals = budgetGoals
    .filter((goal) =>
      goal.goal_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      switch (sortOption) {
        case 'Goal Name':
          return a.goal_name.localeCompare(b.goal_name);
        case 'Planned Amount':
          return b.budget_goal_sum_total - a.budget_goal_sum_total; // for descending order
        case 'Progress':
          const aProgress =
            a.budget_goal_sum_total !== 0
              ? (a.current_amt_saved / a.budget_goal_sum_total) * 100
              : 0;
          const bProgress =
            b.budget_goal_sum_total !== 0
              ? (b.current_amt_saved / b.budget_goal_sum_total) * 100
              : 0;
          return bProgress - aProgress; // for descending order
        default:
          return 0;
      }
    });

  return (
    <div>
      <div className='row pt-4 px-5'>
        <div className='col-12'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent'>
            <div className='col-md-3'>
              <h4 className='mb-sm-0'>Budget Goals</h4>
            </div>

            <div className='col-md-6'>
              <div id='contact-existing-list'>
                <div className='row mb-2'>
                  <div className='col'>
                    <input
                      className='search form-control'
                      placeholder='Search'
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-3 text-end'>
              <NavDropdown title={`Sort by: ${sortOption}`} id='nav-dropdown'>
                <NavDropdown.Item onClick={() => setSortOption('Goal Name')}>
                  Goal Name
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => setSortOption('Planned Amount')}
                >
                  Planned Amount
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setSortOption('Progress')}>
                  Progress
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
        </div>
      </div>
      <div className='row px-4'>
        {filteredGoals &&
          filteredGoals.map((goal, index) => {
            const amountBudgeted = goal.budget_goal_sum_total || 0;
            const amountSpent = goal.current_amt_saved || 0;
            const amountLeft = amountBudgeted - amountSpent;
            const progress =
              amountBudgeted !== 0 ? (amountSpent / amountBudgeted) * 100 : 0;

            let barColor = 'bg-secondary';
            if (progress >= 95) {
              barColor = 'bg-danger';
            } else if (progress >= 80) {
              barColor = 'bg-warning';
            } else {
              barColor = 'bg-success';
            }

            return (
              <div key={index} className='col-xl-3 '>
                <div className='card card-height-100'>
                  <div className='card-body'>
                    <li key={index} className='list-group-item'>
                      <BudgetGoalCard
                        goal={goal}
                        amountLeft={amountLeft}
                        progress={progress}
                        barColor={barColor}
                      />
                    </li>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default BudgetGoalsGrid;
