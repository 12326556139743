//Register landlord next and let the questions come from the api

import React, { useEffect, useState, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
//import load_img from "../../../styletheme/assets/img/svgicons/loader.png";
//import fav_img from "../../../styletheme/assets/img/brand/favicon.png";

import { Bars } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import Form from '../../reusable/form/form';
import Joi from 'joi-browser';

import Input from '../../reusable/form/Input';

import { toast } from 'react-toastify';
import {
  findEmailByLoginCode,
  loginUser,
  sendLoginRequest,
} from '../../../services/authentication/authManagementService';

import { User } from '../../../services/authentication/model/user';

import logo from '../../../styletheme/logos/savingpalz_logo.png';
import ImageCarousel from '../../reusable/carousel/imageCarousel';

const LoginPage = () => {
  //for login
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const { loginCode } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({
    emailAddress: '',
    loginCode: '',
  });

  const [errors, setErrors] = useState({});
  const schema = {
    emailAddress: Joi.string().email().required().label('Email Address'), //use joi in node as well
    loginCode: Joi.string().min(7).required().label('Login Code'),
  };

  //this is the home page (only authenticated people)
  const [pageHeaderDetail, setHeaderDetail] = useState({
    title: 'Relevant news about SavingPalz and its offerings',
  });

  const [Loading, setLoading] = useState({
    isLoading: false,
  });

  const [isRequestEmailSent, setIsRequestEmailSent] = useState(false);

  useEffect(() => {
    setLoading({ isLoading: true });
    window.scrollTo(0, 0);

    getAdditionalInfo();

    //after loading
    setLoading({ isLoading: false });
  }, []);

  const getAdditionalInfo = async () => {
    if (loginCode) {
      //alert('Login Code: ' + loginCode);
      //find the email address based on the login code
      //findEmailByLoginCode
      const response = await findEmailByLoginCode(loginCode);

      if (response.isError) {
        toast.error(response.message);
        return;
      }
      //email
      data.emailAddress = response.message;

      //email was sent
      setIsRequestEmailSent(true);
      //set the login code
      data.loginCode = loginCode;
    }
  };

  //TODO: Form Validation (Need to find a way to make this more streamlined)
  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};

    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };
  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaLocal);

    return error ? error.details[0].message : null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //get the values from the form
    // var emailAddress = this.emailAddress.current.value;
    // var password = document.getElementById('txtPassword').value;
    //call the server to save the changes

    const errors = validate();
    //console.log(errors);

    //toast.error(errors.password);
    setErrors({ errors: errors || {} });
    if (errors) return;

    doSubmit();
  };

  const handleChange = ({ currentTarget: input }) => {
    //input validation

    const errorMessage = validateProperty(input);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    //data seccount (model)
    //const data = { ...data };

    data[input.name] = input.value;

    //toast.error(input.value);
    //setData({ data: data });
    setErrors({ errors });
  };

  const sendLoginCodeRequest = async () => {
    //login function
    try {
      //payload
      const user_pal_id = '';
      const first_name = '';
      const last_name = '';
      const email_address = data.emailAddress;

      let userPayload = new User(
        user_pal_id,
        first_name,
        last_name,
        email_address
      );

      const message = await sendLoginRequest(userPayload);

      //console.log('API Response on UI');
      //console.log(message);
      if (message.is_error) {
        toast.error(message.message);
        return;
      } else if (message.is_error === false) {
        toast.success(message.server_message);
        //if successful then set that the email has been sent
        setIsRequestEmailSent(true);
      }
    } catch (ex) {
      //console.log(ex);
      if (ex.response && ex.response.status === 400) {
        //const errors = { ...errors };
        toast.error(ex.response.data);
        errors.emailAddress = ex.response.data;
        //Update the UI
        setErrors({ errors });
      }
    }
  };

  const doSubmit = async () => {
    //login function
    try {
      //const { data } = this.state;
      //payload
      const user_pal_id = '';
      const first_name = '';
      const last_name = '';
      const email_address = data.emailAddress;
      const login_code = data.loginCode;

      let loginPayLoad = new User(
        user_pal_id,
        first_name,
        last_name,
        email_address,
        null,
        null,
        null,
        null,
        login_code
      );

      //console.log(loginPayLoad);
      //alert("Login called");
      //return;
      const response = await loginUser(loginPayLoad);

      //alert(response.message);
      //get and set the profile
      if (response.isError) {
        toast.error(response.message);
        return;
      } else if (response.isError === false) {
        //force update
        //forceUpdate();

        // Navigate to the dashboard
        //navigate('/dashboard');

        forceUpdate();

        toast.success(response.message);
        //force refresh the nav bar
        //window.location.reload();

        window.location = '/dashboard';
      }

      //Not working
      // alert(`Login called ${test}`);

      //toast.success('Logged In Successfully');
    } catch (ex) {
      //console.log(ex);
      if (ex.response && ex.response.status === 400) {
        //const errors = { ...errors };
        toast.error(ex.response.data);
        errors.emailAddress = ex.response.data;
        //Update the UI
        setErrors({ errors });
      }
    }
  };

  //for the slide show
  const [slideShowImages, setSlideShowImages] = useState([
    {
      id: 1,
      image:
        'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Web%20Portal%20Login%20Register%20Images%2FScreenshot%202024-05-06%20at%2011.42.25%E2%80%AFAM.png?alt=media&token=9621af52-4a8c-40d4-b89f-2b0b8281a501',
      title: 'Your Financial Hub',
      description: 'See your finances at a glance.',
    },
    {
      id: 2,
      image:
        'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Web%20Portal%20Login%20Register%20Images%2FScreenshot%202024-05-07%20at%203.43.09%E2%80%AFAM.png?alt=media&token=44bc09b9-7480-43ea-b7b9-0121ef298889',
      title: 'Goal Setting with your Pal',
      description: 'You can share budget goals with others.',
    },
    {
      id: 3,
      image:
        'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Web%20Portal%20Login%20Register%20Images%2FScreenshot%202024-05-07%20at%203.42.12%E2%80%AFAM.png?alt=media&token=eeabdaa9-56cc-488c-97f2-86d2d84d6ac6',
      title: 'How is my budget doing?',
      description: 'Easy to read breakdown of your budget and spending habits.',
    },
    {
      id: 4,
      image:
        'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Web%20Portal%20Login%20Register%20Images%2FScreenshot%202024-05-07%20at%203.42.28%E2%80%AFAM.png?alt=media&token=2b725bd3-3ab4-4c8e-b38f-fd999962ca95',
      title: 'Your Current Expenses',
      description: 'See your expenses for your current budget period.',
    },
  ]);

  return (
    <React.Fragment>
      {/*Loader */}
      {Loading.isLoading && (
        <div
          id='global-loader'
          style={{
            zIndex: '99',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            position: 'absolute',
          }}
        >
          <Bars
            height='80'
            width='80'
            color='#4fa94d'
            ariaLabel='bars-loading'
            wrapperStyle={{}}
            wrapperclassName=''
            visible={true}
          />
        </div>
      )}
      {/*/Loader */}

      <div className='page'>
        <div className='container-fluid'>
          <div className='row no-gutter'>
            <div className='col-xl-12'>
              <div className='row justify-content-center'>
                <div
                  id='centerLoginForm'
                  className='col-md-4 col-lg-3 col-xl-3 center-login'
                >
                  {/* Login Form */}
                  <div className='card mt-4 card-bg-fill'>
                    <div className='card-body p-4'>
                      <div className='text-center mt-2'>
                        <img
                          src={logo}
                          width='60'
                          height='60'
                          alt='description_of_image'
                        />
                        <h5 className='text-primary'>Welcome Back !</h5>
                        <p className='text-muted'>
                          Sign in to continue to SavingPalz.
                        </p>
                      </div>
                      <div className='p-2 mt-4'>
                        <form onSubmit={handleSubmit}>
                          <div className='mb-3'>
                            <Input
                              name='emailAddress'
                              type='email'
                              error={errors.emailAddress}
                              value={data.emailAddress}
                              placeholder='Write your Email Address.'
                              label='Email Address'
                              onChange={handleChange}
                            />
                          </div>

                          <div className='mb-3'>
                            <div className='position-relative auth-pass-inputgroup mb-3'>
                              {isRequestEmailSent && (
                                <div className='form-group'>
                                  <Input
                                    name='loginCode'
                                    type='password'
                                    error={errors.loginCode}
                                    value={data.loginCode}
                                    placeholder='Write your Login Code.'
                                    label='Login Code'
                                    onChange={handleChange}
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          <div className='mt-4'>
                            {isRequestEmailSent && (
                              <button
                                //disabled={validate()}
                                className='btn btn-success w-100'
                              >
                                Sign In
                              </button>
                            )}

                            {!isRequestEmailSent && (
                              <button
                                //disabled={validate()}
                                onClick={() => {
                                  sendLoginCodeRequest();
                                }}
                                className='btn btn-success w-100'
                              >
                                Send Login Code
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                    {/*end card body*/}
                  </div>
                  {/*end card*/}

                  <div className='mt-4 text-center'>
                    <p className='mb-0'>
                      Don't have an account ?{' '}
                      <Link
                        to='/signup'
                        className='fw-semibold text-primary text-decoration-underline'
                      >
                        {' '}
                        Sign Up{' '}
                      </Link>{' '}
                    </p>
                  </div>
                </div>
                <div className='col-md-8 col-lg-4 col-xl-4'>
                  {/* Slides Reusable */}
                  <ImageCarousel slideShowImages={slideShowImages} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*End Page */}
    </React.Fragment>
  );
};

export default LoginPage;
