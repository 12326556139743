// src/components/reusable/SavingCard.jsx
import React from 'react';

const SavingCard = ({ mostSavedCategory, percentageChange }) => {
  return (
    <>
      <div className='card card-animate'>
        <div className='card-body'>
          <div className='d-flex justify-content-between'>
            <div>
              <p className='fw-medium text-muted mb-0'>Most Saved Category</p>
              <h2 className='mt-4 ff-secondary fw-semibold'>
                <span className='counter-value' data-target='33.48'>
                  {mostSavedCategory.category
                    ? mostSavedCategory.category.category_name
                    : 'No categories'}
                </span>
              </h2>
              <p className='mb-0 text-muted'>
                <span
                  className={`badge bg-light ${
                    percentageChange >= 0 ? 'text-success' : 'text-danger'
                  } mb-0`}
                >
                  <i
                    className={`ri-arrow-${
                      percentageChange >= 0 ? 'down' : 'up'
                    }-line align-middle`}
                  ></i>{' '}
                  {Number(percentageChange)?.toFixed(2)} %
                </span>{' '}
                vs. previous period
              </p>
            </div>
            <div>
              <div className='avatar-sm flex-shrink-0'>
                <span className='avatar-title bg-info-subtle rounded-circle fs-2 material-shadow'>
                  <i className='text-info bx bx-money'></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* end card body */}
      </div>{' '}
      {/* end card*/}
    </>
  );
};

export default SavingCard;
