// src/components/reusable/BudgetLeftCard.jsx
import React from 'react';

const BudgetLeftCard = ({ leftToBudget, totalBudget }) => {
  const budgetPercentage = (leftToBudget / totalBudget) * 100;
  const budgetClass =
    budgetPercentage < 10
      ? 'bg-danger'
      : budgetPercentage > 50
      ? 'bg-success'
      : 'bg-primary';
  return (
    <>
      <div className={`card card-animate ${budgetClass}`}>
        <div className='card-body'>
          <div className='d-flex justify-content-between'>
            <div>
              <p className='fw-medium text-white-50 mb-0'>Left to Budget</p>
              <h2 className='mt-4 ff-secondary fw-semibold text-white'>
                <span className='counter-value' data-target='3'>
                  $
                </span>{' '}
                <span className='counter-value' data-target='40'>
                  {Number(leftToBudget)?.toFixed(2)}
                </span>
              </h2>
              <p className='mb-0 text-white-50'>
                <span className='badge bg-white bg-opacity-25 text-white mb-0'>
                  <i
                    className={`ri-arrow-${
                      budgetPercentage >= 0 ? 'down' : 'up'
                    }-line align-middle`}
                  ></i>{' '}
                  {Number(budgetPercentage)?.toFixed(2)} %
                </span>{' '}
                vs. previous period
              </p>
            </div>
            <div>
              <div className='avatar-sm flex-shrink-0'>
                <span className='avatar-title bg-white bg-opacity-25 rounded-circle fs-2 material-shadow'>
                  <i className='bx bx-bell text-white'></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* end card body */}
      </div>{' '}
      {/* end card*/}
    </>
  );
};

export default BudgetLeftCard;
