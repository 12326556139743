import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoaderView from '../../reusable/loader/loaderView';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
  predefinedColors,
  dynamicallyGeneratedColor,
  parseColor,
  darkenColor,
  colorForCategory,
  stringToHash,
} from '../../../services/helpers/colorService';
import {
  fetchBudgetGoalById,
  fetchBudgetGoalFundsByBudgetGoalId,
  fetchBudgetGoalsByUserId,
} from '../../../services/budgetGoal/budgetGoalManagement';
import BudgetGoalContributionCard from '../../reusable/cards/dashboard/budgetGoalContributionCard';
import BudgetGoalsGrid from '../../reusable/cards/budgetGoal/budgetGoalsGrid';
import BudgetGoalFundsGrid from '../../reusable/cards/budgetGoal/sub/budgetGoalFundsGrid';
import GoalFundsBarChart from '../../reusable/cards/budgetGoal/sub/goalFundsBarChart';
import BudgetGoalCard from '../../reusable/cards/budgetGoal/sub/budgetGoalCard';

const BudgetGoalDetail = ({ isDarkMode }) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const [isLoading, setIsLoading] = useState();
  const pageTitle = 'Budget Goal';

  const { budgetGoalId } = useParams();

  const [progress, setProgress] = useState(0);
  const [amountLeft, setAmountLeft] = useState(0);
  const [barColor, setBarColor] = useState('bg-secondary');
  const [budgetGoal, setBudgetGoal] = useState({
    ai_insight_msg: '',
    bank_account_id: '',
    budget_goal_id: '',
    budget_goal_sum_total: 0,
    current_amt_saved: 0,
    goal_category_icon: '',
    goal_creator_profile: {},
    goal_name: '',
    goal_type: '',
    has_achieved_goal: false,
    is_deleted: false,
    month: 0,
    pals_working_together_towards_goal: [],
    record_time_stamp: 0,
    target_end_date_raw_date: 0,
    total_left: 0,
    user_id: '',
    year: 0,
  });

  const [budgetGoalFunds, setBudgetGoalFunds] = useState([
    {
      budget_goal_fund_id: '',
      budget_goal_id: '',
      fund_amt_added: 0,
      fund_date: 0,
      fund_source_id: '',
      fund_source_name: '',
    },
  ]);

  const getBudgetGoal = async () => {
    //get the budget goals
    //console.log('Budget goal id: ', budgetGoalId);
    const budgetGoal = await fetchBudgetGoalById(budgetGoalId);

    setBudgetGoal(budgetGoal);

    //get the funds
    const budgetGoalFunds = await fetchBudgetGoalFundsByBudgetGoalId(
      budgetGoalId
    );

    if (budgetGoalFunds.length > 0) {
      setBudgetGoalFunds(budgetGoalFunds);

      setGoalStats();
    } else {
      //console.log('No funds found');
      setGoalStats();
    }
    setIsLoading(false);
  };

  //check why it shouldnt set sometimes
  const setGoalStats = () => {
    const amountBudgeted = budgetGoal.budget_goal_sum_total || 0;
    const amountSpent = budgetGoal.current_amt_saved || 0;
    const amountLeft = amountBudgeted - amountSpent;
    const calculatedProgress =
      amountBudgeted !== 0 ? (amountSpent / amountBudgeted) * 100 : 0;

    setProgress(calculatedProgress);

    let calculatedBarColor = 'bg-secondary';
    if (calculatedProgress >= 95) {
      calculatedBarColor = 'bg-danger';
    } else if (calculatedProgress >= 80) {
      calculatedBarColor = 'bg-warning';
    } else {
      calculatedBarColor = 'bg-success';
    }

    setBarColor(calculatedBarColor);
  };

  useEffect(() => {
    setIsLoading(true);

    getBudgetGoal();
  }, []);

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}
      {/*/Loader */}

      {/* ============================================================== */}
      {/* Start right Content here */}
      {/* ============================================================== */}

      <div data-bs-theme={isDarkMode ? 'dark' : 'light'}>
        <div className='container-fluid'>
          <div className='row px-4'>
            <div className='col-12'>
              <div className='page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent'>
                <h4 className='mb-sm-0'>{pageTitle}</h4>

                <div className='page-title-right'>
                  <ol className='breadcrumb m-0'>
                    <li className='breadcrumb-item'>
                      <a
                        href='#'
                        onClick={(event) => {
                          event.preventDefault();
                          // your code here
                        }}
                      >
                        Pages
                      </a>
                    </li>
                    <li className='breadcrumb-item active'>{pageTitle}</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className='row'>
            <div className='col-xl-4'>
              <GoalFundsBarChart funds={budgetGoalFunds} />
            </div>
            <div className='col-xl-6'>
              <div className='row'>
                <div className='col-md-6'>
                  {/*}  <SpendingCard
                    mostSpentCategory={mostSpentCategory}
                    percentageChange={percentageChange}
                    />*/}
                  <div className='card card-height-100'>
                    <div className='card-body'>
                      <BudgetGoalCard
                        goal={budgetGoal}
                        amountLeft={amountLeft}
                        progress={progress}
                        barColor={barColor}
                        isModifyMode={true}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  {/*} <BudgetLeftCard
                    leftToBudget={leftToBudget}
                    totalBudget={totalBudget}
                    />*/}
                </div>
                <div className='col-md-6'>
                  {/*} <TotalSpendCard
                    totalSpentPercentageChange={totalSpentPercentageChange}
                    totalSpent={totalSpent}
                    secondLatestBudgetSummary={secondLatestBudgetSummary}
                    />*/}
                </div>
                <div className='col-md-6'>
                  {/* <SavingCard
                    mostSavedCategory={mostSavedCategory}
                    percentageChange={percentageChangeInSavings}
                    />*/}
                </div>
              </div>
            </div>
          </div>
          {/* end col*/}
        </div>{' '}
        {/* end row*/}
        <div className='row'>
          {/* Budget Goals */}
          <BudgetGoalFundsGrid budgetGoalFunds={budgetGoalFunds} />
          {/* end col */}
        </div>
        {/* end row */}
        {/* container-fluid */}
      </div>
      {/* End Page-content */}

      {/* End Page-content */}
    </React.Fragment>
  );
};

export default BudgetGoalDetail;
