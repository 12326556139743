import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Input from '../../../reusable/form/Input';
import { toast } from 'react-toastify';
import Joi from 'joi-browser';
import { iconUrlMap } from '../../../../constants/iconUrlMap';
import PageTitleBox from '../../../reusable/portal/general/pageTitleBox';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import 'react-datepicker/dist/react-datepicker.css';
import { budgetGoalCategories } from '../../../../constants/budgetGoal/budgetGoalCategories';
import config from '../../../../constants/config.json';
import {
  updateBudgetGoal,
  fetchBudgetGoalById,
  createBudgetGoalFunds,
} from '../../../../services/budgetGoal/budgetGoalManagement';
import LoaderView from '../../../reusable/loader/loaderView';
import { set } from 'lodash';

const AddBudgetGoalFunds = ({ isDarkMode }) => {
  const pageTitle = 'Budget Goal Funds';
  const [isLoading, setIsLoading] = useState(false);
  const { budgetGoalId } = useParams();
  const navigate = useNavigate();
  //TODO: need  get the current budget goal and update the funds next
  const [budgetGoal, setBudgetGoal] = useState({
    goal_date: new Date(), //not stored in the database
    ai_insight_msg: '',
    bank_account_id: '',
    budget_goal_id: '',
    fund_amt_added: 0,
    current_amt_saved: 0,
    goal_category_icon: '',
    goal_creator_profile: {},
    goal_name: '',
    goal_type: '',
    has_achieved_goal: false,
    is_deleted: false,
    month: 0,
    pals_working_together_towards_goal: [],
    record_time_stamp: 0,
    target_end_date_raw_date: 0,
    total_left: 0,
    user_id: '',
    year: 0,
  });

  const [budgetGoalFundsFormData, setBudgetGoalFundsFormData] = useState({
    fund_amt_added: 0,
  });

  const [errors, setErrors] = useState({});

  const schema = {
    //must be greater than 0
    fund_amt_added: Joi.number()
      .min(1)
      .required()
      .label('Amount of Funds Being Added'),
  };

  //form handling
  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(budgetGoalFundsFormData, schema, options);
    if (!error) return null;

    const errors = {};

    for (let item of error.details) errors[item.path[0]] = item.message;

    //console.log('Errors: ', errors);

    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaLocal);

    return error ? error.details[0].message : null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //get the values from the form
    // var emailAddress = this.emailAddress.current.value;
    // var password = document.getElementById('txtPassword').value;
    //call the server to save the changes

    const errors = validate();

    for (const error in errors) {
      if (errors.hasOwnProperty(error)) {
        toast.error(errors[error]);
      }
    }
    setErrors({ errors: errors || {} });
    if (errors) return;

    doSubmit();
  };

  const handleChange = (input) => {
    const currentErrors = { ...errors };
    let name, value;

    if (input && input.currentTarget) {
      name = input.currentTarget.name;
      value = input.currentTarget.value;
    }

    const errorMessage = validateProperty({ name, value });
    if (errorMessage) {
      currentErrors[name] = errorMessage;

      //setErrors(currentErrors);
    } else delete currentErrors[name];

    setBudgetGoalFundsFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors(currentErrors);
  };

  const doSubmit = async () => {
    //call the server
    //console.log('Budget goal funds form data: ', budgetGoalFundsFormData);

    let fund_date_seconds = Number(Date.now()); // Storing date as seconds
    // let fund_date = new Date(fund_date_seconds * 1000); // Converting back to date

    //let convertedBackToDate = new Date(fund_date_seconds);

    const dataToSend = {
      budget_goal_fund_id: uuidv4(),
      budget_goal_id: budgetGoalId,
      fund_date: fund_date_seconds,
      fund_source_id: '',
      fund_source_name: '',
      fund_amt_added: budgetGoalFundsFormData.fund_amt_added,
    };
    const updateBudgetGoalResponse = await createBudgetGoalFunds(dataToSend);
    //console.log('Update budget goal response: ', updateBudgetGoalResponse);

    if (updateBudgetGoalResponse) {
      toast.success('Budget goal funds added successfully');
      //redirect to the budget goal details page
      navigate(`/budgetGoal/${budgetGoalId}`);
    } else {
      toast.error('Failed to add budget goal funds');
      return;
    }
  };

  const getBudgetGoal = async () => {
    //get the budget goals
    //console.log('Budget goal id: ', budgetGoalId);
    const budgetGoal = await fetchBudgetGoalById(budgetGoalId);

    setBudgetGoal(budgetGoal);

    //console.log('Budget goal: ', budgetGoal);

    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    //get the budget goal
    getBudgetGoal();
  }, []);

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}
      {/*/Loader */}
      <div data-bs-theme={isDarkMode ? 'dark' : 'light'}>
        <div className='container-fluid'>
          <PageTitleBox pageTitle={pageTitle} />
          <div className='row '>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-header align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    Add Funds to Budget Goal
                  </h4>
                  <div className='flex-shrink-0'></div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='card-body'>
                    <div className='live-preview'>
                      <div className='row gy-4'>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='fund_amt_added'
                              label='Amount of Funds Being Added'
                              type={'number'}
                              value={budgetGoalFundsFormData.fund_amt_added}
                              onChange={handleChange}
                              error={errors.fund_amt_added}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card-footer'>
                      <button
                        disabled={validate()}
                        className='btn btn-success'
                        type='submit'
                      >
                        Add Funds
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddBudgetGoalFunds;
