import React, { createContext, useState, useContext } from 'react';

const PalRequestContext = createContext();

export function PalRequestProvider({ children }) {
  const [palRequests, setPalRequests] = useState({
    palRequestCount: 0,
  });

  return (
    <PalRequestContext.Provider value={{ palRequests, setPalRequests }}>
      {children}
    </PalRequestContext.Provider>
  );
}

export function usePalRequest() {
  return useContext(PalRequestContext);
}
