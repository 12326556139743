// expenseCategories.js
export const expenseCategories = [
  { value: 'housing', label: 'Housing' },
  { value: 'utilities', label: 'Utilities' },
  { value: 'groceries', label: 'Groceries' },
  { value: 'dining', label: 'Dining Out' },
  { value: 'transportation', label: 'Transportation' },
  { value: 'insurance', label: 'Insurance' },
  { value: 'healthcare', label: 'Healthcare' },
  { value: 'entertainment', label: 'Entertainment' },
  { value: 'clothing', label: 'Clothing' },
  { value: 'savings', label: 'Savings & Investments' },
  { value: 'debt', label: 'Debt Payments' },
  { value: 'education', label: 'Education' },
  { value: 'personal_care', label: 'Personal Care' },
  { value: 'gifts_donations', label: 'Gifts & Donations' },
  { value: 'subscriptions', label: 'Subscriptions & Memberships' },
  { value: 'miscellaneous', label: 'Miscellaneous' },
];

export function findExpenseCategoryByKey(key) {
  const category = expenseCategories.find((option) => option.value === key);
  return category ? category.label : null;
}