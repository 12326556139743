import React from 'react';
import { Link } from 'react-router-dom';

const APPLE_EPOCH_UNIX_TIMESTAMP = 978307200; // Number of seconds between 1970 and 2001

const BudgetGoalFundsGrid = ({ budgetGoalFunds }) => {
  const filteredFunds = budgetGoalFunds
    .filter((fund) => fund.fund_date !== 0)
    .sort((a, b) => b.fund_date - a.fund_date); // for descending order by fund_date

  return (
    <div className='d-flex flex-wrap justify-content-start'>
      {filteredFunds.map((fund, index) => {
        const unixTimestamp = fund.fund_date + APPLE_EPOCH_UNIX_TIMESTAMP;
        const convertedBackToDate = new Date(fund.fund_date);
        return (
          <div className='card card-height-100 m-2' key={index}>
            <div className='card-body'>
              <li className='list-group-item'>
                <div className='overflow-hidden'>
                  <div className='no-padding card-body '>
                    <div className='d-flex justify-content-between '>
                      <div>
                        <h6 className='mb-0'>
                          Fund Date{' '}
                          <b className='text'>
                            {new Date(fund.fund_date).toLocaleDateString(
                              'en-US',
                              {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                              }
                            )}
                          </b>{' '}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-flex align-items-start'>
                  <div className='flex-grow-1'>
                    <div className='d-flex justify-content-between align-items-start'>
                      <div className='overflow-hidden'>
                        <h5 className='contact-name fs-13 mb-1'>
                          {fund.fund_source_name}
                        </h5>
                      </div>

                      <div className='flex-shrink-0 ms-2'>
                        <div
                          className='fs-5 text'
                          style={{ fontWeight: 'bold' }}
                        >
                          {'$'} {Number(fund.fund_amt_added)?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BudgetGoalFundsGrid;
