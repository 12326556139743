///expense/fetch_expenses_and_transactions_by_user_pal_id

import http from "../general/httpService";
import api from "../../constants/config.json";
import config from "../../constants/config.json";

export async function fetchExpensesAndTransactionsByUserPalId() {

    const userId = localStorage.getItem(config.user_id);
    const fullApiEndpoint = api.apiEndpoint + `/expense/fetch_expenses_and_transactions_by_user_pal_id`;
    const {data : expensesAndTransactions} = await http.post(fullApiEndpoint,{user_pal_id: userId});
    return expensesAndTransactions;
}


export async function fetchExpensesByBudgetCategorySelectedId(budgetCategoryId) {

    const fullApiEndpoint = api.apiEndpoint + `/expense/fetch_expenses_by_budget_category_selected_id`;
    const {data : transactions} = await http.post(fullApiEndpoint,{budget_category_selected_id: budgetCategoryId});
    return transactions;
}

///expenses/get_current_expenses
export async function fetchCurrentExpenses() {
    const userId = localStorage.getItem(config.user_id);
    const fullApiEndpoint = api.apiEndpoint + `/expenses/get_current_expenses`;
    const {data : expenses} = await http.post(fullApiEndpoint,{user_id: userId});
    return expenses;
}

//get it for primary or secondary palz
export async function fetchExpensesByPalId(userId) {
    const fullApiEndpoint = api.apiEndpoint + `/expenses/get_current_expenses`;
    const {data : expenses} = await http.post(fullApiEndpoint,{user_id: userId});
    return expenses;
}


//create
export async function createExpenseWithTransactions(expenseData) {
    const fullApiEndpoint = api.apiEndpoint + `/expense/create_expense_with_transactions`;
    const {data : expense} = await http.post(fullApiEndpoint,expenseData);
    return expense;
}

