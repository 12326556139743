import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useParams } from 'react-router-dom';
import LoaderView from '../../../reusable/loader/loaderView';
import { fetchPalByPalId } from '../../../../services/palz/palzManagement';
import { set } from 'lodash';
import { fetchExpensesByPalId } from '../../../../services/expenses/expenseServiceManagement';
import ExpenseGrid from '../../../reusable/cards/expense/expenseGrid';
import BudgetByCategoryChart from './sub/budget/budgetByCategoryChart';
import {
  predefinedColors,
  dynamicallyGeneratedColor,
  parseColor,
  darkenColor,
  colorForCategory,
  stringToHash,
} from '../../../../services/helpers/colorService';
import ExpenseBreakdownCard from '../../../reusable/cards/dashboard/expenseBreakdownCard';
import BasicStatCard from './sub/basicStatCard';
import ExpensesByCategoryChart from './sub/expensesByCategoryChart';
import PalCoCard from './sub/palCoCard';
import PalzExpenseSection from './sub/completeSection/palzExpenseSection';
import { fetchLatestBudgetByUserId } from '../../../../services/budgets/budgetServiceManagement';
import BudgetBreakdownCard from '../../../reusable/cards/dashboard/BudgetBreakdownCard';
import BudgetCardGrid from '../../../reusable/cards/budget/budgetCardGrid';
import PalzBudgetSection from './sub/completeSection/palzBudgetSection';
import BudgetGoalFundsGrid from '../../../reusable/cards/budgetGoal/sub/budgetGoalFundsGrid';
import BudgetGoalsGrid from '../../../reusable/cards/budgetGoal/budgetGoalsGrid';
import { fetchBudgetGoalsByPalId } from '../../../../services/budgetGoal/budgetGoalManagement';
import BudgetGoalContributionCard from '../../../reusable/cards/dashboard/budgetGoalContributionCard';
import BudgetGoalDonutChart from '../../../reusable/cards/budgetGoal/sub/budgetGoalDonutChart';

import empty_expenses_animation from '../../../reusable/animations/lottie/empty_expenses_animation.json';
import no_budget_animation from '../../../reusable/animations/lottie/no_budget.json';
import budget_goal_animation from '../../../reusable/animations/lottie/budget_goal_animation.json';
import friends_animation from '../../../reusable/animations/lottie/friends_animation.json';
import NotAllowedOverlay from '../../../reusable/subscription/notAllowed/notAllowedOverlay';

const PalzDetail = ({ isDarkMode }) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const [isLoading, setIsLoading] = useState(false);
  const { palId } = useParams();
  const pageTitle = 'Pal';

  const [palzDetail, setPalzDetail] = useState({
    access_right: {
      access_id: '',
      can_comment: false,
      can_edit_budget_goal: false,
      can_edit_expense: false,
      can_edit_income: false,
      can_link_their_budget: false,
      can_view_budget: true,
      can_view_budget_goal: true,
      can_view_expense: true,
      can_view_income: true,
      pal_id: '',
      record_time_stamp: 0,
    },
    date_link_created: '',
    email_address: '',
    expiration_date: 0,
    has_access: false,
    invite_code: '',
    pal_first_name: '',
    pal_id: '',
    pal_last_name: '',
    pal_profile_image: '',
    primary_pal_id: '',
    requestor_first_name: '',
    requestor_last_name: '',
    requestor_profile_image: '',
  });

  //expenseChartData
  const [expenseChartData, setExpenseChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Budget Breakdown',
        data: [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  });

  //lottie

  const emptyExpensesLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: empty_expenses_animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const emptyBudgetLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: no_budget_animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const emptyBudgetGoalLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: budget_goal_animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  //
  const emptyPalzLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: friends_animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  //primary expenses
  const [
    primaryUserExpensesAndTransactions,
    setPrimaryUserExpensesAndTransactions,
  ] = useState([
    {
      account_id: '',
      day_due_each_month: 0,
      expense_amt: 0,
      expense_category: '',
      expense_description: '',
      expense_icon: '',
      expense_id: '',
      expense_name: '',
      has_insight: false,
      is_re_occuring: false,
      record_time_stamp: 0,
      transactions: [
        {
          history_id: '',
          expense_id: '',
          investment_id: '',
          history_description: '',
          saving_acc_id: '',
          account_id: '',
          amount: 0,
          budget_amount_left: 0,
          budget_category_name: '',
          budget_category_selected_id: '',
          currency: '',
          currency_interest: 0,
          expense_image_scans: null,
          history_day: 0,
          history_month: 0,
          history_type: '',
          history_year: 0,
          is_deleted: false,
          time_stamp: 0,
          total_allocated_for_category: 0,
        },
      ],
      user_pal_id: '',
    },
  ]);

  const [
    secondaryUserExpensesAndTransactions,
    setSecondaryUserExpensesAndTransactions,
  ] = useState([
    {
      account_id: '',
      day_due_each_month: 0,
      expense_amt: 0,
      expense_category: '',
      expense_description: '',
      expense_icon: '',
      expense_id: '',
      expense_name: '',
      has_insight: false,
      is_re_occuring: false,
      record_time_stamp: 0,
      transactions: [
        {
          history_id: '',
          expense_id: '',
          investment_id: '',
          history_description: '',
          saving_acc_id: '',
          account_id: '',
          amount: 0,
          budget_amount_left: 0,
          budget_category_name: '',
          budget_category_selected_id: '',
          currency: '',
          currency_interest: 0,
          expense_image_scans: null,
          history_day: 0,
          history_month: 0,
          history_type: '',
          history_year: 0,
          is_deleted: false,
          time_stamp: 0,
          total_allocated_for_category: 0,
        },
      ],
      user_pal_id: '',
    },
  ]);

  const [
    combinedUserExpensesAndTransactions,
    setCombinedUserExpensesAndTransactions,
  ] = useState([
    {
      account_id: '',
      day_due_each_month: 0,
      expense_amt: 0,
      expense_category: '',
      expense_description: '',
      expense_icon: '',
      expense_id: '',
      expense_name: '',
      has_insight: false,
      is_re_occuring: false,
      record_time_stamp: 0,
      transactions: [
        {
          history_id: '',
          expense_id: '',
          investment_id: '',
          history_description: '',
          saving_acc_id: '',
          account_id: '',
          amount: 0,
          budget_amount_left: 0,
          budget_category_name: '',
          budget_category_selected_id: '',
          currency: '',
          currency_interest: 0,
          expense_image_scans: null,
          history_day: 0,
          history_month: 0,
          history_type: '',
          history_year: 0,
          is_deleted: false,
          time_stamp: 0,
          total_allocated_for_category: 0,
        },
      ],
      user_pal_id: '',
      requestor_first_name: '',
      requestor_last_name: '',
      requestor_profile_image: '',
      pal_first_name: '',
      pal_last_name: '',
      pal_profile_image: '',
    },
  ]);

  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [expensesByCategory, setExpensesByCategory] = useState({});
  const [recurringExpenses, setRecurringExpenses] = useState(0);
  const [nonRecurringExpenses, setNonRecurringExpenses] = useState(0);
  const [expensesByRequestor, setExpensesByRequestor] = useState(0);
  const [expensesByPal, setExpensesByPal] = useState(0);

  //TODO show the budget breakdown for the current
  const [primaryUserBudgetBreakdown, setPrimaryUserBudgetBreakdown] = useState({
    budget_categories: [
      {
        budget_id: '',
        budget_category_id: '',
        category_name: '',
        total_allocated_for_property: 0,
        amount_left: 0,
        requestor_first_name: '',
        requestor_last_name: '',
        requestor_profile_image: '',
        pal_first_name: '',
        pal_last_name: '',
        pal_profile_image: '',
      },
    ],
    budget_id: '',
    creator_profile: {
      creator_profile_id: '',
      email_address: '',
      profile_img: '',
      last_name: '',
      user_id: '',
    },
    email_address: '',
    first_name: '',
    is_active_budget: false,
    last_name: '',
    month: 0,
    record_time_stamp: 0,
    total_left: 0,
    total_sum: 0,
    user_id: '',
    year: 0,
  });

  const [secondaryUserBudgetBreakdown, setSecondaryUserBudgetBreakdown] =
    useState({
      budget_categories: [
        {
          budget_id: '',
          budget_category_id: '',
          category_name: '',
          total_allocated_for_property: 0,
          amount_left: 0,
          requestor_first_name: '',
          requestor_last_name: '',
          requestor_profile_image: '',
          pal_first_name: '',
          pal_last_name: '',
          pal_profile_image: '',
        },
      ],
      budget_id: '',
      creator_profile: {
        creator_profile_id: '',
        email_address: '',
        profile_img: '',
        last_name: '',
        user_id: '',
      },
      email_address: '',
      first_name: '',
      is_active_budget: false,
      last_name: '',
      month: 0,
      record_time_stamp: 0,
      total_left: 0,
      total_sum: 0,
      user_id: '',
      year: 0,
    });

  const [combinedUserBudgetBreakdown, setCombinedUserBudgetBreakdown] =
    useState({
      budget_categories: [
        {
          budget_id: '',
          budget_category_id: '',
          category_name: '',
          total_allocated_for_property: 0,
          amount_left: 0,
          requestor_first_name: '',
          requestor_last_name: '',
          requestor_profile_image: '',
          pal_first_name: '',
          pal_last_name: '',
          pal_profile_image: '',
        },
      ],
      budget_id: '',
      creator_profile: {
        creator_profile_id: '',
        email_address: '',
        profile_img: '',
        last_name: '',
        user_id: '',
      },
      email_address: '',
      first_name: '',
      is_active_budget: false,
      last_name: '',
      month: 0,
      record_time_stamp: 0,
      total_left: 0,
      total_sum: 0,
      user_id: '',
      year: 0,
    });

  //budgetChartData

  const [budgetExpenseChartData, setBudgetExpenseChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Budget Breakdown',
        data: [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  });

  const [budgetByCategory, setBudgetByCategory] = useState({});
  const [totalBudgetCategories, setTotalBudgetCategories] = useState(0);
  const [totalBudgetCategoriesLeft, setTotalBudgetCategoriesLeft] = useState(0);
  const [totalBudgetCategoriesSpent, setTotalBudgetCategoriesSpent] =
    useState(0);
  const [totalBudgetCategoryByRequestor, setTotalBudgetCategoryByRequestor] =
    useState(0);
  const [totalBudgetCategoryByPal, setTotalBudgetCategoryByPal] = useState(0);

  //budget goal
  const [primaryBudgetGoals, setPrimaryBudgetGoals] = useState([
    {
      ai_insight_msg: '',
      bank_account_id: '',
      budget_goal_id: '',
      budget_goal_sum_total: 0,
      current_amt_saved: 0,
      goal_category_icon: '',
      goal_creator_profile: {},
      goal_name: '',
      goal_type: '',
      has_achieved_goal: false,
      is_deleted: false,
      month: 3,
      pals_working_together_towards_goal: [],
      record_time_stamp: 0,
      target_end_date_raw_date: 0,
      total_left: 0,
      user_id: '',
      year: 0,
    },
  ]);

  const [secondaryBudgetGoals, setSecondaryBudgetGoals] = useState([
    {
      ai_insight_msg: '',
      bank_account_id: '',
      budget_goal_id: '',
      budget_goal_sum_total: 0,
      current_amt_saved: 0,
      goal_category_icon: '',
      goal_creator_profile: {},
      goal_name: '',
      goal_type: '',
      has_achieved_goal: false,
      is_deleted: false,
      month: 3,
      pals_working_together_towards_goal: [],
      record_time_stamp: 0,
      target_end_date_raw_date: 0,
      total_left: 0,
      user_id: '',
      year: 0,
    },
  ]);

  const [combinedBudgetGoals, setCombinedBudgetGoals] = useState([
    {
      ai_insight_msg: '',
      bank_account_id: '',
      budget_goal_id: '',
      budget_goal_sum_total: 0,
      current_amt_saved: 0,
      goal_category_icon: '',
      goal_creator_profile: {},
      goal_name: '',
      goal_type: '',
      has_achieved_goal: false,
      is_deleted: false,
      month: 3,
      pals_working_together_towards_goal: [],
      record_time_stamp: 0,
      target_end_date_raw_date: 0,
      total_left: 0,
      user_id: '',
      year: 0,
    },
  ]);

  const [totalBudgetGoalCategories, setTotalBudgetGoalCategories] = useState(0);
  const [totalBudgetGoalCategoriesLeft, setTotalBudgetGoalCategoriesLeft] =
    useState(0);
  const [
    totalBudgetGoalCategoriesAchieved,
    setTotalBudgetGoalCategoriesAchieved,
  ] = useState(0);
  const [
    totalBudgetGoalCategoryByRequestor,
    setTotalBudgetGoalCategoryByRequestor,
  ] = useState(0);
  const [totalBudgetGoalCategoryByPal, setTotalBudgetGoalCategoryByPal] =
    useState(0);

  const [budgetGoalChartData, setBudgetGoalChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Budget Goal Breakdown',
        data: [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    setIsLoading(true);
    //fetch the palz detail
    fetchPalzDetail();
    setIsLoading(false);
  }, []);

  /*
  useEffect(() => {
    if (
      totalBudgetCategoryByRequestor !== 0 ||
      totalBudgetCategoryByPal !== 0
    ) {
      // Perform some action
      calculateAndSetBudgets(combinedUserBudgetBreakdown);
    }
  }, [totalBudgetCategoryByRequestor, totalBudgetCategoryByPal]);
  */

  const fetchPalzDetail = async () => {
    //console.log('Pal Id', palId);
    const palzDetail = await fetchPalByPalId(palId);
    setPalzDetail(palzDetail);
    accessControl(palzDetail);
  };

  //handle getting the primary and secondary palz if approved
  const accessControl = (palzDetail) => {
    if (palzDetail.has_access && palzDetail.secondary_pal_accepted_request) {
      checkIfCanViewExpenses(palzDetail);
      checkIfCanViewBudget(palzDetail);
      checkIfCanViewBudgetGoal(palzDetail);
    } else {
      //show hasNotBeenApprovedSection();
      setIsLoading(false);
    }
  };

  const checkIfCanViewExpenses = (_palzDetail) => {
    //get the primary and secondary palz
    //check if can view expenses
    if (_palzDetail.access_right && _palzDetail.access_right.can_view_expense) {
      //show the expenses
      //console.log('Can view expenses');

      //get the expenses for primary and secondary palz then combine the list
      getPrimaryAndSecondaryUserExpenses(_palzDetail);
    }
  };

  const checkIfCanViewBudget = (_palzDetail) => {
    //get the primary and secondary palz
    //check if can view budget
    if (_palzDetail.access_right && _palzDetail.access_right.can_view_budget) {
      //show the budget
      //console.log('Can view budget');
      getPrimaryAndSecondaryUserBudgetBreakdown(_palzDetail);
    }
  };

  const checkIfCanViewBudgetGoal = (_palzDetail) => {
    //get the primary and secondary palz
    //check if can view budget goal
    if (
      _palzDetail.access_right &&
      _palzDetail.access_right.can_view_budget_goal
    ) {
      //show the budget goal

      //console.log('Can view budget goal');
      getPrimaryAndSecondaryUserBudgetGoal(_palzDetail);
    }
  };

  //for the current period
  const getPrimaryAndSecondaryUserExpenses = async (_palDetail) => {
    //get the primary user expenses
    //get the transactions for the primary user expenses
    //console.log('Primary Pal Id', _palDetail.primary_pal_id);
    let primaryUserExpensesAndTransactions = [];
    let secondaryUserExpensesAndTransactions = [];

    try {
      primaryUserExpensesAndTransactions = await fetchExpensesByPalId(
        _palDetail.primary_pal_id
      );
      setPrimaryUserExpensesAndTransactions(primaryUserExpensesAndTransactions);
    } catch (error) {
      console.error('Failed to fetch expenses for primary pal:', error);
    }

    if (_palDetail.secondary_pal_id) {
      try {
        secondaryUserExpensesAndTransactions = await fetchExpensesByPalId(
          _palDetail.secondary_pal_id
        );
        setSecondaryUserExpensesAndTransactions(
          secondaryUserExpensesAndTransactions
        );
      } catch (error) {
        console.error('Failed to fetch expenses for secondary pal:', error);
      }
    }

    if (
      primaryUserExpensesAndTransactions.length === 0 &&
      secondaryUserExpensesAndTransactions.length === 0
    ) {
      console.error('Failed to fetch expenses for both pals');
      // Handle this case as needed
      return;
    }

    const combinedUserExpensesAndTransactions = [
      ...primaryUserExpensesAndTransactions,
      ...secondaryUserExpensesAndTransactions,
    ].map((expense) => {
      if (_palDetail.primary_pal_id === expense.user_pal_id) {
        return {
          ...expense,
          requestor_first_name: _palDetail.requestor_first_name,
          requestor_last_name: _palDetail.requestor_last_name,
          requestor_profile_image: _palDetail.requestor_profile_image,
        };
      } else if (
        _palDetail.secondary_pal_id &&
        _palDetail.secondary_pal_id === expense.user_pal_id
      ) {
        return {
          ...expense,
          pal_first_name: _palDetail.pal_first_name,
          pal_last_name: _palDetail.pal_last_name,
          pal_profile_image: _palDetail.pal_profile_image,
        };
      }
      return expense;
    });

    setCombinedUserExpensesAndTransactions(combinedUserExpensesAndTransactions);

    //console.log('Combined User Expenses and Transactions');
    //console.log(combinedUserExpensesAndTransactions);
    getLatestExpensesAndTransactionsChartData(
      combinedUserExpensesAndTransactions
    );

    setOtherExpenseSummary(combinedUserExpensesAndTransactions, _palDetail);

    setIsLoading(false);
  };

  const getLatestExpensesAndTransactionsChartData = async (
    expensesAndTransactions
  ) => {
    const labels = expensesAndTransactions.map(
      (expense) => expense.expense_name
    );

    const data = expensesAndTransactions.map((expense) =>
      expense.transactions.reduce(
        (sum, transaction) => sum + Number(transaction.amount),
        0
      )
    );

    const hoverColors = predefinedColors.map((color) =>
      darkenColor(color, 0.7)
    ); // darken by 30%

    setExpenseChartData((prevState) => ({
      ...prevState,
      labels: labels,
      datasets: prevState.datasets.map((dataset) => ({
        ...dataset,
        data: data,
        backgroundColor: predefinedColors, // Use the predefined colors
        borderColor: isDarkMode ? '#6c757d' : 'white',
        borderWidth: 3,
        hoverBackgroundColor: hoverColors, // Use the darker colors for hover
      })),
    }));
  };

  const setOtherExpenseSummary = (
    _combinedUserExpensesAndTransactions,
    _palDetail
  ) => {
    const totalExpenses = _combinedUserExpensesAndTransactions.reduce(
      (total, expense) => total + Number(expense.expense_amt),
      0
    );
    setTotalExpenses(totalExpenses);

    //total transactions
    const totalTransactions = _combinedUserExpensesAndTransactions.reduce(
      (total, expense) => total + Number(expense.transactions.length),
      0
    );
    setTotalTransactions(totalTransactions);

    //Expenses by Category
    const expensesByCategory = _combinedUserExpensesAndTransactions.reduce(
      (categories, expense) => {
        if (!categories[expense.expense_category]) {
          categories[expense.expense_category] = 0;
        }
        categories[expense.expense_category] += Number(expense.expense_amt);
        return categories;
      },
      {}
    );
    setExpensesByCategory(expensesByCategory);
    //console.log('Expenses By Category');
    //console.log(expensesByCategory);

    //Recurring and Non-Recurring Expenses:
    const recurringExpenses = _combinedUserExpensesAndTransactions
      .filter((expense) => expense.is_re_occuring)
      .reduce((total, expense) => total + Number(expense.expense_amt), 0);
    setRecurringExpenses(recurringExpenses);

    const nonRecurringExpenses = _combinedUserExpensesAndTransactions
      .filter((expense) => !expense.is_re_occuring)
      .reduce((total, expense) => total + Number(expense.expense_amt), 0);
    setNonRecurringExpenses(nonRecurringExpenses);

    //these two needs to be fixed
    calculateAndSetExpenses(_combinedUserExpensesAndTransactions, _palDetail);
  };

  function calculateAndSetExpenses(
    _combinedUserExpensesAndTransactions,
    _palDetail
  ) {
    const expensesByRequestor = calculateTotalExpensesByUser(
      _combinedUserExpensesAndTransactions,
      _palDetail,
      false
    );
    setExpensesByRequestor(expensesByRequestor);

    const expensesByPal = calculateTotalExpensesByUser(
      _combinedUserExpensesAndTransactions,
      _palDetail,
      true
    );
    //console.log('Expenses by Pal:', expensesByPal);
    setExpensesByPal(expensesByPal);
  }

  function calculateTotalExpensesByUser(expenses, _palDetail, isPal) {
    return expenses
      .filter((expense) =>
        isPal
          ? expense.pal_first_name === _palDetail.pal_first_name &&
            expense.pal_last_name === _palDetail.pal_last_name
          : expense.requestor_first_name === _palDetail.requestor_first_name &&
            expense.requestor_last_name === _palDetail.requestor_last_name
      )
      .reduce((total, expense) => total + Number(expense.expense_amt), 0);
  }

  //get the current budget breakdown for the primary and secondary palz
  const getPrimaryAndSecondaryUserBudgetBreakdown = async (_palDetail) => {
    //get the primary user budget breakdown
    //get the transactions for the primary user expenses
    //console.log('Primary Pal Id', _palDetail.primary_pal_id);
    let primaryUserBudgetBreakdown;
    let secondaryUserBudgetBreakdown;

    try {
      primaryUserBudgetBreakdown = await fetchLatestBudgetByUserId(
        _palDetail.primary_pal_id
      );

      // Add additional fields to each category in primaryUserBudgetBreakdown
      primaryUserBudgetBreakdown.budget_categories =
        primaryUserBudgetBreakdown.budget_categories.map((category) => ({
          ...category,
          requestor_first_name: _palDetail.requestor_first_name,
          requestor_last_name: _palDetail.requestor_last_name,
          requestor_profile_image: _palDetail.requestor_profile_image,
        }));

      setPrimaryUserBudgetBreakdown(primaryUserBudgetBreakdown);
    } catch (error) {
      console.error('Failed to fetch budget for primary user:', error);
      primaryUserBudgetBreakdown = { budget_categories: [] };
    }

    try {
      secondaryUserBudgetBreakdown = await fetchLatestBudgetByUserId(
        _palDetail.secondary_pal_id
      );

      // Add additional fields to each category in secondaryUserBudgetBreakdown
      secondaryUserBudgetBreakdown.budget_categories =
        secondaryUserBudgetBreakdown.budget_categories.map((category) => ({
          ...category,
          pal_first_name: _palDetail.pal_first_name,
          pal_last_name: _palDetail.pal_last_name,
          pal_profile_image: _palDetail.pal_profile_image,
        }));

      setSecondaryUserBudgetBreakdown(secondaryUserBudgetBreakdown);
    } catch (error) {
      console.error('Failed to fetch budget for secondary user:', error);
      secondaryUserBudgetBreakdown = { budget_categories: [] };
    }

    // Combine all categories from primaryUserBudgetBreakdown and secondaryUserBudgetBreakdown
    const allCategories = [
      ...primaryUserBudgetBreakdown.budget_categories,
      ...secondaryUserBudgetBreakdown.budget_categories,
    ];
    //console.log('All Categories');
    //console.log(allCategories);

    // Create a new array that includes all unique categories
    const combinedCategories = allCategories.reduce(
      (categories, currentCategory) => {
        // Check if the current category already exists in the categories array
        const existingCategory = categories.find(
          (category) =>
            category.category_name.trim().toLowerCase() ===
            currentCategory.category_name.trim().toLowerCase()
        );

        if (existingCategory) {
          // If the category already exists, merge their properties
          return categories.map((category) =>
            category.category_name.trim().toLowerCase() ===
            existingCategory.category_name.trim().toLowerCase()
              ? {
                  ...category,
                  ...currentCategory,
                  total_allocated_for_property:
                    category.total_allocated_for_property +
                    currentCategory.total_allocated_for_property,
                  amount_left:
                    category.amount_left + currentCategory.amount_left,
                  isMerged: true,
                }
              : category
          );
        } else {
          // If the category does not exist, add it to the categories array
          return [...categories, currentCategory];
        }
      },
      []
    );

    const combinedUserBudgetBreakdown = {
      ...primaryUserBudgetBreakdown,
      ...secondaryUserBudgetBreakdown,
      budget_categories: combinedCategories,
    };

    setCombinedUserBudgetBreakdown(combinedUserBudgetBreakdown);

    // console.log('Combined User Budget Breakdown');
    //console.log(combinedUserBudgetBreakdown);
    getLatestBudgetBreakdownChartData(combinedUserBudgetBreakdown);
    setOtherBudgetSummary(combinedUserBudgetBreakdown, _palDetail);

    setIsLoading(false);
  };

  const setOtherBudgetSummary = (_combinedUserBudgetBreakdown, _palDetail) => {
    const totalBudgetCategories =
      _combinedUserBudgetBreakdown.budget_categories.reduce(
        (total, category) =>
          total + Number(category.total_allocated_for_property),
        0
      );
    setTotalBudgetCategories(totalBudgetCategories);

    //Budget By Category
    const budgetByCategory =
      _combinedUserBudgetBreakdown.budget_categories.reduce(
        (categories, category) => {
          if (!categories[category.category_name]) {
            categories[category.category_name] = 0;
          }
          categories[category.category_name] += Number(
            category.total_allocated_for_property
          );
          return categories;
        },
        {}
      );
    setBudgetByCategory(budgetByCategory);
    //console.log('Budget By Category');
    //console.log(budgetByCategory);

    const totalBudgetCategoriesLeft =
      _combinedUserBudgetBreakdown.budget_categories.reduce(
        (total, category) => total + Number(category.amount_left),
        0
      );
    setTotalBudgetCategoriesLeft(totalBudgetCategoriesLeft);

    const totalBudgetCategoriesSpent =
      _combinedUserBudgetBreakdown.budget_categories.reduce(
        (total, category) =>
          total +
          Number(category.total_allocated_for_property) -
          Number(category.amount_left),
        0
      );
    setTotalBudgetCategoriesSpent(totalBudgetCategoriesSpent);

    calculateAndSetBudgets(_combinedUserBudgetBreakdown, _palDetail);
  };

  function calculateTotalBudgetByUser(budgetCategories, userDetail, isPal) {
    // console.log('Budget Categories for calculation:', budgetCategories);
    //console.log('User Detail:', userDetail);
    //console.log('Is Pal:', isPal);
    return budgetCategories
      .filter((category) =>
        isPal
          ? category.pal_first_name === userDetail.pal_first_name &&
            category.pal_last_name === userDetail.pal_last_name
          : category.requestor_first_name === userDetail.requestor_first_name &&
            category.requestor_last_name === userDetail.requestor_last_name
      )
      .reduce(
        (total, category) =>
          total + Number(category.total_allocated_for_property),
        0
      );
  }

  function calculateAndSetBudgets(_combinedUserBudgetBreakdown, _palDetail) {
    const totalBudgetCategoryByRequestor = calculateTotalBudgetByUser(
      _combinedUserBudgetBreakdown.budget_categories,
      _palDetail,
      false
    );
    if (totalBudgetCategoryByRequestor !== 0) {
      setTotalBudgetCategoryByRequestor(totalBudgetCategoryByRequestor);
    }

    const totalBudgetCategoryByPal = calculateTotalBudgetByUser(
      _combinedUserBudgetBreakdown.budget_categories,
      _palDetail,
      true
    );
    if (totalBudgetCategoryByPal !== 0) {
      setTotalBudgetCategoryByPal(totalBudgetCategoryByPal);
    }
  }

  const getLatestBudgetBreakdownChartData = async (budgetBreakdown) => {
    const labels = budgetBreakdown.budget_categories.map(
      (category) => category.category_name
    );
    const data = budgetBreakdown.budget_categories.map(
      (category) => category.total_allocated_for_property
    );

    const backgroundColor = labels.map((category) =>
      dynamicallyGeneratedColor(category, isDarkMode)
    );
    const borderColor = backgroundColor.map(
      (color) => color.replace('0.2', '1') // Set alpha channel to 1 for solid color
    );

    const hoverColors = predefinedColors.map((color) =>
      darkenColor(color, 0.7)
    ); // darken by 30%

    setBudgetExpenseChartData((prevState) => ({
      ...prevState,
      labels: labels,
      datasets: prevState.datasets.map((dataset) => ({
        ...dataset,
        data: data,
        backgroundColor: predefinedColors, // Use the predefined colors
        borderColor: isDarkMode ? '#6c757d' : 'white',
        borderWidth: 3,
        hoverBackgroundColor: hoverColors, // Use the darker colors for hover
      })),
    }));
  };

  //budget goal
  const getPrimaryAndSecondaryUserBudgetGoal = async (_palDetail) => {
    //get the primary user budget goal
    //get the transactions for the primary user expenses
    //console.log('Primary Pal Id', _palDetail.primary_pal_id);
    let primaryBudgetGoal = await fetchBudgetGoalsByPalId(
      _palDetail.primary_pal_id
    );
    // console.log('Primary User Budget Goal');
    //console.log(primaryBudgetGoal);

    setPrimaryBudgetGoals(primaryBudgetGoal);

    //console.log('Primary User Budget Goal');
    // console.log(primaryBudgetGoal);

    //get the secondary user budget goal
    //get the transactions for the secondary user expenses
    //console.log('Secondary Pal Id', _palDetail.secondary_pal_id);
    let secondaryBudgetGoal = await fetchBudgetGoalsByPalId(
      _palDetail.secondary_pal_id
    );
    // console.log('Secondary User Budget Goal');
    //console.log(secondaryBudgetGoal);

    setSecondaryBudgetGoals(secondaryBudgetGoal);

    // Combine primary and secondary user budget goal
    const combinedBudgetGoals = [...primaryBudgetGoal, ...secondaryBudgetGoal];

    if (combinedBudgetGoals.length > 0) {
      setCombinedBudgetGoals(combinedBudgetGoals);
      getLatestBudgetGoalChartData(combinedBudgetGoals);
      setOtherBudgetGoalSummary(combinedBudgetGoals, _palDetail);
    }
    setIsLoading(false);
  };

  const getLatestBudgetGoalChartData = async (budgetGoal) => {
    const labels = budgetGoal.map((goal) => goal.goal_name);
    const data = budgetGoal.map((goal) => goal.budget_goal_sum_total);

    const backgroundColor = labels.map((category) =>
      dynamicallyGeneratedColor(category, isDarkMode)
    );
    const borderColor = backgroundColor.map(
      (color) => color.replace('0.2', '1') // Set alpha channel to 1 for solid color
    );

    const hoverColors = predefinedColors.map((color) =>
      darkenColor(color, 0.7)
    ); // darken by 30%

    setBudgetGoalChartData((prevState) => ({
      ...prevState,
      labels: labels,
      datasets: prevState.datasets.map((dataset) => ({
        ...dataset,
        data: data,
        backgroundColor: predefinedColors, // Use the predefined colors
        borderColor: isDarkMode ? '#6c757d' : 'white',
        borderWidth: 3,
        hoverBackgroundColor: hoverColors, // Use the darker colors for hover
      })),
    }));
  };

  const setOtherBudgetGoalSummary = (_combinedBudgetGoals, _palDetail) => {
    const totalBudgetGoalCategories = _combinedBudgetGoals.reduce(
      (total, goal) => total + Number(goal.budget_goal_sum_total),
      0
    );
    setTotalBudgetGoalCategories(totalBudgetGoalCategories);

    //totalBudgetGoalCategoriesLeft

    const totalBudgetGoalCategoriesLeft = _combinedBudgetGoals.reduce(
      (total, goal) => total + Number(goal.total_left),
      0
    );
    setTotalBudgetGoalCategoriesLeft(totalBudgetGoalCategoriesLeft);

    //totalBudgetGoalCategoriesAchieved

    const totalBudgetGoalCategoriesAchieved = _combinedBudgetGoals.reduce(
      (total, goal) => total + Number(goal.current_amt_saved),
      0
    );
    setTotalBudgetGoalCategoriesAchieved(totalBudgetGoalCategoriesAchieved);

    //totalBudgetGoalCategoryByRequestor

    const totalBudgetGoalCategoryByRequestor = calculateTotalBudgetGoalByUser(
      _combinedBudgetGoals,
      _palDetail,
      false
    );
    setTotalBudgetGoalCategoryByRequestor(totalBudgetGoalCategoryByRequestor);

    //totalBudgetGoalCategoryByPal

    const totalBudgetGoalCategoryByPal = calculateTotalBudgetGoalByUser(
      _combinedBudgetGoals,
      _palDetail,
      true
    );
    setTotalBudgetGoalCategoryByPal(totalBudgetGoalCategoryByPal);
  };

  const calculateTotalBudgetGoalByUser = (budgetGoals, _palDetail, isPal) => {
    return budgetGoals
      .filter((goal) =>
        isPal
          ? goal.pal_first_name === _palDetail.pal_first_name &&
            goal.pal_last_name === _palDetail.pal_last_name
          : goal.goal_creator_profile.first_name ===
              _palDetail.requestor_first_name &&
            goal.goal_creator_profile.last_name ===
              _palDetail.requestor_last_name
      )
      .reduce((total, goal) => total + Number(goal.budget_goal_sum_total), 0);
  };

  //show the chart for the expenses combined for the primary and secondary palz (have option to see individual charts)
  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}
      {/*/Loader */}

      {/* ============================================================== */}
      {/* Start right Content here */}
      {/* ============================================================== */}

      <div
        className='container-fluid'
        data-bs-theme={isDarkMode ? 'dark' : 'light'}
      >
        {/* Expenses section (need file)*/}
        <div className='row'>
          <div className='col-xl-12'>
            <PalCoCard key={palzDetail.pal_id} pal={palzDetail} />
          </div>
        </div>

        {/*Has access and decision made */}
        {palzDetail.has_access && palzDetail.secondary_pal_accepted_request ? (
          <NotAllowedOverlay message='You need to be a paid user to access this feature'>
            <div className='card'>
              <Tabs
                defaultActiveKey='expenses'
                id='justify-tab-example'
                className='mb-3 nav-pills  dark-light'
              >
                <Tab
                  eventKey='expenses'
                  title='Expenses'
                  className='nav-link bg-light text-dark'
                >
                  <PalzExpenseSection
                    lottieAnimation={emptyExpensesLottieOptions}
                    expenseChartData={expenseChartData}
                    isDarkMode={isDarkMode}
                    expensesByCategory={expensesByCategory}
                    totalExpenses={totalExpenses}
                    totalTransactions={totalTransactions}
                    recurringExpenses={recurringExpenses}
                    nonRecurringExpenses={nonRecurringExpenses}
                    expensesByRequestor={expensesByRequestor}
                    expensesByPal={expensesByPal}
                    combinedUserExpensesAndTransactions={
                      combinedUserExpensesAndTransactions
                    }
                  />
                </Tab>
                <Tab
                  eventKey='budget'
                  title='Budget'
                  className='nav-link bg-light text-dark'
                >
                  <PalzBudgetSection
                    lottieAnimation={emptyBudgetLottieOptions}
                    budgetExpenseChartData={budgetExpenseChartData}
                    isDarkMode={isDarkMode}
                    budgetByCategory={budgetByCategory}
                    totalBudgetCategories={totalBudgetCategories}
                    totalBudgetCategoriesLeft={totalBudgetCategoriesLeft}
                    totalBudgetCategoriesSpent={totalBudgetCategoriesSpent}
                    totalBudgetCategoryByRequestor={
                      totalBudgetCategoryByRequestor
                    }
                    totalBudgetCategoryByPal={totalBudgetCategoryByPal}
                    combinedUserBudgetBreakdown={combinedUserBudgetBreakdown}
                  />
                </Tab>
                <Tab
                  eventKey='budgetGoal'
                  title='Goals'
                  className='nav-link bg-light text-dark'
                >
                  {/* Budget Goal Start */}
                  <div className='row'>
                    <div className='col-xl-3'>
                      <BudgetGoalDonutChart
                        budgetGoalChartData={budgetGoalChartData}
                        isDarkMode={isDarkMode}
                      />
                    </div>
                    <div className='col-xl-3'>
                      <BudgetGoalContributionCard
                        lottieAnimation={emptyBudgetGoalLottieOptions}
                        budgetGoals={combinedBudgetGoals}
                      />
                    </div>
                    <div className='col-xl-3'>
                      <div className='row'>
                        <div className='col-md-6'>
                          <BasicStatCard
                            title={'Total Budget Goal Categories'}
                            percentage={0}
                            total={totalBudgetGoalCategories}
                            icon={'bx bx-wallet text-primary'}
                          />
                        </div>
                        <div className='col-md-6'>
                          <BasicStatCard
                            title={'Total Budget Goal Categories Left'}
                            percentage={0}
                            total={totalBudgetGoalCategoriesLeft}
                            icon={'bx bx-transfer text-primary'}
                          />
                        </div>
                        <div className='col-md-6'>
                          <BasicStatCard
                            title={'Total Budget Goal Categories Achieved'}
                            percentage={0}
                            total={totalBudgetGoalCategoriesAchieved}
                            icon={'bx bx-receipt text-primary'}
                          />
                        </div>
                        <div className='col-md-6'>
                          <BasicStatCard
                            title={'Budget Goal By Requestor'}
                            percentage={0}
                            total={totalBudgetGoalCategoryByRequestor}
                            icon={'bx bx-receipt text-primary'}
                          />
                        </div>
                        <div className='col-md-6'>
                          <BasicStatCard
                            title={'Budget Goal By Pal'}
                            percentage={0}
                            total={totalBudgetGoalCategoryByPal}
                            icon={'bx bx-user text-primary'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    {combinedBudgetGoals &&
                      combinedBudgetGoals?.length > 0 &&
                      combinedBudgetGoals[0]?.goal_name && (
                        <BudgetGoalsGrid
                          budgetGoals={combinedBudgetGoals || []}
                        />
                      )}
                  </div>
                  {/* Budget Goal End */}
                </Tab>
              </Tabs>
            </div>
          </NotAllowedOverlay>
        ) : (
          <div className='card'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-xl-12'>
                  <div className='alert alert-danger' role='alert'>
                    <h4 className='alert-heading'>Access Denied</h4>
                    <p>
                      You do not have access to view the details of this Pal.
                      Please contact the Pal to request access.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* end row */}
        {/* container-fluid */}
      </div>
      {/* End Page-content */}

      {/* End Page-content */}
    </React.Fragment>
  );
};

export default PalzDetail;
