import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../../../reusable/form/Input';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';

import Joi from 'joi-browser';
import MultiLineInput from '../../../reusable/form/multilineinput';
import PageTitleBox from '../../../reusable/portal/general/pageTitleBox';
import {
  fetchLatestBudget,
  updateAmountLeft,
} from '../../../../services/budgets/budgetServiceManagement';
import { getIconUrl, iconUrlMap } from '../../../../constants/iconUrlMap';
import { set } from 'lodash';
import Select from 'react-select';
import {
  expenseCategories,
  findExpenseCategoryByKey,
} from '../../../../constants/expense/expenseCategories';
import config from '../../../../constants/config.json';
import { v4 as uuidv4 } from 'uuid';
import {
  createExpenseWithTransactions,
  fetchExpensesAndTransactionsByUserPalId,
} from '../../../../services/expenses/expenseServiceManagement';

const CreateNewExpense = ({ isDarkMode }) => {
  const pageTitle = 'Create New Expense';
  const [ignored, setIgnored] = useReducer((x) => x + 1, 0);

  const navigate = useNavigate();

  /*

    const [expensesAndTransactions, setExpensesAndTransactions] = useState([
    {
      account_id: '',
      day_due_each_month: 0,
      expense_amt: 0,
      expense_category: '',
      expense_description: '',
      expense_icon: '',
      expense_id: '',
      expense_name: '',
      has_insight: false,
      is_re_occuring: false,
      record_time_stamp: 0,
      transactions: [
        {
          history_id: '',
          expense_id: '',
          investment_id: '',
          history_description: '',
          saving_acc_id: '',
          account_id: '',
          amount: 0,
          budget_amount_left: 0,
          budget_category_name: '',
          budget_category_selected_id: '',
          currency: '',
          currency_interest: 0,
          expense_image_scans: null,
          history_day: 0,
          history_month: 0,
          history_type: '',
          history_year: 0,
          is_deleted: false,
          time_stamp: 0,
          total_allocated_for_category: 0,
        },
      ],
      user_pal_id: '',
    },
  ]);

  */
  //need to fetch the other expenses that are already in the system to auto complete

  //handle the separately then combine them
  //the form data
  const [expenseData, setExpenseData] = useState({
    account_id: '', //auto generated
    day_due_each_month: 0,
    expense_amt: 0,
    expense_category: '',
    expense_description: '',
    expense_icon: '',
    expense_id: '', //auto generated
    expense_name: '',
    has_insight: false,
    is_re_occuring: false,
    record_time_stamp: 0, //auto generated
    user_pal_id: '', //auto generated
  });

  const [isReOcurring, setIsReOcurring] = useState(expenseData.is_re_occuring);

  //this is added at the end when submitting the form, as a new entry or added on the existing entry
  const [transactionData, setTransactionData] = useState({
    history_id: '',
    expense_id: '',
    investment_id: '',
    history_description: '',
    saving_acc_id: '',
    account_id: '',
    amount: 0,
    budget_amount_left: 0,
    budget_category_name: '',
    budget_category_selected_id: '',
    currency: '',
    currency_interest: 0,
    expense_image_scans: null,
    history_day: 0,
    history_month: 0,
    history_type: '',
    history_year: 0,
    is_deleted: false,
    time_stamp: 0,
    total_allocated_for_category: 0,
  });

  const [latestBudget, setLatestBudget] = useState({
    budget_categories: [
      {
        budget_id: '',
        budget_category_id: '',
        category_name: '',
        total_allocated_for_property: 0,
        amount_left: 0,
      },
    ],
    budget_id: '',
    creator_profile: {
      creator_profile_id: '',
      email_address: '',
      profile_img: '',
      last_name: '',
      user_id: '',
    },
    email_address: '',
    first_name: '',
    is_active_budget: false,
    last_name: '',
    month: 0,
    record_time_stamp: 0,
    total_left: 0,
    total_sum: 0,
    user_id: '',
    year: 0,
  });

  const [existingExpensesAndTransactions, setExistingExpensesAndTransactions] =
    useState([
      {
        account_id: '',
        day_due_each_month: 0,
        expense_amt: 0,
        expense_category: '',
        expense_description: '',
        expense_icon: '',
        expense_id: '',
        expense_name: '',
        has_insight: false,
        is_re_occuring: false,
        record_time_stamp: 0,
        transactions: [
          {
            history_id: '',
            expense_id: '',
            investment_id: '',
            history_description: '',
            saving_acc_id: '',
            account_id: '',
            amount: 0,
            budget_amount_left: 0,
            budget_category_name: '',
            budget_category_selected_id: '',
            currency: '',
            currency_interest: 0,
            expense_image_scans: null,
            history_day: 0,
            history_month: 0,
            history_type: '',
            history_year: 0,
            is_deleted: false,
            time_stamp: 0,
            total_allocated_for_category: 0,
          },
        ],
        user_pal_id: '',
      },
    ]);

  const [errors, setErrors] = useState({});

  const schema = {
    day_due_each_month: Joi.number().required().label('Day Due Each Month'),
    expense_amt: Joi.number().required().label('Expense Amount'),
    expense_category: Joi.string().required().label('Expense Category'),
    expense_description: Joi.string().required().label('Expense Description'),
    expense_icon: Joi.string().optional().label('Expense Icon').allow(''),
    expense_name: Joi.string().required().label('Expense Name'),
    has_insight: Joi.boolean().optional().label('Has Insight'),
    is_re_occuring: Joi.boolean()
      .optional()
      .label('Is Re Occuring')
      .allow('', null),
    record_time_stamp: Joi.number().allow(0).label('Record Time Stamp'),
    user_pal_id: Joi.string().allow('').label('User Pal Id'),
    expense_id: Joi.string().allow('').label('Expense Id'),
    account_id: Joi.string().allow('').label('Account Id'),
    transactions: Joi.array().optional().label('Transactions').allow(''),
    budget_category_selected_id: Joi.string()
      .optional()
      .label('Budget Category')
      .allow(''),
  };

  const [isLoading, setIsLoading] = useState(false);
  const [budgetAllocationSelectorOptions, setSelectorOptions] = useState([]);
  const [selectedBudgetAllocatedOption, setSelectedBudgetAllocatedOption] =
    useState(null);

  const [selectedOptionExpenseCategory, setSelectedOptionExpenseCategory] =
    useState(null);

  const [existingExpenseOptions, setExistingExpenseOptions] = useState([
    { value: '', label: '', data: {} },
  ]);
  const [selectedExpenseOption, setSelectedExpenseOption] = useState({
    value: '',
    label: '',
    data: {},
  });
  //get the latest budget then transform the data into a form that can be used in the dropdown (categories)
  const getLatestBudget = async () => {
    //get the latest budget
    const latestBudget = await fetchLatestBudget();

    if (latestBudget) {
      setIsLoading(false);
      setLatestBudget(latestBudget);

      //convert to the format that can be used in the dropdown
      if (latestBudget.budget_categories) {
        mapSelectorOptions(latestBudget.budget_categories);
      }
    }
  };

  const getAllExpenses = async () => {
    const existingExpensesAndTransactions =
      await fetchExpensesAndTransactionsByUserPalId();
    if (existingExpensesAndTransactions) {
      // Filter out duplicate expenses
      const uniqueExpenses = existingExpensesAndTransactions.reduce(
        (unique, expense) => {
          if (
            unique.findIndex((e) => e.expense_name === expense.expense_name) ===
            -1
          ) {
            unique.push(expense);
          }
          return unique;
        },
        []
      );

      setExistingExpensesAndTransactions(uniqueExpenses);

      // Set the selection options
      const options = uniqueExpenses.map((expense) => ({
        value: expense.expense_name,
        label: expense.expense_name,
        data: expense,
      }));
      setExistingExpenseOptions(options);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    getIconOptions();
    //get the current budget
    getLatestBudget();
    //TODO: get all the expenses and transaction (use the latest transaction for the value to set the fields)
    // for the user and show them in the dropdown for expense name which is a typeahead
    getAllExpenses();

    setIsLoading(false);
  }, []);

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(expenseData, schema, options);
    if (!error) return null;

    const errors = {};

    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };
  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaLocal);

    return error ? error.details[0].message : null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //get the values from the form
    // var emailAddress = this.emailAddress.current.value;
    // var password = document.getElementById('txtPassword').value;
    //call the server to save the changes

    const errors = validate();

    for (const error in errors) {
      if (errors.hasOwnProperty(error)) {
        toast.error(errors[error]);
      }
    }
    setErrors({ errors: errors || {} });
    if (errors) return;

    doSubmit();
  };

  const handleChange = (input) => {
    if (input && input.currentTarget) {
      const errorMessage = validateProperty(input.currentTarget);

      const newErrors = { ...errors };
      if (errorMessage) {
        newErrors[input.currentTarget.name] = errorMessage;
      } else delete newErrors[input.currentTarget.name];

      let newExpenseData;
      if (input.currentTarget.name === 'is_re_occuring') {
        newExpenseData = {
          ...expenseData,
          [input.currentTarget.name]: input.currentTarget.checked,
        };

        setIsReOcurring(input.currentTarget.checked); // Update isReOcurring
      } else {
        newExpenseData = {
          ...expenseData,
          [input.currentTarget.name]: input.currentTarget.value,
        };
      }

      setExpenseData(newExpenseData);
      setErrors(newErrors);
    } else if (input && input.value) {
      // This is an option from the CreatableSelect component for expense_name
      const selectedExpense = existingExpensesAndTransactions.find(
        (expense) => expense.expense_name === input.value
      );

      if (selectedExpense) {
        // Find the last transaction of the selected expense
        const lastTransaction =
          selectedExpense.transactions[selectedExpense.transactions.length - 1];

        setExpenseData((prevExpenseData) => ({
          ...prevExpenseData,
          ...selectedExpense,
          budget_category_selected_id:
            lastTransaction.budget_category_selected_id,
          is_re_occuring: selectedExpense.is_re_occuring,
          expense_category: selectedExpense.expense_category,
          expense_icon: selectedExpense.expense_icon,
        }));
        setIsReOcurring(selectedExpense.is_re_occuring); // Update isReOcurring

        //set the expense icon
        const selectedExpenseIcon = iconSelectOptions.find(
          (option) => option.value === selectedExpense.expense_icon
        );
        setSelectedOptionExpenseIcon(selectedExpenseIcon);

        //only set this if it can be found in the current budget else set a warning that the user should select a budget allocation since
        //no current matches were found
        const selectedBudgetAllocatedOption =
          budgetAllocationSelectorOptions.find(
            (option) =>
              option.label.toLowerCase().replace(/\s/g, '') ===
              lastTransaction.budget_category_name
                .toLowerCase()
                .replace(/\s/g, '')
          );
        if (selectedBudgetAllocatedOption) {
          handleBudgetAllocatedChange({
            value: selectedBudgetAllocatedOption.value,
            label: selectedBudgetAllocatedOption.label, // Replace this with the actual label if available
            icon: selectedBudgetAllocatedOption.icon, // Use the category_icon for the icon
          });
        } else {
          toast.warning(
            `No matching budget allocation, ${lastTransaction.budget_category_name} found for the selected expense. 
            Please select from the Budget Allocation From dropdownlist.`,
            { autoClose: false }
          );
        }

        handleExpenseChangeCategory({
          value: selectedExpense.expense_category,
          label: findExpenseCategoryByKey(selectedExpense.expense_category), // Replace this with the actual label if available
        });
      } else {
        // If the selected expense does not exist in existingExpensesAndTransactions,
        // only update the expense_name field in expenseData
        setExpenseData((prevExpenseData) => ({
          ...prevExpenseData,
          expense_name: input.value,
        }));
      }
    } else if (input && input.label === 'expense_category') {
      // This is an option from the Select component for expense_category
      setSelectedOptionExpenseCategory(input);
      setExpenseData((prevExpenseData) => ({
        ...prevExpenseData,
        expense_category: input.value,
      }));
    } else if (input && input.label === 'budget_category_selected_id') {
      // This is an option from the Select component for budget_category_selected_id
      setSelectedBudgetAllocatedOption(input);
      setTransactionData((prevExpenseData) => ({
        ...prevExpenseData,
        budget_category_selected_id: input.value,
      }));
    }
  };

  //catches if it wasnt caught in the first validation
  const lastValidationCheck = (
    expenseData,
    latestBudget,
    selectedBudgetAllocatedOption,
    selectedOptionExpenseCategory,
    selectedOptionExpenseIcon
  ) => {
    if (expenseData.expense_name === '') {
      toast.error('Please select an expense name.');
      return false;
    }
    if (!latestBudget) {
      toast.error('No budget found. Please create a budget first.');
      return false;
    }
    if (!selectedBudgetAllocatedOption) {
      toast.error('Please select a budget allocation.');
      return false;
    }
    if (!selectedOptionExpenseCategory) {
      toast.error('Please select an expense category.');
      return false;
    }
    if (!selectedOptionExpenseIcon) {
      toast.error('Please select an expense icon.');
      return false;
    }
    if (expenseData.expense_amt <= 0) {
      toast.error('Please enter a valid expense amount.');
      return false;
    }
    return true;
  };

  //TODO next get the current budget data and show it in the dropdown
  const doSubmit = async () => {
    if (
      !lastValidationCheck(
        expenseData,
        latestBudget,
        selectedBudgetAllocatedOption,
        selectedOptionExpenseCategory,
        selectedOptionExpenseIcon
      )
    ) {
      return;
    }
    // Continue with the rest of your code...

    const budget_id = latestBudget.budget_id;
    let budget_category_id = selectedBudgetAllocatedOption.value;
    let new_amount_left = 0;

    //get the allocated amount for this budget category
    const budgetCategory = latestBudget.budget_categories.find(
      (category) => category.budget_category_id === budget_category_id
    );

    if (budgetCategory) {
      new_amount_left = budgetCategory.amount_left - expenseData.expense_amt;
    }

    //look at the data and submit it to the server
    //set the auto generated values
    //if the expense_id is empty then it is a new entry
    if (expenseData.expense_id === '') expenseData.expense_id = uuidv4();
    expenseData.account_id = '';
    expenseData.record_time_stamp = Date.now();
    expenseData.has_insight = false;
    expenseData.expense_icon = selectedOptionExpenseIcon.value;
    //user_pal_id is from the local storage
    expenseData.user_pal_id = localStorage.getItem(config.user_id);

    //ensure all expense numbers are numbers
    expenseData.expense_amt = Number(expenseData.expense_amt);
    expenseData.day_due_each_month = Number(expenseData.day_due_each_month);
    expenseData.record_time_stamp = Number(expenseData.record_time_stamp);

    //set the transaction data
    transactionData.history_id = uuidv4();
    transactionData.expense_id = expenseData.expense_id;
    transactionData.investment_id = '';
    transactionData.history_description = expenseData.expense_description;
    transactionData.saving_acc_id = '';
    transactionData.account_id = '';
    transactionData.amount = Number(expenseData.expense_amt);
    transactionData.budget_amount_left = Number(new_amount_left);
    transactionData.budget_category_name = selectedBudgetAllocatedOption.label;
    transactionData.budget_category_selected_id =
      selectedBudgetAllocatedOption.value;
    transactionData.currency = '';
    transactionData.currency_interest = Number(0);
    transactionData.expense_image_scans = null;
    transactionData.history_day = Number(expenseData.day_due_each_month);
    //current month as a number
    transactionData.history_month = Number(new Date().getMonth() + 1);
    transactionData.history_type = 'Expense';
    //current year
    transactionData.history_year = new Date().getFullYear();
    transactionData.is_deleted = false;
    transactionData.time_stamp = Date.now();
    transactionData.total_allocated_for_category = Number(
      budgetCategory.total_allocated_for_property
    );

    if (transactionData) {
      expenseData.transactions = [transactionData];
    } else {
      //console.log('transaction data is empty');
    }

    const expense = await createExpenseWithTransactions(expenseData);

    //if created successfully then update the budget
    if (expense) {
      const result = updateAmountLeft(
        budget_id,
        budget_category_id,
        new_amount_left
      );

      if (result) {
        toast.success('Expense created successfully.');
        navigate('/currentExpenses');
      } else {
        toast.error('Failed to update the budget amount left.');
      }
    }
    //update the amount left in the budget category
  };

  //hanlde the selector
  const mapSelectorOptions = (budget_categories) => {
    const options = budget_categories.map((category) => ({
      value: category.budget_category_id,
      label: category.category_name,
      icon: getIconUrl(category.category_icon).url,
    }));

    setSelectorOptions(options);
  };

  const handleExpenseChangeCategory = (option) => {
    setSelectedOptionExpenseCategory(option);
    expenseData.expense_category = option.value;
  };

  const handleBudgetAllocatedChange = (option) => {
    setSelectedBudgetAllocatedOption(option);
    transactionData.budget_category_selected_id = option.value;
    transactionData.budget_category_name = option.label;
  };

  const [iconSelectOptions, setIconSelectOptions] = useState([]);

  const getIconOptions = () => {
    const options = Object.keys(iconUrlMap).map((key) => {
      const { val, url } = iconUrlMap[key];
      return { value: key, label: val, url };
    });
    setIconSelectOptions(options);
  };

  const capitalize = (str) => {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const formatOptionLabel = ({ value, label, url }) => (
    <div>
      <img
        src={url}
        alt={label}
        style={{ width: '20px', marginRight: '10px' }}
      />
      {capitalize(value.split(/(?=[A-Z])/).join(' '))}
    </div>
  );

  const [selectedOptionExpenseIcon, setSelectedOptionExpenseIcon] =
    useState(null);

  const handleExpenseChangeIcon = (_selectedExpenseIconOption) => {
    setSelectedOptionExpenseIcon(_selectedExpenseIconOption);
    handleChange({
      target: {
        name: 'expense_icon',
        value: _selectedExpenseIconOption
          ? _selectedExpenseIconOption.value
          : '',
      },
    });
  };

  return (
    <React.Fragment>
      <div data-bs-theme={isDarkMode ? 'dark' : 'light'}>
        <div className='container-fluid'>
          <PageTitleBox pageTitle={pageTitle} />
          <div className='row '>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-header align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    Create New Expense
                  </h4>
                  <div className='flex-shrink-0'></div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='card-body'>
                    <div className='live-preview'>
                      <div className='row gy-4'>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            {/* <Input
                              name='expense_name'
                              label='Expense Name'
                              value={expenseData.expense_name}
                              onChange={handleChange}
                              error={errors.expense_name}
                            />*/}
                            <label htmlFor='form-grid-category'>
                              Expense Name
                            </label>
                            <CreatableSelect
                              name='expense_name'
                              options={existingExpenseOptions}
                              onChange={handleChange}
                              isClearable
                            />
                          </div>
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <label
                              htmlFor='is_re_occuring'
                              style={{ display: 'block' }}
                            >
                              Is Re Occuring Expense
                            </label>
                            <div className='form-check form-switch form-switch-right form-switch-md'>
                              <label
                                htmlFor='is_re_occuring'
                                className='form-label text-muted'
                              >
                                {isReOcurring ? 'Yes' : 'No'}
                              </label>
                              <input
                                className='form-check-input code-switcher'
                                type='checkbox'
                                id='is_re_occuring'
                                name='is_re_occuring'
                                checked={expenseData.is_re_occuring}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <label htmlFor='form-grid-category'>
                              Budget Allocated From
                            </label>

                            <Select
                              value={selectedBudgetAllocatedOption}
                              onChange={handleBudgetAllocatedChange}
                              options={budgetAllocationSelectorOptions}
                              formatOptionLabel={({ label, icon }) => (
                                <div>
                                  <img
                                    src={icon}
                                    alt={label}
                                    width={20}
                                    height={20}
                                  />
                                  {'  '}
                                  {label}
                                </div>
                              )}
                            />
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            {/*} <Input
                              name='expense_category'
                              label='Expense Category'
                              value={expenseData.expense_category}
                              onChange={handleChange}
                              error={errors.expense_category}
                            />*/}
                            <label htmlFor='form-grid-category'>
                              Expense Category
                            </label>
                            <Select
                              value={selectedOptionExpenseCategory}
                              onChange={handleExpenseChangeCategory}
                              options={expenseCategories}
                            />
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='expense_amt'
                              label='Expense Amount'
                              type={'number'}
                              value={expenseData.expense_amt}
                              onChange={handleChange}
                              error={errors.expense_amt}
                            />
                          </div>
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='day_due_each_month'
                              label='Day Due Each Month'
                              type={'number'}
                              value={expenseData.day_due_each_month}
                              onChange={handleChange}
                              error={errors.day_due_each_month}
                            />
                          </div>
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            {/*<Input
                              name='expense_icon'
                              label='Expense Icon (Dropdown with the images)'
                              value={expenseData.expense_icon}
                              onChange={handleChange}
                              error={errors.expense_icon}
                        />*/}
                            <label htmlFor='form-grid-category'>
                              Expense Icon
                            </label>
                            <Select
                              name='expense_icon'
                              value={selectedOptionExpenseIcon}
                              onChange={handleExpenseChangeIcon}
                              options={iconSelectOptions}
                              formatOptionLabel={formatOptionLabel}
                            />
                          </div>
                        </div>
                        <div className='col-xxl-12 col-md-12'>
                          <div>
                            <MultiLineInput
                              name='expense_description'
                              label='Expense Description'
                              value={expenseData.expense_description}
                              onChange={handleChange}
                              error={errors.expense_description}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card-footer'>
                      <button
                        disabled={validate()}
                        className='btn btn-success'
                        type='submit'
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateNewExpense;
