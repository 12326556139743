import React, { useState } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Link } from 'react-router-dom';
import { getIconUrl } from '../../../../../constants/iconUrlMap';

const PastBudgetCardGrid = ({ pastBudgets }) => {
  const [sortOption, setSortOption] = useState('Month and Year');
  const [filterOption, setFilterOption] = useState('All Time');

  const sortBudgets = () => {
    let sortedBudgets;
    switch (sortOption) {
      case 'Month and Year':
        sortedBudgets = [...pastBudgets].sort(
          (a, b) => new Date(a.year, a.month) - new Date(b.year, b.month)
        );
        break;
      default:
        sortedBudgets = pastBudgets;
    }
    return sortedBudgets;
  };

  const filterBudgets = (sortedBudgets) => {
    let filteredBudgets;
    const now = new Date();
    switch (filterOption) {
      case 'Last 3 Months':
        filteredBudgets = sortedBudgets.filter(
          (budget) =>
            new Date(budget.year, budget.month) >=
            new Date(now.getFullYear(), now.getMonth() - 3)
        );
        break;
      case 'Last 6 Months':
        filteredBudgets = sortedBudgets.filter(
          (budget) =>
            new Date(budget.year, budget.month) >=
            new Date(now.getFullYear(), now.getMonth() - 6)
        );
        break;
      default:
        filteredBudgets = sortedBudgets;
    }
    return filteredBudgets;
  };

  const sortedAndFilteredBudgets = filterBudgets(sortBudgets());

  return (
    <>
      <div className='row pt-3 px-5'>
        <div className='col-12'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent'>
            <h4 className='mb-sm-0'>Past Budgets</h4>

            <div className='d-flex flex-shrink-0'>
              <NavDropdown title={`Sort by: ${sortOption}`} id='nav-dropdown'>
                <NavDropdown.Item
                  onClick={() => setSortOption('Month and Year')}
                >
                  Month and Year
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title={`Filter by: ${filterOption}`}
                id='nav-dropdown'
              >
                <NavDropdown.Item onClick={() => setFilterOption('All Time')}>
                  All Time
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => setFilterOption('Last 3 Months')}
                >
                  Last 3 Months
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => setFilterOption('Last 6 Months')}
                >
                  Last 6 Months
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
        </div>
      </div>

      <div className='row px-4'>
        {sortedAndFilteredBudgets.map((budget, index) => {
          const totalBudgeted = budget.budget_categories.reduce(
            (total, category) =>
              total + (category.total_allocated_for_property || 0),
            0
          );
          const totalLeft = budget.budget_categories.reduce(
            (total, category) => total + (category.amount_left || 0),
            0
          );
          const totalSpent = budget.budget_categories.reduce(
            (total, category) =>
              total +
              ((category.total_allocated_for_property || 0) -
                (category.amount_left || 0)),
            0
          );
          const progress =
            totalBudgeted > 0 ? (totalSpent / totalBudgeted) * 100 : 0;

          let barColor = 'bg-secondary';
          if (progress >= 95) {
            barColor = 'bg-danger';
          } else if (progress >= 80) {
            barColor = 'bg-warning';
          } else {
            barColor = 'bg-success';
          }

          return (
            <div key={index} className='col-xl-3'>
              <div className='card card-height-100'>
                <div className='card-body p-0'>
                  <ul className='list list-group list-group-flush mb-0'>
                    <li className='list-group-item' data-id='01'>
                      <div className='d-flex align-items-start'>
                        <div className='flex-shrink-0 me-3'>
                          <div>
                            <img
                              className='image avatar-xs rounded-circle'
                              alt=''
                              src={
                                getIconUrl(
                                  budget.budget_categories[0].category_icon
                                ).url
                              }
                            />
                          </div>
                        </div>
                        <div className='flex-grow-1'>
                          <div className='d-flex justify-content-between align-items-start'>
                            <div className='overflow-hidden'>
                              <h5 className='contact-name fs-13 mb-1'>
                                <Link
                                  to={`/budget/${budget.budget_id}`}
                                  className='link text-body'
                                >
                                  {new Date(0, budget.month - 1).toLocaleString(
                                    'default',
                                    {
                                      month: 'long',
                                    }
                                  )}{' '}
                                  {budget.year} Budget
                                </Link>
                              </h5>
                            </div>
                          </div>

                          <div className='overflow-hidden'>
                            <div className='no-padding card-body '>
                              <div className='d-flex justify-content-between '>
                                <div>
                                  <h6 className='mb-0'>
                                    <b className='text'>
                                      ${Number(totalSpent)?.toFixed(2)}
                                    </b>{' '}
                                    Spent
                                  </h6>
                                </div>
                                <div>
                                  <h6 className='mb-0'>
                                    ${Number(totalLeft)?.toFixed(2)} Left
                                  </h6>
                                </div>
                                <div>
                                  <h6 className='mb-0'>
                                    ${Number(totalBudgeted)?.toFixed(2)}{' '}
                                    Budgeted
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className='progress bg-secondary-subtle rounded-0'>
                              <div
                                className={`progress-bar ${barColor}`}
                                role='progressbar'
                                style={{ width: `${progress}%` }}
                                aria-valuenow={progress}
                                aria-valuemin='0'
                                aria-valuemax='100'
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* end cardbody */}
              </div>
              {/* end card */}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PastBudgetCardGrid;
