//TODO: if you are the person who sent the request you can cancel it, while the pal can approve or reject it

import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { base64ImageString } from '../../../../constants/images/defaultImgs';
import {
  deletePal,
  respondToPalRequest,
} from '../../../../services/palz/palzManagement';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const PendingPalCard = ({ pal, storedUserId }) => {
  const navigate = useNavigate();
  //approve method, reject method, cancel method

  const handleApprove = async () => {
    //approve pal request
    const palResponseDetail = {
      pal_id: pal.pal_id,
      secondary_pal_accepted_request: true,
      secondary_pal_id: storedUserId,
      date_link_created: pal.date_link_created,
      has_access: true,
    };

    const response = await respondToPalRequest(palResponseDetail);
    if (!response.isError) {
      //go to palz detail page
      toast.success('Pal request approved');
      navigate(`/palz/${pal.pal_id}`);
    }
  };

  const handleReject = async () => {
    const palResponseDetail = {
      pal_id: pal.pal_id,
      secondary_pal_accepted_request: false,
      secondary_pal_id: '',
      date_link_created: '',
      has_access: false,
    };

    const response = await respondToPalRequest(palResponseDetail);
    if (!response.isError) {
      //dont need to go anywhere since already on Pending Palz
      toast.success('Pal request rejected');
      navigate(`/approvedPalz`); //add context later to force an update
    }
  };

  const handleCancel = () => {
    //cancel pal request
    const response = deletePal(pal.pal_id);
    if (!response.isError) {
      toast.success('Pal request cancelled');
      navigate(`/approvedPalz`); //add context later to force an update
    } else {
      toast.error('Error cancelling pal request');
    }
  };

  return (
    <div className='card card-animate' key={pal.pal_id}>
      <div className='card-body'>
        <div className='d-flex justify-content-between'>
          <div>
            <p
              className={`fw-bold mb-0 ${
                !pal.secondary_has_responded && !pal.has_access
                  ? 'text-primary'
                  : pal.secondary_has_responded && !pal.has_access
                  ? 'text-danger'
                  : ''
              }`}
            >
              {!pal.secondary_has_responded && !pal.has_access
                ? 'Pending Pal'
                : pal.secondary_has_responded && !pal.has_access
                ? 'Rejected Pal'
                : ''}
            </p>
            <Link to={`/palz/${pal.pal_id}`}>
              <h4 className='mt-4 ff-secondary fw-semibold'>
                <span className='counter-value' data-target='28.05'>
                  {pal.primary_pal_id === storedUserId
                    ? `${pal.pal_first_name} ${pal.pal_last_name}`
                    : `${pal.requestor_first_name} ${pal.requestor_last_name}`}
                </span>
              </h4>
            </Link>
            {/* if you are the person who sent the request you can cancel it, while the pal can approve or reject it*/}
            <div className='mt-3'>
              {pal.primary_pal_id === storedUserId ? (
                <button
                  onClick={() => {
                    handleCancel();
                  }}
                  className='btn btn-danger btn-sm'
                >
                  Cancel
                </button>
              ) : (
                <div>
                  <button
                    onClick={() => {
                      handleReject();
                    }}
                    className='btn btn-danger btn-sm me-2'
                  >
                    Reject
                  </button>
                  <button
                    onClick={() => {
                      handleApprove();
                    }}
                    className='btn btn-success btn-sm'
                  >
                    Approve
                  </button>
                </div>
              )}
            </div>
          </div>
          <div>
            <div className='avatar-sm flex-shrink-0'>
              <img
                src={`data:image/png;base64,${
                  pal.primary_pal_id === storedUserId
                    ? pal.pal_profile_image || base64ImageString
                    : pal.requestor_profile_image || base64ImageString
                }`}
                alt='Profile'
                className='avatar-title bg-info-subtle rounded-circle fs-2'
                style={{ width: '50px', height: '50px' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingPalCard;
