import React from 'react';

import BudgetCardGrid from '../../../../../reusable/cards/budget/budgetCardGrid';
import BasicStatCard from '../basicStatCard';
import BudgetByCategoryChart from '../budget/budgetByCategoryChart';
import BudgetBreakdownCard from '../../../../../reusable/cards/dashboard/BudgetBreakdownCard';

const PalzBudgetSection = ({
  lottieAnimation,
  budgetExpenseChartData,
  isDarkMode,
  budgetByCategory,
  totalBudgetCategories,
  totalBudgetCategoriesLeft,
  totalBudgetCategoriesSpent,
  totalBudgetCategoryByRequestor,
  totalBudgetCategoryByPal,
  combinedUserBudgetBreakdown,
}) => (
  <>
    <div className='row'>
      <div className='col-xl-3'>
        <BudgetBreakdownCard
          lottieAnimation={lottieAnimation}
          budgetExpenseChartData={budgetExpenseChartData}
          isDarkMode={isDarkMode}
        />
      </div>
      <div className='col-xl-3'>
        <BudgetByCategoryChart budgetByCategory={budgetByCategory} />
      </div>
      <div className='col-xl-3'>
        <div className='row'>
          <div className='col-md-6'>
            <BasicStatCard
              title={'Total Budget Categories'}
              percentage={0}
              total={totalBudgetCategories}
              icon={'bx bx-wallet text-primary'}
            />
          </div>
          <div className='col-md-6'>
            <BasicStatCard
              title={'Total Budget Categories Left'}
              percentage={0}
              total={totalBudgetCategoriesLeft}
              icon={'bx bx-transfer text-primary'}
            />
          </div>
          <div className='col-md-6'>
            <BasicStatCard
              title={'Total Budget Categories Spent'}
              percentage={0}
              total={totalBudgetCategoriesSpent}
              icon={'bx bx-receipt text-primary'}
            />
          </div>
          <div className='col-md-6'>
            <BasicStatCard
              title={'Budget By Requestor'}
              percentage={0}
              total={totalBudgetCategoryByRequestor}
              icon={'bx bx-receipt text-primary'}
            />
          </div>
          <div className='col-md-6'>
            <BasicStatCard
              title={'Budget By Pal'}
              percentage={0}
              total={totalBudgetCategoryByPal}
              icon={'bx bx-user text-primary'}
            />
          </div>
        </div>
      </div>
    </div>
    <div className='row'>
      {combinedUserBudgetBreakdown &&
        combinedUserBudgetBreakdown?.budget_categories?.length > 0 &&
        combinedUserBudgetBreakdown?.budget_categories[0].category_name && (
          <BudgetCardGrid latestBudget={combinedUserBudgetBreakdown} />
        )}
    </div>
  </>
);

export default PalzBudgetSection;
