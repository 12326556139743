export class User {
  constructor(user_pal_id, first_name, last_name, email_address, password = null, security_question = null, security_answer = null, profile_img = null, login_code = null, expires_at = null) {
    this.user_pal_id = user_pal_id;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email_address = email_address;
    this.password = password;
    this.security_question = security_question;
    this.security_answer = security_answer;
    this.profile_img = profile_img;
    this.login_code = login_code;
    this.expires_at = expires_at;
  }
}

//register

export class UserProfile {
  constructor({ user_pal_id, first_name, last_name, email_address, password = null, security_question = null, security_answer = null, profile_img = null, login_code = null, expires_at = null }) {
    this.user_pal_id = user_pal_id;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email_address = email_address;
    this.password = password;
    this.security_question = security_question;
    this.security_answer = security_answer;
    this.profile_img = profile_img;
    this.login_code = login_code;
    this.expires_at = expires_at;
  }
}