import React, { useEffect, useState } from 'react';
import LoaderView from '../../reusable/loader/loaderView';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
  predefinedColors,
  dynamicallyGeneratedColor,
  parseColor,
  darkenColor,
  colorForCategory,
  stringToHash,
} from '../../../services/helpers/colorService';
import { fetchBudgetGoalsByUserId } from '../../../services/budgetGoal/budgetGoalManagement';
import BudgetGoalContributionCard from '../../reusable/cards/dashboard/budgetGoalContributionCard';
import BudgetGoalsGrid from '../../reusable/cards/budgetGoal/budgetGoalsGrid';
import budget_goal_animation from '../../reusable/animations/lottie/budget_goal_animation.json';
import NotAllowedOverlay from '../../reusable/subscription/notAllowed/notAllowedOverlay';

const AllBudgetGoals = ({ isDarkMode }) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const [isLoading, setIsLoading] = useState();
  const pageTitle = 'All Budget Goals';

  const [budgetGoals, setBudgetGoals] = useState([
    {
      ai_insight_msg: '',
      bank_account_id: '',
      budget_goal_id: '',
      budget_goal_sum_total: 0,
      current_amt_saved: 0,
      goal_category_icon: '',
      goal_creator_profile: {},
      goal_name: '',
      goal_type: '',
      has_achieved_goal: false,
      is_deleted: false,
      month: 3,
      pals_working_together_towards_goal: [],
      record_time_stamp: 0,
      target_end_date_raw_date: 0,
      total_left: 0,
      user_id: '',
      year: 0,
    },
  ]);

  const emptyBudgetGoalLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: budget_goal_animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const getBudgetGoals = async () => {
    //get the budget goals
    const budgetGoals = await fetchBudgetGoalsByUserId();

    setBudgetGoals(budgetGoals);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);

    getBudgetGoals();
  }, []);

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}
      {/*/Loader */}

      {/* ============================================================== */}
      {/* Start right Content here */}
      {/* ============================================================== */}

      <div data-bs-theme={isDarkMode ? 'dark' : 'light'}>
        <div className='container-fluid'>
          <div className='row px-4'>
            <div className='col-12'>
              <div className='page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent'>
                <h4 className='mb-sm-0'>{pageTitle}</h4>

                <div className='page-title-right'>
                  <ol className='breadcrumb m-0'>
                    <li className='breadcrumb-item'>
                      <a
                        href='#'
                        onClick={(event) => {
                          event.preventDefault();
                          // your code here
                        }}
                      >
                        Pages
                      </a>
                    </li>
                    <li className='breadcrumb-item active'>{pageTitle}</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className='row'>
            <div className='col-xl-4'>
              <NotAllowedOverlay message='You need to be a paid user to access this feature'>
                <BudgetGoalContributionCard
                  lottieAnimation={emptyBudgetGoalLottieOptions}
                  budgetGoals={budgetGoals}
                />
              </NotAllowedOverlay>
            </div>
            <div className='col-xl-6'>
              <div className='row'>
                <div className='col-md-6'>
                  {/*}  <SpendingCard
                    mostSpentCategory={mostSpentCategory}
                    percentageChange={percentageChange}
                    />*/}
                </div>
                <div className='col-md-6'>
                  {/*} <BudgetLeftCard
                    leftToBudget={leftToBudget}
                    totalBudget={totalBudget}
                    />*/}
                </div>
                <div className='col-md-6'>
                  {/*} <TotalSpendCard
                    totalSpentPercentageChange={totalSpentPercentageChange}
                    totalSpent={totalSpent}
                    secondLatestBudgetSummary={secondLatestBudgetSummary}
                    />*/}
                </div>
                <div className='col-md-6'>
                  {/* <SavingCard
                    mostSavedCategory={mostSavedCategory}
                    percentageChange={percentageChangeInSavings}
                    />*/}
                </div>
              </div>
            </div>
          </div>
          {/* end col*/}
        </div>{' '}
        {/* end row*/}
        <div className='row'>
          {/* Budget Goals */}
          <NotAllowedOverlay message='You need to be a paid user to access this feature'>
            <BudgetGoalsGrid budgetGoals={budgetGoals} />
          </NotAllowedOverlay>

          {/* end col */}
        </div>
        {/* end row */}
        {/* container-fluid */}
      </div>
      {/* End Page-content */}

      {/* End Page-content */}
    </React.Fragment>
  );
};

export default AllBudgetGoals;
