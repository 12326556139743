import React, { useState } from 'react';
import { getIconUrl } from '../../../../constants/iconUrlMap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';

const BudgetGoalsCard = ({ budgetGoals }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('Goal Name');

  const filteredGoals = budgetGoals
    .filter((goal) =>
      goal.goal_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      switch (sortOption) {
        case 'Goal Name':
          return a.goal_name.localeCompare(b.goal_name);
        case 'Planned Amount':
          return b.budget_goal_sum_total - a.budget_goal_sum_total; // for descending order
        case 'Progress':
          const aProgress =
            a.budget_goal_sum_total !== 0
              ? (a.current_amt_saved / a.budget_goal_sum_total) * 100
              : 0;
          const bProgress =
            b.budget_goal_sum_total !== 0
              ? (b.current_amt_saved / b.budget_goal_sum_total) * 100
              : 0;
          return bProgress - aProgress; // for descending order
        default:
          return 0;
      }
    });

  return (
    <div className='col-xl-3'>
      <div className='card card-height-100'>
        <div className='card-header align-items-center d-flex'>
          <h4 className='card-title mb-0 flex-grow-1'>Budget Goals</h4>

          <div className='flex-shrink-0'>
            <NavDropdown title={`Sort by: ${sortOption}`} id='nav-dropdown'>
              <NavDropdown.Item onClick={() => setSortOption('Goal Name')}>
                Goal Name
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => setSortOption('Planned Amount')}>
                Planned Amount
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => setSortOption('Progress')}>
                Progress
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>
        {/* end card header */}
        <div className='card-body'>
          <p className='text-muted'>Filter Goals by Name</p>
          <div className='row mb-2'>
            <div className='col'>
              <div>
                <input
                  className='search form-control'
                  placeholder='Search'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>
          <ul className='list list-group list-group-flush mb-0'>
            {filteredGoals.map((goal, index) => {
              const amountBudgeted = goal.budget_goal_sum_total || 0;
              const amountSpent = goal.current_amt_saved || 0;
              const amountLeft = amountBudgeted - amountSpent;
              const progress =
                amountBudgeted !== 0 ? (amountSpent / amountBudgeted) * 100 : 0;

              let barColor = 'bg-secondary';
              if (progress >= 95) {
                barColor = 'bg-danger';
              } else if (progress >= 80) {
                barColor = 'bg-warning';
              } else {
                barColor = 'bg-success';
              }

              return (
                <li key={index} className='list-group-item'>
                  <div className='d-flex align-items-start'>
                    <div className='flex-shrink-0 me-3'>
                      <div>
                        <img
                          className='image avatar-xs rounded-circle'
                          alt=''
                          src={getIconUrl(goal.goal_category_icon).url}
                        />
                      </div>
                    </div>

                    <div className='flex-grow-1'>
                      <div className='d-flex justify-content-between align-items-start'>
                        <div className='overflow-hidden'>
                          <h5 className='contact-name fs-13 mb-1'>
                            <Link
                              to={`/budgetGoal/${goal.budget_goal_id}`}
                              className='link text-body'
                            >
                              {goal.goal_name}
                            </Link>
                          </h5>
                        </div>

                        <div className='flex-shrink-0 ms-2'>
                          <div
                            className='fs-5 text'
                            style={{ fontWeight: 'bold' }}
                          >
                            ${Number(amountSpent)?.toFixed(2)}
                          </div>
                        </div>
                      </div>

                      <div className='overflow-hidden'>
                        <div className='no-padding card-body '>
                          <div className='d-flex justify-content-between '>
                            <div>
                              <h6 className='mb-0'>
                                Saved{' '}
                                <b className='text'>
                                  ${Number(amountSpent)?.toFixed(2)}
                                </b>{' '}
                              </h6>
                            </div>
                            <div>
                              <h6 className='mb-0'>
                                ${Number(amountLeft)?.toFixed(2)} Left
                              </h6>
                            </div>
                            <div>
                              <h6 className='mb-0'>
                                Goal {''} ${Number(amountBudgeted)?.toFixed(2)}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className='progress bg-secondary-subtle rounded-0'>
                          <div
                            className={`progress-bar ${barColor}`}
                            role='progressbar'
                            style={{ width: `${progress}%` }}
                            aria-valuenow={progress}
                            aria-valuemin='0'
                            aria-valuemax='100'
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        {/* end cardbody */}
      </div>
      {/* end card */}
    </div>
  );
};

export default BudgetGoalsCard;
